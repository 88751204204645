import Vue from 'vue'
import removeMarkdown from 'remove-markdown'

const bind = (el, binding) => {
    el.innerHTML = removeMarkdown(binding.value || '')
}

Vue.directive('unmarked', {
    bind: bind,
    update: bind
})

Vue.filter('unmarked', value => removeMarkdown(value || ''))
