<template>
    <acs-base-container center>
        <v-card flat>
            <v-card-text class="acs--survey-message">
                <img src="/img/pay-success.svg" />
            </v-card-text>
            <v-card-title class="justify-center">
                <div>{{ $t('paysuccess.success') }}</div>
            </v-card-title>
            <v-card-text>
                {{ $t('paysuccess.successtext') }}
                <template v-if="payId">
                    <p>{{ $t('paysuccess.payid')}}</p>
                    <strong>{{ payId }}</strong>
                </template>

                <acs-pay-create-account v-if="!connected" class="mt-4" />

            </v-card-text>
            <v-card-actions>
                <v-btn rounded color="primary" block :to="getNextUrl()">{{ $t('paysuccess.next') }}</v-btn>
            </v-card-actions>
        </v-card>
    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsPayCreateAccount from '@/components/pay/AcsPayCreateAccount'
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'pay-success',
    components: { AcsBaseContainer, AcsPayCreateAccount },
    mixins: [RouteMixin],
    props: {
        slug: { type: String },
        aslot: { type: String }
    },
    computed: {
        payId() {
            return this.$route.query.payment_intent
        },

        connected() {
            return this.$store.getters['auth/connected']
        },

        isOnlyTeam() {
            const salepoint = this.$store.getters['sp/current']
            return this.$store.getters['auth/isOnly'](salepoint.id, 'team')
        }
    },
    async mounted() {
        this.loading = true
        return this.$store.dispatch('sp/current', { slug: this.slug })
            .catch(err => this.$err(err))
            .finally(() => (this.loading = false))
    },

    methods: {
        getNextUrl() {
            if (this.isOnlyTeam) {
                return { name: this.getContextRouteName('salepoint') }
            }
            return { name: this.getContextRouteName('payfinish') }
        }
    }
}
</script>
