<template>
    <v-autocomplete
        v-model="slotSlug"
        :items="items"
        :label="$t('Chercher un emplacement')"
        item-text="text"
        item-value="value"
        color="primary"
        :loading="loading"
        :disabled="disabled"
        :search-input.sync="search"
        :hide-no-data="!search">
        <template #no-data>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ $t(slots.length ? 'Veuillez préciser votre recherche' : 'Aucun emplacement trouvé') }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="slots.length">
                        {{ slots.length }} emplacements trouvés
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>

<script>
import lodash from 'lodash'

export default {
    name: 'acs-slot-selector',
    props: {
        slug: { type: String },
        value: { type: String },
        disabled: { type: Boolean }
    },
    data: () => ({
        slots: [],
        loading: false,
        specifySearch: false,
        search: null,
        showIfResultLengthLessThan: 3
    }),
    computed: {
        slotSlug: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        items() {
            if (this.specifySearch) {
                return []
            }
            return this.slots.map(slot => ({
                value: slot.slug,
                text: `${slot.name} (${slot.slug})`
            }))
        }
    },
    watch: {
        search: 'getSlots'
    },
    methods: {
        getSlots: lodash.debounce(function(search) {
            this.loading = true
            this.specifySearch = false
            this.slots = []
            return this.$store.dispatch('slot/search', { slug: this.slug, search })
                .then(slots => {
                    this.specifySearch = this.showIfResultLengthLessThan < slots.length
                    this.slots = slots
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }, 500)
    }
}
</script>
