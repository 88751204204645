import Vue from 'vue'
import i18next from 'i18next'

Vue.prototype.$err = function(err, data) {
    data = data || {
        returnMessage: false,
        skip: false
    }

    let message = ''
    if (err.response) {
        const e = err.response.data || {}
        const errorData = {
            code: err.response.status,
            text: err.response.statusText
        }
        if (e.error?.translate === false) {
            message = e.message || err.message
        } else if (e.length && e[0].keyword) {
            message = i18next.t([
                `shared-front:errors.Api.${e[0].keyword}`,
                'shared-front:errors.Api.default'
            ], {
                ...errorData,
                ...e[0].params
            })
        } else if (e.error) {
            message = i18next.t([
                `shared-front:errors.${e.error.i18nkey}`,
                e.error.i18nkey || '',
                `shared-front:errors.${e.type}.${e.error.model}`,
                `shared-front:errors.${e.type}.${e.error.code}`,
                `shared-front:errors.${e.type}.${e.error.type}`,
                `shared-front:errors.${e.type}.default`,
                `shared-front:errors.Api.${err.response.status}`,
                'shared-front:errors.Unknown'
            ], {
                ...errorData,
                ...e.error
            })
        } else {
            message = i18next.t([
                `shared-front:errors.Api.${err.response.status}`,
                'shared-front:errors.Unknown'
            ], errorData)
        }
    } else if (err.request) {
        message = 'Request error'
    } else {
        message = err.message
    }
    if (data.returnMessage) {
        return message
    }
    this.$store.commit('msg/show', {
        message: message,
        type: 'error',
        ...data
    })
    if (data.skip) {
        return
    }
    throw err
}

Vue.prototype.$success = function(res, data) {
    data = data || {}
    const msg = i18next.t(data.message, (res && res.data) || res)
    delete data.message
    this.$store.commit('msg/show', {
        message: msg,
        type: 'info',
        ...data
    })
}
