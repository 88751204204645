<template>
    <v-container>
        <v-row :class="{ 'text-center': center }">
            <v-col :class="cls">
                <v-card flat :max-width="maxWidth" class="acs-home--card">
                    <slot name="default" />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'acs-base-container',
    props: {
        large: { type: Boolean },
        size: { type: String },
        center: { type: Boolean }
    },
    computed: {
        cls() {
            if (this.large) {
                return ''
            }
            return 'px-0'
        },

        maxWidth() {
            if (this.size === 'large') {
                return 800
            }
            return 400
        }
    }
}
</script>
