<template>
    <div>
        <v-app-bar
            v-if="salepoint.id"
            color="primary"
            dark
            extended
            extension-height="104px"
            :src="bg">
            <template v-slot:img="{ props }">
                <v-img
                    v-bind="props"
                    gradient="to bottom, black, 3%, transparent" />
            </template>
            <v-btn class="acs-back-btn" icon v-if="back" :to="getTo(true)" @click="backFn">
                <v-icon>$vuetify.icons.baseBack</v-icon>
            </v-btn>
            <v-spacer />

            <acs-cart-btn v-if="$route.params.slug" :slug="$route.params.slug" />

            <v-btn v-if="menu" icon @click="$emit('update:right', !right)">
                <v-icon large>$vuetify.icons.baseMenu</v-icon>
            </v-btn>
        </v-app-bar>
        <component :is="$route.meta.titleCmp" class="acs-title--cmp" />
    </div>
</template>

<script>
import AcsCartBtn from '@/components/cart/AcsCartBtn'

export default {
    name: 'acs-base-top-bar',
    components: { AcsCartBtn },
    props: {
        right: { type: Boolean }
    },
    data: () => ({
        title: '',
        back: null,
        backFn: () => null,
        menu: false
    }),
    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        },
        btitle() {
            return this.$store.getters['top/title']
        },
        bg() {
            return this.salepoint.links_bg
        }
    },
    watch: {
        $route(to) {
            this.applyMeta(to.meta || {})
        },
        btitle(title) {
            this.title = title
        }
    },
    created() {
        this.applyCurrent()
    },
    methods: {
        applyCurrent() {
            this.applyMeta(this.$router.currentRoute.meta || {})
        },

        applyMeta(meta) {
            this.$store.commit('top/title', {
                title: meta.title,
                ext: !!meta.titleCmp && meta.ext !== false
            })
            this.title = meta.title
            this.back = meta.back
            this.backFn = () => null
            this.menu = meta.menu !== false
            if (typeof this.getTo() === 'function') {
                this.backFn = this.getTo()
            }
        },

        getTo(strict) {
            if (typeof this.back === 'string') {
                return { name: this.back }
            }
            if (typeof this.back === 'function') {
                const b = this.back({ store: this.$store, router: this.$router, route: this.$route })
                if (strict && typeof b === 'function') {
                    return null
                }
                return b
            }
        }
    }
}
</script>

<style lang="sass">
.acs-title--cmp
    margin-top: -100px

.acs-back-btn.v-btn--active::before
    opacity: 0 !important
</style>
