<template>
    <v-row class="mt-1 align-center">
        <v-col class="pl-0">
            <v-input
                type="hidden"
                :rules="loose ? [] : [$required]"
                :value="!errorMessage"
                single-line
                hide-details />

            <v-alert
                v-if="!errorMessage && scanned"
                text
                dense
                type="info"
                class="text-caption mb-0">
                {{ $t(loose ? 'productlist.slotscannedLoose' : 'productlist.slotscanned', { slot: slot.name || aslot }) }}
            </v-alert>
            <div v-else-if="!errorMessage" class="text-caption">
                {{ label }}
                <p v-if="slot && slot.id">
                    {{ $t('cart.slot') }}: <strong>{{ slot.name }} <span v-if="slot.zone">({{ slot.zone }})</span></strong>
                </p>
            </div>
            <v-alert
                v-else
                text
                dense
                :type="errorMessage === 'inside' ? 'warning' : 'error'"
                class="text-caption mb-0">
                {{ $t(`productlist.scanerror.${errorMessage}`) }}
            </v-alert>
        </v-col>

        <v-col class="pr-0 shrink">

            <v-menu v-if="false" v-model="show" :close-on-content-click="false" min-width="400">
                <template #activator="{ on, attrs }">
                    <v-btn outlined rounded color="primary" v-on="on" v-bind="attrs">
                        {{ $t('Action') }}
                    </v-btn>
                </template>
                <v-card flat>
                    <acs-base-card-title @close="show = false" />
                    <v-list>
                        <acs-base-qrcode-btn @input="onScan">
                            <template #button="{ on }">
                                <v-list-item v-on="on" class="orange lighten-5 orange--text">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('productlist.slotscan') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </acs-base-qrcode-btn>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <acs-slot-selector v-model="selectedSlot" :disabled="loading" :slug="slug" />
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>

            <acs-base-qrcode-btn v-else @input="onScan">
                <template #button="{ on }">
                    <v-btn outlined rounded color="primary" v-on="on" :loading="loading">
                        {{ $t('productlist.slotscan') }}
                    </v-btn>
                </template>
            </acs-base-qrcode-btn>
        </v-col>
    </v-row>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'
import AcsSlotSelector from '@/components/slot/AcsSlotSelector'
import AcsBaseQrcodeBtn from '@/shared/components/base/AcsBaseQrcodeBtn'
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-cart-scan',

    components: { AcsBaseCardTitle, AcsSlotSelector, AcsBaseQrcodeBtn },

    props: {
        slug: { type: String },
        aslot: { type: String },
        label: { type: String }
    },

    mixins: [RouteMixin],

    data: () => ({
        errorMessage: null,
        loading: false,
        scanned: false,
        show: false,
        selectedSlot: null
    }),

    computed: {
        slot() {
            return this.$store.getters['sp/slot']
        },
        loose() {
            // #ACC-455: si on n'est pas dans un QR emplacement, on force tout de même le scan
            if (!this.slot?.id) {
                return false
            }
            // autorise de passer commande même si le QR slot n'est pas/plus valide
            const cart = this.$store.getters['cart/cart']

            const total = this.$store.getters['cart/products'].reduce((sum, cartproduct) => {
                sum = sum + (cartproduct.quantity * cartproduct.price)
                return sum
            }, 0)

            return !cart || total > 0
        }
    },

    watch: {
        selectedSlot(v) {
            if (!v || v === this.aslot) {
                return
            }
            const route = this.$router.resolve({
                name: this.getContextRouteNameFromRoute(this.$route.name, v),
                params: {
                    slug: this.slug,
                    aslot: v
                }
            })
            this.redirectToSlot(this.slug, v, `${route.href}?scanok=1`)
        }
    },

    async mounted() {
        this.selectedSlot = this.aslot

        this.scanned = !!this.$route.query.scanok
        if (!this.aslot) {
            // this.errorMessage = this.loose ? 'noslotLoose' : 'noslot'
            this.errorMessage = 'noslot'
            return
        }
        if (this.loose) {
            this.scanned = true
            return
        }

        this.loading = true
        const valid = await this.$store
            .dispatch('out/check', { slug: this.slug, table: this.aslot, token: this.$route.query.t })
            .catch(err => {
                this.$err(err, { silent: true })
                return false
            })

        if (valid) {
            this.$store.dispatch('out/start')
        } else {
            this.errorMessage = 'invalid'
        }
        this.loading = false
    },

    beforeDestroy() {
        this.$store.dispatch('out/end')
    },

    methods: {
        onScan(url) {
            this.errorMessage = null
            // on a ici une URL myaccessing valide. On va maintenant voir si cette
            // url contient un slug d'emplacement
            const reg = /T:::[0-9]+:::([^:]+):::([^:]+):::/
            const matches = url.match(reg)
            if (!matches || matches.length < 2) {
                // Ce QR code n'est pas un QR d'emplacement
                this.errorMessage = 'badqr'
                return
            }
            const salepointSlug = matches[1]
            const slotSlug = matches[2]

            const nextUrl = window.location.href = `${url}?p=${this.$route.name}`

            return this.redirectToSlot(salepointSlug, slotSlug, nextUrl)
        },

        redirectToSlot(salepointSlug, slotSlug, url) {
            this.loading = true

            if (salepointSlug !== this.slug) {
                // on scan un QR d'un autre établissement, le panier ne peut plus être valable
                // on le vide
                return this.$store.dispatch('cart/remove', { slug: this.slug })
                    .catch(err => this.$err(err, true))
                    .finally(() => (window.location.href = url))
            }

            return this.$store.dispatch('cart/update', { slug: salepointSlug, slot_slug: slotSlug })
                .then(() => {
                    // la page se recharge, donc pas besoin de passer loading à false
                    window.location.href = url
                })
                .catch(err => {
                    this.loading = false
                    this.$err(err)
                })
        }
    }
}
</script>
