import Vue from 'vue'
import moment from 'moment'
import store from '@/store'

Vue.filter('num', (value, currency) => {
    if (!currency) {
        return value
    }
    const n = new Intl.NumberFormat(store.getters['ln/currentWithRegion'], { style: 'currency', currency: currency })
    return n.format(value)
})

Vue.filter('sign', (value, currency, opts) => {
    opts = opts || {}
    if (!currency) {
        return value
    }
    const n = new Intl.NumberFormat(store.getters['ln/currentWithRegion'], { style: 'currency', currency: currency })
    let result = opts.full ? n.format(value) : n.format(value).replace(/[^0-9 .',-]/g, '')
    if (!value || value < 0) {
        return result
    }
    // récupère la 1ère occurence d'un nombre, dans le cas où la monnaie se place devant le nombre
    let i
    for (i = 0; result[i] < '0' || result[i] > '9'; i += 1);
    i = i < result.length ? i : 0
    result = result.slice(0, i) + (opts.small ? '<small> + </small>' : ' + ') + result.slice(i)

    return result
})

/**
 * Le login team se fait sur l'email, comme pour tous les autres users. Sauf
 * que les teams ne doivent pas tout saisir. Et les responsables d'établissements
 * n'ont pas forcément d'email à dispo.
 * On crée donc des mails corrects, mais inexistants, pour la connexion. Et on
 * ne montre que la 1ère partie de l'email.
 *
 * exemple: team-1@salepoint-slug.accessing.app
 * devient: team-1@salepoint-slug
 *
 * Et c'est ce dernier que la team saisit pour se connecter
 */
Vue.filter('teamEmail', value => {
    if (!value) {
        return
    }
    return value.toString().replace('.accessing.app', '')
})

/**
 * Formate une date avec moment. La prop [lang] n'est utilisée
 * que pour permettre la mise à jour de la vue automatiquement
 * lorsqu'on change la langue
 */
Vue.filter('date', (value, lang, format, inputFormat) => {
    if (!value) {
        return
    }
    return moment(value, inputFormat || undefined).format(format)
})

Vue.filter('daterange', (from, to, formats) => {
    if (!from || !to) {
        return
    }
    return moment().rangeDate(from, to, formats)
})
