<template>
    <v-dialog v-model="show" max-width="400">
        <template #activator="{ on }">
            <v-btn block text color="primary" v-on="on">
                {{ $t('user.password.change') }}
            </v-btn>
        </template>
        <v-card>
            <acs-base-card-title :title="$t('user.password.change')" @close="show = false" :dismissible="!loading" />
            <v-card-text class="py-4" v-marked="$t('user.password.changehelp', { email: email })"></v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    @click="send"
                    :loading="loading">
                    {{ $t('user.password.emailsend') }}
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'

export default {
    name: 'acs-user-password-change',
    components: { AcsBaseCardTitle },
    props: {
        email: { type: String }
    },
    data: () => ({
        show: false,
        loading: false
    }),
    methods: {
        send() {
            this.$store.commit('msg/show', { message: this.$i18n.t('user.password.emailsent') })
            this.show = false
        }
    }
}
</script>
