import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import NotFound from '@/views/NotFound.vue'
import Home from '@/views/Home.vue'

import store from '@/store'

import salepoints from './salepoints'
import slots from './slots'
import menus from './menus'
import surveys from './surveys'
import users from './users'
import products from './products'

Vue.use(VueRouter)

const router = new VueRouter({
    scrollBehavior: (to) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        }
        return goTo(scrollTo)
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes: []
        .concat(salepoints)
        .concat(slots)
        .concat(menus)
        .concat(surveys)
        .concat(users)
        .concat(products)
        .concat([{
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: '',
                dense: true
            }
        }, {
            path: '*',
            component: NotFound
        }])
})

router.beforeEach(async(to, from, next) => {
    // if lang is set in query, set as current language
    if (to?.query?.lang) {
        await store.dispatch('ln/current', { current: to.query.lang })
    }
    if (to.query.p) {
        const routeName = to.query.p
        delete to.query.p
        return next({ name: routeName, params: to.params, query: to.query })
    }
    const promises = []
    if (to.params.slug) {
        promises.push(store.dispatch('sp/current', { slug: to.params.slug }))
    }
    if (to.params.aslot) {
        promises.push(store.dispatch('sp/slot', { slug: to.params.slug, slot: to.params.aslot }))
    }

    return Promise.all(promises)
        .then(() => next())
        .catch(err => {
            store.commit('msg/show', {
                message: Vue.prototype.$err(err, { returnMessage: true }),
                type: 'error'
            })
            next({ name: 'home' })
        })
})

export default router
