import Vue from 'vue'
import lodash from 'lodash'

export default {
    namespaced: true,

    state: () => ({
        showPopupDone: false
    }),

    getters: {
        showPopupDone: state => state.showPopupDone
    },

    mutations: {
        showPopupDone(state, data) {
            state.showPopupDone = data.value
        }
    },

    actions: {
        download({ state }, { url, params, hook, timeout, sendmail }) {
            if (!url) {
                throw new Error('[url] param is required')
            }

            // on doit créer la window avant de faire une quelconque action
            // asynchrone, sinon ça ne passe pas sous Safari
            const win = !sendmail && window.open()
            state.showPopupDone = false

            return (hook ? hook(params) : Promise.resolve())
                .then(() => this.dispatch('auth/refreshToken'))
                .then(() => {
                    if (sendmail) {
                        return Vue.prototype.$api.get(url, { params, timeout: timeout || 1000 })
                            .then(() => {
                                state.showPopupDone = true
                            })
                            .catch(err => {
                                if (err.code === 'ECONNABORTED') {
                                    state.showPopupDone = true
                                    return
                                }
                                throw err
                            })
                    }

                    const data = lodash.isString(params)
                        ? params
                        : Object.entries(params || {}).reduce((acc, p) => {
                            if (p[1] === undefined) {
                                return acc
                            } else if (p[1] === false) {
                                p[1] = '0'
                            } else if (!p[1] && p[1] !== 0) {
                                p[1] = ''
                            } else if (Array.isArray(p[1])) {
                                p[1] = p[1].join(',')
                            } else if (lodash.isDate(p[1])) {
                                p[1] = p[1].toISOString()
                            } else if (lodash.isObject(p[1])) {
                                p[1] = JSON.stringify(p[1])
                            }
                            return `${acc}&${p[0]}=${p[1]}`
                        }, '')

                    win.location = `${Vue.prototype.$api.defaults.baseURL}${url}?${data}${this.getters['ln/query']}`

                    return new Promise(resolve => window.setTimeout(resolve, timeout || 1))
                })
                .catch(err => {
                    win && win.close()
                    throw err
                })
        }
    }
}
