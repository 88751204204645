<template>
    <v-card flat class="acs-product-navigation flex-grow-1" max-width="800">
        <v-card-actions class="pa-0">
            <v-btn
                v-if="previous"
                :to="{ name: getContextRouteName('productform'), params: { slug, category: previous.category_id, product: previous.id } }"
                :loading="loading"
                color="primary"
                outlined
                x-small>
                <v-icon x-small left>$vuetify.icons.baseLeft</v-icon>
            </v-btn>
            <v-btn
                v-if="previousCategory"
                :to="{ name: getContextRouteName('productlist'), params: { slug, category: previousCategory.id } }"
                :loading="loading"
                color="primary"
                outlined
                x-small>
                <v-icon x-small left>$vuetify.icons.baseLeft</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
                v-if="next"
                :to="{ name: getContextRouteName('productform'), params: { slug, category: next.category_id, product: next.id } }"
                :loading="loading"
                color="primary"
                outlined
                x-small>
                <v-icon x-small right>$vuetify.icons.baseRight</v-icon>
            </v-btn>
            <v-btn
                v-if="nextCategory"
                :to="{ name: getContextRouteName('productlist'), params: { slug, category: nextCategory.id } }"
                :loading="loading"
                color="primary"
                outlined
                x-small>
                <v-icon x-small right>$vuetify.icons.baseRight</v-icon>
            </v-btn>
        </v-card-actions>
        <acs-service-timeframe-selected />
    </v-card>
</template>

<script>
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-product-navigation',

    props: {
        product: { type: String },
        category: { type: String }
    },

    mixins: [RouteMixin],

    data: () => ({
        loading: false,
        previousCategory: null,
        nextCategory: null
    }),

    computed: {
        slug() {
            return this.$store.getters['sp/current'].slug
        },
        navigation() {
            return this.$store.getters['prod/navigation']
        },
        categories() {
            return this.$store.getters['prod/categoriesFlat']
        },
        previous() {
            const index = this.navigation.findIndex(p => p.id === this.product) - 1
            return index >= 0 ? this.navigation[index] : null
        },
        next() {
            const index = this.navigation.findIndex(p => p.id === this.product) + 1
            return index < this.navigation.length ? this.navigation[index] : null
        },
        navigateTo() {
            return this.$store.getters['prod/navigateTo']
        }
    },

    watch: {
        product: 'fetchSiblingCategories',

        navigateTo(direction) {
            if (!direction) {
                return
            }
            const product = direction === 'prev' ? this.previous : this.next
            const category = direction === 'prev' ? this.previousCategory : this.nextCategory

            if (product) {
                this.$router.push({
                    name: this.getContextRouteName('productform'),
                    params: { slug: this.slug, category: product.category_id, product: product.id }
                })
            } else if (category) {
                this.$router.push({
                    name: this.getContextRouteName('productlist'),
                    params: { slug: this.slug, category: category.id }
                })
            }
            this.$store.commit('prod/navigateTo', { direction: null })
        }
    },

    mounted() {
        return this.fetchSiblingCategories()
    },

    methods: {
        async fetchSiblingCategories() {
            try {
                this.loading = true
                this.previousCategory = null
                this.nextCategory = null

                const index = this.categories.findIndex(c => c.id === this.category)
                if (index === -1) {
                    this.loading = false
                    return
                }
                let i

                i = index - 1
                while (i >= 0 && !this.previousCategory && !this.previous) {
                    if (this.categories[i]?.products_count && !this.categories[i].children.length) {
                        this.previousCategory = this.categories[i]
                    }
                    i -= 1
                }

                i = index + 1
                while (i < this.categories.length && !this.nextCategory && !this.next) {
                    if (this.categories[i]?.products_count && !this.categories[i].children.length) {
                        this.nextCategory = this.categories[i]
                    }
                    i += 1
                }
                this.loading = false
            } catch (e) {
                this.loading = false
                this.$err(e)
            }
        }
    }
}
</script>
