<template>
    <div>
        <div class="d-flex justify-center mt-5">
            <v-btn color="primary" small rounded class="mr-3">{{ $t('home.guest') }}</v-btn>
            <v-btn color="primary" small rounded outlined class="mr-3" @click="show = true">{{ $t('home.loginBtn') }}</v-btn>
            <v-btn color="primary" small rounded outlined :href="createAccountUrl">{{ $t('home.createAccount') }}</v-btn>

            <v-dialog v-model="show" max-width="300" :persistent="loading">
                <v-card :loading="loading">
                    <acs-base-card-title :title="'hop'" :dismissible="!loading" @close="show = false" />
                    <v-card-text class="pt-5">
                        <acs-home-login-form @login="onLogin" />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
// root div is used to respond to v-show directive. d-flex class marks as important
// the display css rule. When v-show is applied with "false", it doesn't take
// precedence over d-flex. Thus, the component is still visible.

import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'
import AcsHomeLoginForm from '@/components/home/AcsHomeLoginForm'

export default {
    name: 'acs-pay-connect',

    components: { AcsBaseCardTitle, AcsHomeLoginForm },

    data: () => ({
        loading: false,
        show: false,
        valid: false,
        email: null,
        password: null
    }),

    computed: {
        createAccountUrl() {
            const query = {
                b: 'user',
                back: this.$route.path
            }
            const querystring = Object.entries(query).map(o => `${o[0]}=${o[1]}`).join('&')
            return `${this.$config.client}/account/signin?${querystring}`
        }
    },

    methods: {
        onLogin() {
            this.loading = true
            return this.$store.dispatch('auth/loadUser')
                .then(() => {
                    window.location.reload()
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
