import Vue from 'vue'

export default {
    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {

        async search(context, { slug, search }) {
            const res = await Vue.prototype.$api.get(`/salepoints/${slug}/slots`, {
                params: { search }
            })
            return res.data
        }
    }
}
