<template>
    <v-text-field
        :type="show ? 'text' : 'password'"
        :label="$t(`user.password.password${label}`) + (required ? ' *' : '')"
        :rules="rules"
        v-model="vpassword"
        :hide-details="current"
        :name="`password${label}`"
        @update:error="err => valid = !err"
        autocapitalize="none"
        :autocomplete="`${label}-password`"
        outlined
        :loading="checkEntropy">
        <template #progress>
            <v-progress-linear
                v-if="checkEntropy"
                :value="progress"
                :color="color()"
                background-color="grey"
                absolute
                height="3" />
        </template>
        <template #append v-if="vpassword">
            <v-icon
                tabindex="-1"
                @click="show = !show">
                {{ show ? '$vuetify.icons.passwordOn' : '$vuetify.icons.passwordOff' }}
            </v-icon>
        </template>
    </v-text-field>
</template>

<script>
import stringEntropy from 'fast-password-entropy'
import Autofill from '@/shared/mixins/Autofill'

export default {
    name: 'acs-user-password-field',
    mixins: [Autofill],
    props: {
        value: { type: String },
        disableEntropy: { type: Boolean },
        disableAutofill: { type: Boolean },
        current: { type: Boolean },
        newp: { type: Boolean },
        confirm: { type: Boolean },
        required: { type: Boolean },
        eq: { type: String },
        eqMessage: { type: String }
    },
    data: () => ({
        show: true,
        valid: false,
        progress: 0,
        meter: 0
    }),
    computed: {
        vpassword: {
            get() {
                return this.value || undefined
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        checkEntropy() {
            return !this.disableEntropy && (this.newp || !this.label)
        },

        entropy() {
            return this.$config.account.minPasswordEntropy
        },

        goodEntropy() {
            return this.$config.account.minPasswordEntropy * 1.2
        },

        label() {
            if (this.current) {
                return 'current'
            }
            if (this.newp) {
                return 'new'
            }
            if (this.confirm) {
                return 'confirm'
            }
            return ''
        },

        rules() {
            const rs = []
            if (!this.disableAutofill) {
                // pour le login, le autofill est actif. Et s'il est actif, le formulaire
                // se remplit MAIS aucun event n'est envoyé. Les champs sont null, le form
                // est invalide (alors que les champs sont remplis), bref, c'est la fête...
                return rs
            }

            if (this.required) {
                rs.push(this.$required)
            }
            if (this.checkEntropy) {
                rs.push(this.$password(this.color() === 'error', this.$config.account.minPasswordLength))
            }
            if (this.confirm) {
                rs.push(this.$eq(this.eq, this.eqMessage || this.$i18n.t('user.password.erroreq')))
            }
            return rs
        }
    },
    watch: {
        vpassword(v) {
            if (!this.checkEntropy) {
                return
            }
            this.meter = v ? stringEntropy(v) : 0
            this.progress = Math.min(1, this.meter / this.goodEntropy) * 100
            this.$emit('update:error', this.color() === 'error')
        }
    },
    mounted() {
        if (this.disableAutofill) {
            window.setTimeout(() => (this.show = false), 2000)
        } else {
            this.show = false
        }
    },
    methods: {

        color() {
            if (this.meter >= this.entropy && this.meter < this.goodEntropy) {
                return 'warning'
            }
            if (this.meter >= this.goodEntropy) {
                return 'success'
            }
            return 'error'
        }
    }
}
</script>
