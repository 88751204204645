import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'

export default {
    namespaced: true,

    state: {
        slug: null,
        psp: null,
        psps: [],
        data: null
    },

    getters: {
        data: state => state.data,
        psps: state => state.psps
    },

    actions: {
        async list({ state }, { slug, force = false }) {
            if (!force && state.psps.length && state.slug === slug) {
                return state.psps
            }
            const res = await Vue.prototype.$api.get(`/salepoints/${slug}/psp`)
            state.psps = res.data
            state.slug = slug

            return state.psps
        },

        async listAssets(context, { slug }) {
            const res = await Vue.prototype.$api.get(`/salepoints/${slug}/psp-assets`)
            return res.data
        },

        async getCheckoutData({ state }, { slug, psp }) {
            if (state.data && state.psp === psp && state.slug === slug) {
                return state.data
            }
            const res = await Vue.prototype.$api.get(`/salepoints/${slug}/psp/${psp}/checkout`)
            state.data = res.data
            state.psp = psp
            state.slug = slug

            return state.data
        },

        async checkout(context, { slug, psp, data, captcha_token }) {
            const res = await Vue.prototype.$api.post(`/me/salepoints/${slug}/cart/checkout`, {
                psp,
                psp_data: data,
                captcha_token,
                reqid: uuidv4()
            })
            return res.data.data || {}
        },

        async checkoutBill(context, { slug, psp, data, captcha_token }) {
            const res = await Vue.prototype.$api.post(`/me/salepoints/${slug}/paybill`, {
                psp,
                psp_data: data,
                captcha_token
            })
            return res.data.data || {}
        },

        async checkoutTerminal(context, { slug, psp, data, captcha_token }) {
            const res = await Vue.prototype.$api.post(`/me/salepoints/${slug}/terminal/${data.code}`, {
                psp,
                psp_data: data,
                captcha_token
            })
            return res.data.data || {}
        },

        async directOrder(context, { slug, captcha_token }) {
            const res = await Vue.prototype.$api.post(`/me/salepoints/${slug}/cart/direct`, {
                captcha_token
            })
            return res.data
        },

        async getTransactionStatus(context, { slug, psp, trx_id }) {
            const res = await Vue.prototype.$api.get(`/payments/salepoints/${slug}/psp/${psp}/transactions/${trx_id}`)
            return res.data
        },

        async createTerminalTransaction(context, { slug, type, data }) {
            const url = type === 'cart'
                ? `/me/salepoints/${slug}/cart/terminal`
                : `/me/salepoints/${slug}/terminal`

            const res = await Vue.prototype.$api.post(url, data)

            return {
                code: res.headers['acc-terminal-transaction-code'],
                qrcode: res.data
            }
        }
    }
}
