<template>
    <v-dialog v-model="show" max-width="400" :persistent="loading">
        <v-form v-model="valid" @submit.stop.prevent="submit">
            <v-card>
                <acs-base-card-title :title="$t('shared-front:usercard.addcard')" :dismissible="!loading" @close="show = false" />
                <v-card-text>
                    <div v-marked="$t('shared-front:usercard.savecard')"></div>
                    <div ref="payform"></div>
                    <v-alert :value="!!paymentElementError" type="error">{{ paymentElementError }}</v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        rounded
                        block
                        color="primary"
                        type="submit"
                        :disabled="disabled"
                        :loading="loading">
                        {{ $t('shared-front:base.add') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'

import AcsPspProviderStripeMixin from './AcsPspProviderStripeMixin'

export default {
    name: 'acs-psp-provider-stripe-card',

    components: { AcsBaseCardTitle },

    mixins: [AcsPspProviderStripeMixin],

    props: {
        value: { type: Boolean },
        slug: { type: String }
    },

    data() {
        return {
            // completed: false, --> dans le mixin
            // loading: false, --> dans le mixin
            // paymentElementError: null --> dans le mixin
            elements: null,
            valid: false
        }
    },

    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        disabled() {
            return this.loading || !this.valid || !this.completed
        }
    },

    watch: {
        show(v) {
            v && this.load()
        }
    },

    async mounted() {
        this.stripe = await this._getStripe().catch(err => this.$err(err))
    },

    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('usr/getSetupForNewCard', { slug: this.slug })
                .then(async({ secret }) => {
                    this.elements = await this._createElement(this.stripe, this.$refs.payform, secret)
                })
                .catch(err => {
                    this.loading = false
                    this.$err(err)
                })
        },

        async submit() {
            try {
                this.loading = true

                const route = this.$route

                const { error } = await this.stripe.confirmSetup({
                    elements: this.elements,
                    confirmParams: {
                        return_url: this.$config.front + route.path
                    }
                })
                if (error) {
                    throw error
                }

                // page will be refreshed by Stripe, so loading can continue
            } catch (err) {
                this.$err(err, { silent: true })
            } finally {
                this.loading = false
            }
        },

        reset() {
            this._reset()
            this.elements = null
        },

        async onConfirmPayment(route, { account_id }, { action, actionData }) {
            try {
                this.loading = true

                // on doit créer un nouveau Stripe avec le bon stripeaccount
                const stripe = await this._getStripe(account_id, { keepLoading: true })
                const { secret } = await this.$store.dispatch(action, actionData)

                // console.log(`step 3: confirm payment with secret [${secret}] and payment method [${sharedPaymentMethodId}]`)
                this.loading = true
                const payment = await stripe.confirmCardPayment(secret, { payment_method: actionData.payment_method_id })
                if (payment.error) {
                    const msg = `[${payment.error.type || 'unknown'}] ${payment.error.message || 'General error'}`
                    throw new Error(msg)
                }

                const redirectUrl = payment.paymentIntent?.id
                    ? this.$config.front + route.href + `?payment_intent=${payment.paymentIntent.id}`
                    : null

                // console.log('step last: payment done')

                if (redirectUrl) {
                    window.location.href = redirectUrl
                }
                // on laisse tourner le loading, parce qu'il y a redirection de toute façon
            } catch (err) {
                this.loading = false
                throw err
            }
        }
    }
}
</script>
