<template>
    <v-dialog v-model="show" max-width="400">
        <template #activator="{ on, attrs }">
            <v-icon
                :small="small"
                v-on="on"
                v-bind="attrs"
                color="primary">
                $vuetify.icons.baseInfo
            </v-icon>
        </template>
        <v-card>
            <acs-base-card-title
                :title="$options.filters.quickTranslate(service, 'name', translate)"
                @close="show = false" />

            <v-card-text class="mt-5">
                <div v-marked="$options.filters.quickTranslate(service, 'description', translate)"></div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'

export default {
    name: 'acs-service-info-popup',
    components: { AcsBaseCardTitle },
    props: {
        service: { type: Object },
        translate: { type: Boolean },
        small: { type: Boolean }
    },
    data: () => ({
        show: false
    })
}
</script>
