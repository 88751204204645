<template>
    <v-bottom-sheet v-model="menu" max-width="700">
        <template #activator="{ on }">
            <div class="d-flex">
                <v-spacer />
                <v-btn
                    v-on="on"
                    text
                    color="primary"
                    class="acs-user-password-lost--btn pr-1"
                    :tabindex="tabindex">
                    {{ $t('userlogin.lostbtn') }}
                </v-btn>
            </div>
        </template>
        <v-sheet>
            <v-card flat :loading="loading">
                <v-form v-model="valid" @submit.prevent.stop="submit" :disabled="loading">
                    <v-card-text>
                        {{ $t('userlogin.losttext') }}
                    </v-card-text>
                    <v-card-text>
                        <acs-user-email-field v-model="email" />
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            type="submit"
                            :disabled="!valid"
                            :loading="loading"
                            rounded
                            block
                            color="primary">
                            {{ $t('userlogin.lostsend') }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import AcsUserEmailField from '@/shared/components/user/AcsUserEmailField'

export default {
    name: 'acs-user-password-lost-btn',
    components: { AcsUserEmailField },
    props: {
        value: { type: String },
        tabindex: { type: [String, Number] }
    },
    data: () => ({
        valid: false,
        loading: false,
        menu: false
    }),
    computed: {
        email: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    methods: {
        submit() {
            this.loading = true
            return this.$store.dispatch('auth/sendLostToken', { email: this.email })
                .then(res => this.$success(res, { message: 'userlogin.lostsent' }))
                .then(() => {
                    this.menu = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass">
.acs-user-password-lost--btn
    text-transform: none
    letter-spacing: normal
    font-weight: bold
</style>
