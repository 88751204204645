<template>
    <v-text-field
        class="mb-5"
        v-model="time"
        :label="label + (!optional ? ' *' : '')"
        :placeholder="format"
        persistent-placeholder
        outlined
        :rules="rules"
        @keypress="sanitizeNumeric"
        @input="onChange" />
</template>

<script>
export default {
    name: 'acs-salepoint-schedule-timeslot',
    props: {
        value: { type: String },
        label: { type: String },
        min: { type: String },
        max: { type: String },
        manageMidnight: { type: Boolean },
        disabled: { type: Boolean },
        start: { type: String },
        seconds: { type: Boolean },
        neqMsg: { type: String },
        optional: { type: Boolean }
    },
    data: () => ({
        time: null
    }),
    computed: {
        format() {
            return this.seconds ? 'HH:mm:ss' : 'HH:mm'
        },
        rules() {
            const rules = [
                this.$time(this.min, this.max, this.seconds)
            ]
            if (this.min) {
                rules.push(this.$neq(this.start, this.neqMsg))
            }
            if (!this.optional) {
                rules.unshift(this.$required)
            }
            return rules
        }
    },
    watch: {
        value(v) {
            this.time = v
        }
    },
    mounted() {
        this.time = this.value
    },
    methods: {
        sanitizeNumeric(e) {
            if (!/^[0-9]$/.test(e.key)) {
                e.stopPropagation()
                e.preventDefault()
            }
        },

        update(v) {
            this.time = v
        },

        onChange(v) {
            if (this.time) {
                if (this.time.length === 2) {
                    this.time += ':'
                }
                if (this.time.length === 3 && !isNaN(this.time.substr(-2))) {
                    this.time = `${this.time.substr(0, this.time.length - 1)}:${this.time.substr(-1)}`
                }
                if (this.seconds && this.time.length === 5) {
                    this.time += ':'
                }
                if (this.seconds && this.time.length === 6 && !isNaN(this.time.substr(-2))) {
                    this.time = `${this.time.substr(0, this.time.length - 1)}:${this.time.substr(-1)}`
                }
            }
            const fn = this.$time(this.min, this.max)
            if (fn(this.time) === true) {
                this.$emit('input', this.time)
            }
        }
    }
}
</script>

<style lang="sass">
.acs-time--with-title .v-picker__title
    border-radius: 0 !important

</style>
