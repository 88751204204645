<template>
    <v-list v-if="connected" dense two-line :disabled="loading || disabled">

        <template>
            <v-list-item-group :value="showCards ? 0 : null" color="primary">
                <v-list-item @click="showCards = !showCards" :input-value="showCards" class="acs-card-list-item">
                    <v-list-item-icon class="acc-icon--center">
                        <v-icon v-if="!loading" large>{{ showCards ? '$vuetify.icons.baseDown' : '$vuetify.icons.baseRight' }}</v-icon>
                        <v-progress-circular v-else indeterminate color="grey" size="36" width="2" />
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('shared-front:usercard.savedcards') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>

            <v-list-item-group v-show="showCards" v-model="selected" color="primary">
                <v-alert :value="available && hasOtherPSPCards" type="info" color="primary" class="mb-0">
                    {{ $t('psp.hasothercards') }}
                </v-alert>
                <v-alert :value="!available && connected" type="warning" class="mb-0">
                    {{ $t('psp.cardsnotavailable') }}
                </v-alert>

                <template>
                    <acs-pay-card-list-item
                        v-for="(card, i) in cards"
                        :key="i"
                        :index="i"
                        :slug="slug"
                        :card="card"
                        class="acs-card-list-item"
                        @select="({ index }) => selected = index"
                        @remove="onCardRemove"
                    />

                    <v-list-item v-if="available" :key="cards.length" @click.stop="showPopup = true" class="acs-card-list-item">
                        <v-list-item-icon class="acc-icon--center">
                            <v-icon v-if="!loading" large>$vuetify.icons.plus</v-icon>
                            <v-progress-circular v-else indeterminate color="grey" size="36" width="2" />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('shared-front:usercard.addcard') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list-item-group>
        </template>

        <component
            v-if="psp && available"
            ref="provider"
            v-model="showPopup"
            :slug="slug"
            :psp="psp && psp.provider"
            :is="pspComponentName"
            :loading-indicator.sync="loading"></component>

        <v-input
            type="hidden"
            name="payment_method"
            :value="value && showCards"
            :rules="rules"
            single-line
            hide-details />
    </v-list>
</template>

<script>
import AcsPayCardListItem from '@/components/pay/AcsPayCardListItem'

import AcsPspProviderDemoCard from '@/components/psp/AcsPspProviderDemoCard'
import AcsPspProviderStripeCard from '@/components/psp/AcsPspProviderStripeCard'
import AcsPspProviderSaferpayCard from '@/components/psp/AcsPspProviderSaferpayCard'

/**
 * @abstract Pour le v-for="(card, i) in cards" , on utilise le (i) comme :key pour les
 * list-item, car c'est le seul moyen pour garantir le bon index. Si on met card.id,
 * par exemple, ça génère un bug avec le list-item contenant l'ajout d'une nouvelle carte.
 */
export default {
    name: 'acs-pay-card-list',
    components: {
        AcsPayCardListItem,
        AcsPspProviderDemoCard,
        AcsPspProviderStripeCard,
        AcsPspProviderSaferpayCard
    },
    props: {
        slug: { type: String },
        value: { type: String },
        disabled: { type: Boolean },
        required: { type: Boolean },
        showList: { type: Boolean },
        loadingIndicator: { type: Boolean }
    },
    data: () => ({
        showPopup: false
    }),
    watch: {
        showCards(v) {
            v && !this.value && this.setActiveCard()
            this.$emit('expanded', v)
        }
    },
    computed: {
        selected: {
            get() {
                const index = this.cards.findIndex(c => c.id === this.value)
                return index === -1 ? null : index
            },
            set(v) {
                const card = this.cards[v]
                this.$emit('input', !card ? null : card.id)
            }
        },
        loading: {
            get() {
                return this.loadingIndicator
            },
            set(v) {
                this.$emit('update:loadingIndicator', v)
            }
        },
        showCards: {
            get() {
                return this.showList
            },
            set(v) {
                this.$emit('update:show-list', v)
            }
        },
        cards() {
            return this.$store.getters['usr/cards']
        },
        available() {
            return this.$store.getters['usr/cardsAvailable']
        },
        rules() {
            const rules = []
            if (this.required) {
                rules.push(this.$required)
            }
            return rules
        },
        connected() {
            return this.$store.getters['auth/connected']
        },
        psp() {
            return this.$store.getters['psp/psps'].find(psp => psp.compliance)
        },
        pspComponentName() {
            if (!this.psp) {
                return ''
            }
            return `acs-psp-provider-${this.psp.provider}-card`
        },

        hasOtherPSPCards() {
            const providers = this.$store.getters['usr/cardsWhereIs']
            return this.psp && !this.loading && !this.cards.length && !!providers.length
        }
    },
    async mounted() {
        if (!this.connected) {
            return
        }
        this.loading = true
        return Promise
            .all([
                this.$store.dispatch('usr/cards', { slug: this.slug }),
                this.$store.dispatch('usr/cardsWhereIs')
            ])
            .then(() => {
                this.showCards = !!this.cards.length
                return this.setActiveCard()
            })
            .catch(err => this.$err(err))
            .finally(() => (this.loading = false))
    },
    methods: {
        setActiveCard() {
            // on set la carte par défaut s'il y en a une
            const index = this.cards.findIndex(c => c.active)
            if (index !== -1) {
                this.selected = index
            }
        },

        onCardRemove({ cardId }) {
            if (this.value === cardId) {
                this.selected = null
            }
        },

        onConfirmPayment(route, checkoutData, paymentData) {
            return this.$refs.provider.onConfirmPayment(route, checkoutData, paymentData)
        }
    }
}
</script>
