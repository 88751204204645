<template>
    <v-dialog v-model="show" fullscreen scrollable content-class="acs-document-preview">
        <v-card>
            <acs-base-card-title :title="title" :dismissible="false" back @close="show = false" toolbar />
            <v-card-text v-if="isImage" class="pa-0">
                <v-img :src="`${imgurl}`">
                    <template #placeholder>
                        <v-layout justify-center ma-0>
                            <v-progress-circular indeterminate color="grey" />
                        </v-layout>
                    </template>
                </v-img>
            </v-card-text>
            <iframe v-else :src="pdfurl" class="acs-document-preview--pdf" />
        </v-card>
    </v-dialog>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'
import PopupCloseMixin from '@/mixins/PopupCloseMixin'

export default {
    name: 'acs-menu-view',
    components: { AcsBaseCardTitle },
    props: {
        menu: { type: Object },
        value: { type: Boolean }
    },
    mixins: [PopupCloseMixin],
    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.endPopupHash()
                this.$emit('input', v)
            }
        },

        title() {
            return `${this.menu.title} - ${this.$store.getters['sp/current'].name}`
        },

        imgurl() {
            return this.menu.links_file
        },

        pdfurl() {
            // mozilla recommande d'utiliser encodeURIComponent() mais il
            // semble que ça ne fonctionne que sans (2020-07-01)
            return `/pdf/web/viewer.html?file=${this.imgurl}`
        },

        type() {
            return this.imgurl && this.menu.links_file.substr(this.menu.links_file.lastIndexOf('.') + 1).toLowerCase()
        },

        isImage() {
            return ['jpg', 'jpeg', 'jp2', 'webp', 'png', 'gif'].indexOf(this.type) !== -1
        }
    },
    watch: {
        show(v) {
            v && this.initPopupHash()
        },

        routehash(v) {
            if (!this.isPopupHash(v)) {
                this.show = false
            }
        }
    }
}
</script>

<style lang="sass">
.acs-document-preview--pdf, .acs-document-preview--url
    height: 100vh
    width: 100%
    border: 0

</style>
