export default {
    methods: {
        /**
         * Returns the good route name, depending on context. We can be inside
         * a salepoint, or inside a slot. Actions are similars, but routes names
         * are different.
         *
         * @param {String} name Route name
         * @returns {String} Real route name, depending on context
         */
        getContextRouteName(name) {
            const routeName = this.$route.params.aslot
                ? name
                : `${name}home`

            if (routeName === 'salepoint' && this.$route.params.aslot) {
                // exception sur la route des slots
                return 'salepointslot'
            }
            return routeName
        },

        /**
         *
         * @param {String} name Route name to check
         * @returns {Boolean}
         */
        isContextRouteName(name, currentRouteName) {
            currentRouteName = currentRouteName || this.$route.name
            return [name, `${name}home`].indexOf(currentRouteName) !== -1
        },

        /**
         * Returns route name depending on the presence of slot slug.
         *
         * @param {String} name Route name to check
         * @param {*} slot Slot slug, if any
         * @returns {String} New route name
         */
        getContextRouteNameFromRoute(name, slot) {
            const reg = /home$/
            if (slot) {
                return name.match(reg)
                    ? name.replace(reg, '')
                    : name
            }
            return name.match(reg)
                ? name
                : `${name}home`
        }
    }
}
