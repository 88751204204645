<template>
    <div>
        <v-snackbar v-for="msg in messages" :key="msg.id"
            :bottom="true"
            :timeout="msg.timeout"
            :color="msg.color"
            :multi-line="true"
            v-model="msg.show">
            {{ msg.message }}
            <template #action="{ attrs }">
                <v-btn v-if="msg.icon" text icon :to="msg.to || undefined" @click="msg.show = false">
                    <v-icon>{{ msg.icon }}</v-icon>
                </v-btn>
                <v-btn small text icon v-bind="attrs" @click="msg.show = false">
                    <v-icon x-small>{{ '$vuetify.icons.baseClose' }}</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: 'acs-base-message',
    computed: {
        messages() {
            return this.$store.getters['msg/messages']
        }
    }
}
</script>
