<template>
    <acs-base-container large center size="large">
        <v-card flat class="text-left" :loading="loading">
            <v-form v-if="transaction" v-model="valid" @submit.stop.prevent="submit">
                <v-divider class="mx-3" />
                <v-simple-table v-if="showDetail" dense>
                    <template #default>
                        <tbody>
                            <tr>
                                <td>{{ $t('cartpay.total') }}</td>
                                <td class="text-right">{{ total | num(currency) }}</td>
                            </tr>
                            <tr v-if="checkoutParams.discount">
                                <td>{{ $t('cartpay.totaloptions') }}</td>
                                <td class="text-right">{{ checkoutParams.discount | num(currency) }}</td>
                            </tr>
                            <tr v-if="checkoutParams.tip">
                                <td>{{ $t('cartpay.totalorder') }}</td>
                                <td class="text-right">{{ checkoutParams.tip | num(currency) }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <v-card-text>
                    <div class="d-flex justify-space-between text-h5">
                        <span>{{ $t('cartpay.pay') }}</span>
                        <span>{{ checkoutParams.amount | num(currency) }}</span>
                    </div>
                    <acs-pay-connect v-show="!connected" />
                </v-card-text>

                <acs-pay-cg v-model="conditionsAccepted" class="pa-3" />

                <acs-cart-pay-process
                    ref="payProcess"
                    :type="transaction.data.type"
                    :step.sync="step"
                    :disabled="!valid"
                    :slug="slug"
                    :aslot="transaction.data.slot_slug"
                    :captcha-enabled="this.$config.hcaptcha.enabled"
                    :direct="false"
                    :checkout-params="checkoutParams"
                    terminal />
            </v-form>
        </v-card>
    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsPayConnect from '@/components/pay/AcsPayConnect'
import AcsPayCg from '@/components/pay/AcsPayCg'
import AcsCartPayProcess from '@/components/cart/AcsCartPayProcess'

import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'cart-share',

    components: { AcsBaseContainer, AcsPayConnect, AcsCartPayProcess, AcsPayCg },

    props: {
        slug: { type: String, required: true },
        code: { type: String, required: true }
    },

    mixins: [RouteMixin],

    data: () => ({
        transaction: null,
        loading: false,
        step: 1,
        conditionsAccepted: false,
        valid: false,
        data: null,
        interval: null
    }),

    computed: {
        checkoutParams() {
            const params = this.transaction?.data?.checkout_params || {}
            params.code = this.code
            return params
        },
        showDetail() {
            const { tip = 0, discount = 0 } = this.checkoutParams
            return !!(tip || discount)
        },
        total() {
            const { amount, tip = 0, discount = 0 } = this.checkoutParams
            return amount - (tip || 0) - (discount || 0)
        },
        salepoint() {
            return this.$store.getters['sp/current']
        },
        currency() {
            return this.salepoint.currency_users && this.salepoint.currency_users.toUpperCase()
        },
        connected() {
            return this.$store.getters['auth/connected']
        },
        isOnlyTeamOrTerminal() {
            const team = this.$store.getters['auth/isOnly'](this.salepoint.id, 'team')
            const terminal = this.$store.getters['auth/isOnly'](this.salepoint.id, 'terminal')
            return team || terminal
        }
    },

    async mounted() {
        if (this.connected) {
            this.conditionsAccepted = true
        }
        window.clearInterval(this.interval)

        this.loading = true
        await Promise
            .all([
                this.$store.dispatch('psp/getTransactionStatus', { slug: this.slug, psp: 'terminal', trx_id: this.code }),
                this.$store.dispatch('sp/current', { slug: this.slug })
            ])
            .then(async([transaction]) => {
                this.transaction = transaction

                if (this.transaction.data.cart_id) {
                    await this.$store.dispatch('cart/share', { slug: this.slug, token: this.code })
                    this.$store.commit('cart/setCurrentCartId', { id: this.transaction.data.cart_id })
                }

                this.loading = false

                if (this.isOnlyTeamOrTerminal) {
                    window.alert('access denied')
                    return this.$router.replace({ name: 'home' })
                }

                await new Promise(resolve => window.setTimeout(resolve, 6 * 1000))
                this.interval = window.setInterval(() => this.getTransaction(this.code), 3 * 1000)
            })
            .catch(err => this.$err(err))
            .finally(() => (this.loading = false))
    },

    beforeDestroy() {
        window.clearInterval(this.interval)
    },

    methods: {

        getTransaction(trxCode) {
            this.loading = false // on ne montre pas le load, ça reste purement en background
            return this.$store.dispatch('psp/getTransactionStatus', { slug: this.slug, psp: 'terminal', trx_id: trxCode })
                .then(async trx => {
                    if (trx.done) {
                        window.clearInterval(this.interval)
                        if (trx.data?.cart_id) {
                            return this.finalizeCart(trx)
                        }
                        return this.finalizeBill(trx)
                    }
                })
                .catch(err => this.$err(err, { silent: true }))
                .finally(() => (this.loading = false))
        },

        finalizeCart(trx) {
            this.loading = true
            return this.$store.dispatch('order/getFromCart', { slug: this.slug, cartId: trx.data.cart_id })
                .then(async order => {
                    // reset des filtres, la commande est finalisée
                    this.$store.dispatch('prod/saveFilters', {})
                    // intégration dans le cache local
                    this.$store.dispatch('order/addInCache', { slug: this.slug, orderId: order.id, order })
                    // suppression du panier
                    await this.$store.dispatch('cart/remove', { slug: this.slug })
                    // redirection sur la page de succès
                    return this.$router.push({
                        name: this.getContextRouteName('cartsuccessorder'),
                        params: { orderId: order.id }
                    })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        finalizeBill(trx) {
            this.loading = true
            return this.$router.push({
                name: this.getContextRouteName('paysuccess')
            })
        }
    }
}
</script>

<style lang="sass">
.acs-pay-wizard
    border: 0
    box-shadow: none !important
</style>
