import Vue from 'vue'
import VueSocketIOExt from 'vue-socket.io-extended'
import SocketIO from 'socket.io-client'
import store from '../../store'

const socket = SocketIO(process.env.VUE_APP_SOCKET, {
    autoConnect: false,
    path: process.env.VUE_APP_SOCKET_PATH,
    pingInterval: 2000,
    pingTimeout: 4000
})

Vue.use(VueSocketIOExt, socket, { store })

store.commit('socket/init', { socket: socket })
