<template>
    <v-expansion-panels class="text-left acs-slot--survey" accordion v-if="show">
        <v-card flat>
            <v-expansion-panel class="pa-0">
                <v-expansion-panel-header class="pa-0">
                    <v-card-title class="pa-4">
                        <acs-base-svg src="/img/star.svg" />
                        <div class="acs-slot--lock-title flex-grow-1">{{ $t('survey.title') }}</div>
                    </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card-text class="px-2 pt-0">
                        {{ $t('survey.message') }}
                    </v-card-text>
                    <v-card-actions class="px-0">
                        <v-btn text color="primary" @click="doSurvey">{{ $t('survey.btn.go') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" v-if="close" @click="hide">{{ $t('survey.btn.later') }}</v-btn>
                    </v-card-actions>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-card>
    </v-expansion-panels>
</template>

<script>
import AcsBaseSvg from '@/shared/components/base/AcsBaseSvg'

export default {
    name: 'acs-slot-survey',

    components: { AcsBaseSvg },

    props: {
        slug: { type: String },
        close: { type: Boolean, default: true }
    },

    data: () => ({
        loading: false,
        show: false
    }),

    mounted() {
        return this.load()
    },

    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('sur/schema', { slug: this.slug })
                .then(schema => {
                    this.show = !!schema.length
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        doSurvey() {
            this.$emit('survey')
        },

        hide() {
            this.show = false
            this.$emit('hide')
        }
    }
}
</script>

<style lang="sass">
.acs-slot--survey
    .markdown code
        background-color: transparent
        color: black
        box-shadow: none

    .v-card__title
        flex-wrap: nowrap !important

    &.v-expansion-panels--accordion
        display: block

</style>
