<template>
    <v-card flat>
        <v-form v-model="valid" @submit.stop.prevent="submit">
            <v-card-title>
                <div>{{ salepoint.name }}</div>
            </v-card-title>
            <v-card-text>
                <p>{{ $t('pay.title') }}</p>
                <div class="acs-pay-field py-5">
                    <v-text-field
                        name="payamount"
                        v-model="payamount"
                        type="number"
                        :rules="[$required, $ranged(0)]"
                        :autofocus="$vuetify.breakpoint.mdAndUp" />
                    <span class="pl-3 acs-pay-currency">{{ currency }}</span>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn block rounded color="primary" type="submit" :disabled="!valid">
                    {{ $t('pay.next') }}
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
export default {
    name: 'acs-pay-amount',
    props: {
        currency: { type: String },
        value: { type: [String, Number] }
    },
    data: () => ({
        valid: false
    }),
    computed: {
        payamount: {
            get() {
                return this.value || this.value === 0 ? Number(this.value) : null
            },
            set(v) {
                this.$emit('input', v || v === 0 ? Number(v) : null)
            }
        },
        salepoint() {
            return this.$store.getters['sp/current'] || {}
        }
    },
    methods: {
        submit() {
            this.$emit('submit')
        }
    }
}
</script>

<style lang="sass">
.acs-pay-field
    display: flex
    align-items: center
    justify-content: center

    .v-input
        max-width: 5em
        font-weight: 500
        font-size: 48px

    input
        text-align: center
        max-height: none
        -moz-appearance: textfield

    input::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

    .acs-pay-currency
        font-size: 120%
</style>
