import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        availableCardBrands: [],
        cards: [],
        cardsWhereIs: [],
        cardsSalepoint: null,
        cardsAvailable: false
    },

    getters: {
        cards: state => state.cards,
        cardsWhereIs: state => state.cardsWhereIs,
        cardsAvailable: state => state.cardsAvailable
    },

    mutations: {
        setDefaultCard(state, { cardId }) {
            state.cards.forEach(card => {
                card.active = card.id === cardId
            })
        },

        reset(state) {
            state.cards = []
            state.availableCardBrands = []
        }
    },

    actions: {

        async cardsWhereIs({ state }) {
            if (state.cardsWhereIs.length) {
                return state.cardsWhereIs
            }
            const res = await Vue.prototype.$api.get('/me/cards-whereis', {
                params: {
                    include_details: true
                }
            })
            state.cardsWhereIs = res.data
            return state.cardsWhereIs
        },

        async cards({ state }, { slug }) {
            if (state.cards.length && state.cardsSalepoint === slug) {
                return state.cards
            }
            try {
                const res = await Vue.prototype.$api.get(`/me/salepoints/${slug}/cards`)
                state.cardsAvailable = true
                state.cards = res.data
                return state.cards
            } catch (err) {
                if (err?.response?.data?.error?.type === 'psp_not_found') {
                    state.cardsAvailable = false
                    state.cards = []
                    return state.cards
                }
                throw err
            }
        },

        async getSetupForNewCard(context, { slug, data }) {
            const res = await Vue.prototype.$api.post(`/me/salepoints/${slug}/cards`, {
                psp_data: data
            })
            return res.data
        },

        async setDefaultCard({ commit }, { slug, cardId }) {
            await Vue.prototype.$api.post(`/me/salepoints/${slug}/cards/${cardId}/default`)
            commit('setDefaultCard', { cardId })
        },

        async removeCard({ state, commit }, { slug, cardId }) {
            const res = await Vue.prototype.$api.delete(`/me/salepoints/${slug}/cards/${cardId}`)

            const index = state.cards.findIndex(c => c.id === cardId)
            if (index !== -1) {
                state.cards.splice(index, 1)
            }

            if (res.data.new_default_payment_method_id) {
                commit('setDefaultCard', {
                    cardId: res.data.new_default_payment_method_id
                })
            }
        },

        async listAvailablePaymentMethods({ state }, { slug, psp }) {
            if (state.availableCardBrands.length) {
                return state.availableCardBrands
            }
            const res = await Vue.prototype.$api.get(`/salepoints/${slug}/psp/${psp}/available-cards`)

            state.availableCardBrands = res.data

            return state.availableCardBrands
        }
    }
}
