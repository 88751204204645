<template>
    <v-list dense>
        <v-list-item-group v-model="selected" color="primary" mandatory>
            <v-list-item v-for="service in services" :key="service.id" :disabled="disabled">
                <template #default="{ active }">
                    <v-list-item-action :class="{ 'mr-0': dense }">
                        <v-icon small>{{ active ? '$vuetify.icons.ecomSelectOn' : '$vuetify.icons.ecomSelectOff' }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content class="acs-list-item--multiline">
                        <v-list-item-title v-if="service.id === 'all'">{{ $t('productfilter.all') }}</v-list-item-title>
                        <v-list-item-title v-else>{{ service | quickTranslate('name', translate) }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="service.description">
                        <acs-service-info-popup :service="service" :translate="translate" small />
                    </v-list-item-action>
                </template>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
import AcsServiceInfoPopup from '@/components/service/AcsServiceInfoPopup'

export default {
    name: 'acs-service-selector',
    components: { AcsServiceInfoPopup },
    props: {
        value: { type: String },
        slug: { type: String },
        dense: { type: Boolean },
        translate: { type: Boolean },
        disabled: { type: Boolean }
    },
    data: () => ({
        loading: false
    }),
    computed: {
        services() {
            const services = this.$store.getters['ser/orderableServices'].slice()
            services.unshift({
                id: 'all'
            })
            return services
        },

        selected: {
            get() {
                const index = this.services.findIndex(s => s.id === this.value)
                return index === -1 ? 0 : index
            },
            set(v) {
                const service = this.services[v]
                this.$emit('input', !service || service.id === 'all' ? null : service.id)
            }
        }
    },
    watch: {
        translate(v) {
            v && this.load(true)
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load(force) {
            this.loading = true
            return this.$store.dispatch('ser/services', { slug: this.slug, force: force, translate: this.translate })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass">
.acs-list-item--multiline
    .v-list-item__title, .v-list-item__subtitle
        overflow: hidden
        white-space: initial
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        display: -webkit-box

</style>
