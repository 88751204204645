<template>
    <div class="d-flex flex-column align-center">
        <v-row>
            <v-col>
                <v-simple-table dense>
                    <template #default>
                        <tbody>
                            <tr v-if="timeframe.frame_price">
                                <td>{{ $t('servicetimeframe.fee') }}</td>
                                <td>{{ framePrice | num(currency) }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col>
                <v-date-picker
                    v-model="date"
                    color="primary"
                    :locale="calendar.locale"
                    :first-day-of-week="calendar.firstDayOfWeek"
                    :allowed-dates="getAllowedDates"
                    :min="minDate"
                    :max="maxDate"
                    no-title
                    @update:picker-date="onDateChange" />
            </v-col>
        </v-row>
        <v-row style="width: 100%;">
            <v-col class="flex-grow-0 px-2">
                <v-icon @click="move(-1)" :loading="loading" small>$vuetify.icons.baseLeft</v-icon>
            </v-col>
            <v-col v-for="cal in calendarDates" :key="cal.date" class="px-2">
                <acs-service-timeframe-calendar-date-header :date="cal.date" />
                <div class="d-flex flex-column align-center">
                    <template v-for="(date, i) in cal.dates">
                        <acs-service-timeframe-calendar-date
                            v-if="date.name"
                            :key="date.name"
                            :slug="slug"
                            :service-id="serviceId"
                            :calendar-date="date"
                            :is-day="cal.is_day"
                            :selected="selected.includes(date.date)"
                            :disabled="date.stock === 0"
                            @click="select(date)"
                        />
                        <p v-else :key="`date-${i}`">-</p>
                    </template>
                </div>
            </v-col>
            <v-col class="flex-grow-0 px-2">
                <v-icon @click="move(1)" :loading="loading" small>$vuetify.icons.baseRight</v-icon>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import AcsServiceTimeframeCalendarDateHeader from '@/components/service-timeframe/AcsServiceTimeframeCalendarDateHeader'
import AcsServiceTimeframeCalendarDate from '@/components/service-timeframe/AcsServiceTimeframeCalendarDate'

import moment from 'moment'

export default {
    name: 'acs-service-timeframe-calendar',

    components: {
        AcsServiceTimeframeCalendarDateHeader,
        AcsServiceTimeframeCalendarDate
    },

    props: {
        slug: { type: String },
        serviceId: { type: [String, Number] },
        serviceAdvancedId: { type: String },
        value: { type: Array },
        timeframe: { type: Object },
        productIds: { type: Array, default: () => [] }
    },

    data: () => ({
        loading: false,
        date: null,
        currentDate: new Date().toISOString().substring(0, 10),
        calendarDates: [],
        availableDates: []
    }),

    computed: {
        calendar() {
            return this.$store.getters['ln/calendar']
        },

        currency() {
            return this.$store.getters['sp/current'].currency_users
        },

        selected: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        framePrice() {
            return (this.timeframe.frame_price || 0) * this.selected.length
        },

        minDate() {
            if (this.timeframe.min_day_delay) {
                return moment(this.currentDate).add(this.timeframe.min_day_delay, 'day').format('YYYY-MM-DD')
            }
            return this.currentDate
        },

        maxDate() {
            if (this.timeframe.max_day_delay) {
                return moment(this.currentDate).add(this.timeframe.max_day_delay, 'day').format('YYYY-MM-DD')
            }
            return undefined
        }
    },

    watch: {
        date: 'reload'
    },

    methods: {
        getAllowedDates(date) {
            return this.availableDates.includes(date)
        },

        setDate(date) {
            this.date = date
        },

        reload() {
            if (!this.date) {
                return
            }
            this.loading = true
            return this.$store
                .dispatch('ser/listTimeframesCalendar', {
                    slug: this.slug,
                    service_id: this.serviceId,
                    service_advanced_id: this.serviceAdvancedId,
                    date: this.date,
                    product_ids: this.productIds
                })
                .then(calendar => {
                    // si l'écran est trop petit, on retire les éléments qu'on n'arrivera pas à afficher
                    if (this.$vuetify.breakpoint.xsOnly) {
                        calendar.pop()
                        calendar.shift()
                    }

                    const maxDateSize = calendar.reduce((max, day) => {
                        const size = day.dates.length
                        return size > max ? size : max
                    }, 0)

                    this.calendarDates = calendar.map(day => {
                        const fill = maxDateSize - day.dates.length
                        for (let i = 0; i < fill; i += 1) {
                            day.dates.push({ name: null })
                        }
                        return day
                    })
                })
                .finally(() => (this.loading = false))
        },

        reloadAvailableDates(date) {
            this.loading = true
            return this.$store
                .dispatch('ser/listAvailableDates', {
                    slug: this.slug,
                    service_id: this.serviceId,
                    service_advanced_id: this.serviceAdvancedId,
                    date,
                    product_ids: this.productIds
                })
                .then(dates => {
                    this.availableDates = dates
                    if (!this.date) {
                        this.date = dates.length ? dates[0] : new Date().toISOString().substring(0, 10)
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        onDateChange(yearMonth) {
            return this.reloadAvailableDates(`${yearMonth}-01`)
        },

        select(date) {
            const index = this.selected.findIndex(s => s === date.date)
            if (index === -1) {
                const max = this.timeframe.nb_frames_per_order_max
                if (max === 1 && max === this.timeframe.nb_frames_per_order_min && max === this.selected.length) {
                    // dans ce cas, on enlève le créneau existant et on le remplace
                    this.selected = [date.date]
                    return
                }

                if (max > 0 && this.selected.length >= max) {
                    return
                }
                this.selected.push(date.date)
                this.selected.sort()
            } else {
                this.selected.splice(index, 1)
            }
        },

        move(quantity) {
            const date = new Date(new Date(this.date).getTime() + (quantity * 1000 * 60 * 60 * 24))
            this.date = date.toISOString().substring(0, 10)
        }
    }
}
</script>
