/**
 * Un bug Chrome fait que le [autofill] des champs n'envoie aucun
 * event au js. Il n'y a donc aucun moyen de savoir si le champ a
 * été autofillé.
 *
 * Par ailleurs, le [.value] du DOM n'est pas renseigné avec autofill
 * pour des raisons de sécurité! Donc pas possible d'y avoir accès, ni
 * ici ni pour vue/vuetify. Les checks, notamment les [required] du
 * formulaire ne sont donc pas pris en compte.
 *
 * Ce mixin permet seulement de factoriser le code nécessaire pour forcer
 * le label à "monter" au-dessus du champ, si celui-ci a été autofillé.
 *
 * @see https://bugs.chromium.org/p/chromium/issues/detail?id=352527
 * @see https://bugs.chromium.org/p/chromium/issues/detail?id=1166619#c6
 * @see https://stackoverflow.com/questions/35049555/chrome-autofill-autocomplete-no-value-for-password
 */

export default {
    data: () => ({
        autofillInterval: null,
        autofillMax: 20,
        autofillDirty: false
    }),

    mounted() {
        let max = 0
        this.autofillInterval = window.setInterval(() => {
            max += 1
            if (this.autofillDirty || max > this.autofillMax) {
                window.clearInterval(this.autofillInterval)
            }
            const input = this.$el.querySelector('input:-webkit-autofill')
            if (input) {
                const label = this.$el.querySelector('.v-label')
                label.className += ' v-label--active'
                this.$el.className += ' v-input--is-label-active v-input--is-dirty input-group--dirty'

                this.autofillDirty = true

                this.$nextTick(() => {
                    this.$el.querySelector('legend').style.width = `${label.offsetWidth - 20}px`
                })
            }
        }, 100)
    },

    beforeDestroy() {
        window.clearInterval(this.autofillInterval)
    }
}
