<template>
    <v-card class="text-left acs-slot--lock" ref="card">
        <v-card-title>
            <acs-base-svg src="/img/clock.svg" />
            <div class="acs-slot--lock-title flex-grow-1">
                <template v-if="!callMissed">
                    {{ $t(`salepointtable.locktitle${validated ? 'go' : ''}`) }}
                </template>
                <template v-else>
                    {{ $t('salepointtable.locktitlemiss') }}
                </template>
            </div>
            <v-tooltip v-if="percent < 100" :attach="$el" bottom min-width="120">
                <template #activator="{ on }">
                    <v-icon v-on="on" :color="validated ? 'primary' : 'grey'">$vuetify.icons.waitRead</v-icon>
                </template>
                <span>{{ $t(`salepointtable.${validated ? 'lockread' : 'lockwait'}`) }}</span>
            </v-tooltip>
            <v-icon v-else color="grey" @click="close">$vuetify.icons.baseClose</v-icon>
        </v-card-title>
        <v-card-text class="acs-slot--lock-text">
            <template v-if="!callMissed">
                {{ $t('salepointtable.lock', { timer: counter }) }}
            </template>
            <template v-else>
                {{ $t('salepointtable.lockmiss') }}
            </template>
        </v-card-text>
        <v-progress-linear rounded :value="percent" />
    </v-card>
</template>

<script>
import AcsBaseSvg from '@/shared/components/base/AcsBaseSvg'

export default {
    name: 'acs-slot-lock',
    components: { AcsBaseSvg },
    computed: {
        percent() {
            return this.$store.getters['lock/percent']
        },

        counter() {
            return this.$store.getters['lock/counter']
        },

        validated() {
            return this.$store.getters['lock/validated']
        },

        slot() {
            return this.$store.getters['sp/slot']
        },

        callMissed() {
            return !this.validated && this.percent >= 100
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="sass">
.acs-slot--lock
    .v-card__title
        flex-wrap: nowrap !important

    .markdown code
        background-color: transparent
        color: black
        box-shadow: none

.acs-slot--lock-title
    font-size: 1.5rem
    padding-left: 10px
    word-break: normal

.acs-slot--lock-text
    text-align: left
</style>
