<template>
    <v-dialog v-model="show" fullscreen scrollable :disabled="disabled">
        <template #activator="{ on, attrs }">
            <slot name="activator" :on="on" :attrs="attrs"></slot>
        </template>
        <v-card tile>
            <v-card-title class="grey lighten-3">
                <div>Web logger</div>
                <v-spacer />
                <v-icon @click="show = false">mdi-close</v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <template v-for="(log, i) in logs">
                    <div :key="`m-${i}`">{{ log.message }}</div>
                    <v-divider :key="`k-${i}`" />
                </template>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
// used for iOS mobile debugging
export default {
    name: 'debug-web-logger',
    props: {
        disabled: { type: Boolean }
    },
    data: () => ({
        show: false
    }),
    computed: {
        logs() {
            return this.$store.getters['dbg/logs']
        }
    }
}
</script>
