import Survey from '@/views/Survey.vue'
import SurveyFinish from '@/views/SurveyFinish.vue'

export default [
    {
        path: '/:slug/survey',
        name: 'surveyhome',
        component: Survey,
        props: true,
        meta: {
            title: 'dosurvey.title',
            back: 'salepointhome',
            menu: false
        }
    },
    {
        path: '/:slug/surveyfinish',
        name: 'surveyfinishhome',
        component: SurveyFinish,
        props: true,
        meta: {
            title: 'survey.title',
            back: 'salepointhome',
            menu: false
        }
    },

    // dans les slots
    {
        path: '/:slug/slots/:aslot/survey',
        name: 'survey',
        component: Survey,
        props: true,
        meta: {
            title: 'dosurvey.title',
            back: 'salepointslot',
            menu: false
        }
    },
    {
        path: '/:slug/slots/:aslot/surveyfinish',
        name: 'surveyfinish',
        component: SurveyFinish,
        props: true,
        meta: {
            title: 'survey.title',
            back: 'salepointslot',
            menu: false
        }
    }
]
