import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        disabled: false,
        url: '',
        audioContext: null,
        audioChoice: false,
        audio: false,
        audioLoopDelay: 1,
        audios: {
            call: '528866__eponn__beep-6.mp3'
        },
        audioBuffers: {}
    },

    getters: {
        disabled: state => state.disabled,
        url: state => `${state.url}/${state.selected}`,
        audio: state => state.audio,
        audioChoice: state => state.audioChoice
    },

    mutations: {

        disabled(state, data) {
            state.disabled = data.value
        },

        url(state, data) {
            state.url = data.url
        },

        audio(state, data) {
            this.commit('dbg/log', { message: `set audio to ${data.value}` })
            state.audio = data.value
            state.audioChoice = true
            if (!state.audioContext) {
                this.commit('dbg/log', { message: 'initialize audio context' })
                state.audioContext = new (window.AudioContext || window.webkitAudioContext)()
                // on play le sound directement à la création, parce que
                // sinon, iOS Safari ne va jamais le jouer ensuite
                this.commit('audio/alert', { forcePlay: true })
            }
        },

        alert(state, { forcePlay, loop = 1 } = {}) {
            if (state.disabled) {
                return
            }
            const audioContext = state.audioContext
            this.commit('dbg/log', { message: 'start alert' })
            if (!state.audio && !forcePlay) {
                this.commit('dbg/log', { message: 'skip alert' })
                return
            }
            const type = 'call'

            if (!state.audioBuffers[type]) {
                // les buffers n'ont pas encore eu le temps de charger
                return
            }

            // pour le replay: @see https://stackoverflow.com/a/58290827/2030095
            for (let i = 0; i < loop; i += 1) {
                const bufferSource = audioContext.createBufferSource()
                bufferSource.buffer = state.audioBuffers[type]
                bufferSource.connect(audioContext.destination)
                bufferSource.start(audioContext.currentTime + ((bufferSource.buffer.duration + state.audioLoopDelay) * i))
            }

            this.commit('dbg/log', { message: `sound played (${forcePlay ? 'force' : 'normal'} mode)` })
        }
    },

    actions: {
        async load(context) {
            if (Object.values(context.state.audioBuffers).length) {
                return context.state.audioBuffers
            }
            const audioContext = new (window.AudioContext || window.webkitAudioContext)()
            const promises = Object.entries(context.state.audios)
                .map(e => Vue.prototype.$http
                    .get(`${context.state.url}/${e[1]}`, { responseType: 'arraybuffer' })
                    .then(res => new Promise((resolve, reject) => {
                        // parce que sous Safari desktop, cette méthode ne retourne pas
                        // de promesse mais [void]...
                        audioContext.decodeAudioData(res.data, resolve, reject)
                    }))
                    .then(buffer => context.state.audioBuffers[e[0]] = buffer)
                )

            return Promise.all(promises)
        }
    }
}
