<template>
    <acs-base-container center>
        <acs-survey-finish @close="close" />
    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsSurveyFinish from '@/components/survey/AcsSurveyFinish'

export default {
    name: 'survey-finish',
    props: {
        slug: { type: String, required: true },
        aslot: { type: String, required: false }
    },
    components: {
        AcsBaseContainer,
        AcsSurveyFinish
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            await this.$store.dispatch('sp/current', { slug: this.slug })
            if (this.aslot) {
                await this.$store.dispatch('sp/slot', { slug: this.slug, slot: this.aslot })
            }
        },

        close() {
            if (!this.aslot) {
                this.$router.push({ name: 'salepointhome', params: { slug: this.slug } })
            } else {
                this.$router.push({ name: 'salepointslot', params: { slug: this.slug, slot: this.aslot } })
            }
        }
    }
}
</script>
