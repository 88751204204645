import i18next from 'i18next'
import moment from 'moment'
import Vue from 'vue'
import { parse } from 'accept-language-parser'

export default {
    namespaced: true,

    state: {
        current: null,
        currentWithRegion: null,
        defaultLn: null,
        available: []
    },

    getters: {
        current: state => state.current,
        currentWithRegion: state => state.currentWithRegion,
        available: state => state.available,
        defaultLn: state => state.defaultLn,
        query: state => `&acc-language=${state.current}`,
        calendar: state => ({
            locale: state.current,
            firstDayOfWeek: moment.localeData(state.current).firstDayOfWeek()
        })
    },

    mutations: {
        current(state, data) {
            data.current = data.current.toLowerCase()
            // Ici, on chope la langue de base (p.ex "fr")
            // Mais on a besoin de la région pour tout ce qui
            // concernen les nombres (p.ex. "ch"). On va faire
            // une devinette auto en tenant compte du browser
            const navln = parse(navigator.userLanguage || navigator.language) || []
            const region = navln.find(l => l.code === data.current)
            if (region && region.region) {
                state.currentWithRegion = `${data.current}-${region.region.toLowerCase()}`
            } else {
                state.currentWithRegion = data.current
            }

            // set la valeur pour le html global
            document.documentElement.setAttribute('lang', state.currentWithRegion)

            state.current = data.current
            moment.locale(data.current)
            if (Vue.prototype.$vuetify) {
                Vue.prototype.$vuetify.lang.current = data.current
            }
            Vue.prototype.$storage.set('acc-ln', data.current)
            this.commit('pub/setLang', { lang: data.current })
        },
        available(state, data) {
            state.available = data.available
        },
        defaultLn(state, data) {
            state.defaultLn = data.defaultLn
        }
    },

    actions: {
        async current(context, data) {
            const ln = data.current.toLowerCase()
            await i18next.changeLanguage(ln)
            context.commit('current', data)
        }
    }
}
