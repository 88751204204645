<template>
    <v-card :max-width="maxWidth" flat>
        <v-card-text class="acs--survey-message py-0">
            <img src="/img/survey-finish.svg" />
        </v-card-text>
        <v-card-title class="acs--survey-header py-0">
            {{ $t('dosurvey.finish.title') }}
        </v-card-title>
        <v-card-text class="acs--survey-message py-0">
            {{ $t('dosurvey.finish.message') }}
        </v-card-text>
        <v-card-actions>
            <v-row>
                <v-col cols="12">
                    <v-btn rounded color="primary" width="100%" @click="close">{{ $t('dosurvey.finish.btn') }}</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'acs-survey-finish',
    props: {
        maxWidth: { type: [String, Number] }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="sass" scoped>
.acs--survey-header
    font-weight: bold
    font-size: 36px
    line-height: 1.2em
    margin-bottom: .1em

.acs--survey-message
    font-weight: normal
    font-size: 14px

.acs--survey-check
    font-weight: normal
    font-size: 14px
    text-align: left

.v-card__text, .v-card__title
    font-family: "Red Hat Display", "Roboto", sans-serif
    color: #000!important
    word-break: normal!important

</style>
