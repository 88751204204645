<template>
    <v-badge
        v-show="show"
        color="primary"
        overlap
        :value="products"
        :content="products"
        offset-x="20"
        offset-y="20">
        <v-btn icon exact :to="{ name: getContextRouteName('cart') }">
            <v-icon>$vuetify.icons.ecomCart</v-icon>
        </v-btn>
    </v-badge>
</template>

<script>
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-cart-btn',
    props: {
        slug: { type: String }
    },
    mixins: [RouteMixin],
    data: () => ({
        loading: false
    }),
    computed: {
        products() {
            return this.$store.getters['cart/quantities']
        },
        show() {
            return this.$store.getters['ser/orderableServices'].length > 0
        }
    },
    mounted() {
        this.loading = true
        return Promise
            .all([
                this.$store.dispatch('cart/get', { slug: this.slug }),
                this.$store.dispatch('ser/services', { slug: this.slug })
            ])
            .catch(err => this.$err(err))
            .finally(() => (this.loading = false))
    }
}
</script>
