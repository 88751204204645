import Account from '@/views/Account.vue'

const backTable = ({ store }) => {
    if (store.getters['sp/slot'].id) {
        return { name: 'salepointslot', params: { aslot: store.getters['sp/slot'].id } }
    }
    if (store.getters['sp/current'].id) {
        return { name: 'salepointhome', params: { slug: store.getters['sp/current'].slug } }
    }
    return { name: 'salepointhome' }
}

export default [
    {
        path: '/:slug/slots/:aslot/account',
        name: 'account',
        component: Account,
        props: true,
        meta: {
            title: 'user.account',
            back: backTable
        }
    }
]
