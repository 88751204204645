<template>
    <v-dialog v-model="show">
        <v-card flat>
            <img class="acs-call--center" :src="`/img/image-${mainType}.svg`" />
            <v-card-title class="acs-call--title">
                {{ $t(`salepointtable.${mainType}.title`) }}
            </v-card-title>
            <v-card-text>
                {{ $t(`salepointtable.${mainType}.text`) }}
            </v-card-text>
            <v-card-actions class="px-4">
                <v-spacer />
                <v-btn large
                    class="acs-call--btn"
                    color="primary"
                    text
                    @click="show = false">
                    {{ $t(`salepointtable.${mainType}.validate`) }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'acs-slot-call',
    props: {
        value: { type: Boolean },
        type: { type: String }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        mainType() {
            return this.type.indexOf('bill') !== -1 ? 'bill' : this.type
        }
    }
}
</script>

<style lang="sass">
.acs-call--center
    display: block
    margin: 0 auto
    padding: 40px 0 20px 0

.acs-call--title
    font-size: 1rem !important

.acs-call--btn
    font-weight: 800
    letter-spacing: 0.9px
</style>
