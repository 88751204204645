import SalepointHome from '@/views/SalepointHome'
import AcsSalepointWelcome from '@/components/salepoint/AcsSalepointWelcome'
import Pay from '@/views/Pay'
import PaySuccess from '@/views/PaySuccess'
import PayFinish from '@/views/PayFinish'
import SalepointInfo from '@/views/SalepointInfo'
import PayTerminal from '@/views/PayTerminal.vue'

export default [
    // route depuis l'accueil d'un salepoint
    {
        path: '/:slug',
        name: 'salepointhome',
        component: SalepointHome,
        props: true,
        meta: {
            title: 'salepointhome.title',
            titleCmp: AcsSalepointWelcome,
            ext: true
        }
    },
    {
        path: '/:slug/pay',
        name: 'payhome',
        component: Pay,
        props: true,
        meta: {
            title: 'pay.menu',
            back: 'salepointhome'
        }
    },
    {
        path: '/:slug/paysuccess',
        name: 'paysuccesshome',
        component: PaySuccess,
        props: true,
        meta: {
            ignoreCount: true,
            title: 'paysuccess.menu'
        }
    },
    {
        path: '/:slug/payfinish',
        name: 'payfinishhome',
        component: PayFinish,
        props: true,
        meta: {
            ignoreCount: true,
            title: 'paysuccess.surveybtn',
            back: 'paysuccesshome'
        }
    },
    {
        path: '/:slug/pay/terminal/:code',
        name: 'payterminal',
        component: PayTerminal,
        props: true,
        meta: {
            title: null
        }
    },
    {
        path: '/:slug/info',
        name: 'salepointinfo',
        component: SalepointInfo,
        props: true,
        meta: {
            title: '',
            topbar: { absolute: true, dark: true, colour: 'transparent' }
        }
    }
]
