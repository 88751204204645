<template>
    <v-text-field
        ref="field"
        :label="label + (required ? ' *' : '')"
        v-model="vname"
        :name="name"
        :disabled="disabled"
        :rules="rules"
        :autofocus="autofocus"
        outlined />
</template>

<script>
export default {
    name: 'acs-user-email-field',
    props: {
        value: { type: String },
        label: { type: String },
        name: { type: String },
        required: { type: Boolean },
        disabled: { type: String },
        autofocus: { type: Boolean }
    },
    computed: {
        vname: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        rules() {
            const rules = []
            if (this.required) {
                rules.push(this.$required)
            }
            return rules
        }
    },
    methods: {
        focus() {
            this.$refs.field.focus()
        }
    }
}
</script>
