<template>
    <v-card class="mx-auto" max-width="424" flat color="transparent">
        <v-card :class="{ 'mx-2': $vuetify.breakpoint.mdAndUp, 'mx-5': $vuetify.breakpoint.smAndDown }" class="mb-5" light>
            <v-card-title class="py-2">
                <div>
                    {{ $tu('salepointhome.welcome', { name: salepoint.name }) }}
                </div>
                <v-btn small :to="{ name: 'salepointinfo' }" :color="salepoint.colour" class="ml-5" icon>
                    <v-icon>$vuetify.icons.baseInfo</v-icon>
                </v-btn>
                <v-spacer />
                <div class="text-subtitle-2 grey--text" v-if="slot">
                    {{ slot.name }}
                </div>
            </v-card-title>

            <v-card-text
                ref="content"
                class="pb-1 acs-salepoint-message--container"
                :class="{ 'collapsed': !showAll }"
                v-show="message && !loading" v-marked="message" />

            <v-btn
                v-if="!loading && (showAll || isClamped)"
                block
                plain
                small
                color="primary"
                @click="showAll = !showAll">
                <v-icon v-show="!showAll" small>$vuetify.icons.baseDown</v-icon>
                <v-icon v-show="showAll" small>$vuetify.icons.baseUp</v-icon>
            </v-btn>

            <v-card-actions v-if="loading">
                <v-spacer />
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                <v-spacer />
            </v-card-actions>
        </v-card>
    </v-card>
</template>

<script>
export default {
    name: 'acs-salepoint-welcome',
    data: () => ({
        loading: false,
        message: null,
        showAll: false,
        isClamped: false
    }),
    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        },
        slot() {
            return this.$store.getters['sp/slot']
        },
        lang() {
            return this.$store.getters['ln/current']
        }
    },
    watch: {
        lang: 'setMessage',
        'salepoint.id': 'setMessage'
    },
    mounted() {
        this.setMessage()
    },
    methods: {
        setMessage() {
            if (!this.salepoint.welcome_message) {
                return
            }
            this.loading = true
            return this.$store.dispatch('sp/translateWelcomeMessage', { slug: this.salepoint.slug })
                .then(msg => {
                    this.message = msg || this.salepoint.welcome_message
                    this.loading = false
                    return this.initClampStatus()
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        async initClampStatus() {
            this.showAll = false
            await this.$nextTick()
            if (!this.$refs.content) {
                return
            }
            this.isClamped = this.$refs.content.scrollHeight > this.$refs.content.clientHeight
        }
    }
}
</script>

<style lang="sass">
.acs-salepoint-message--container

    &.collapsed
        overflow: hidden
        white-space: initial
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical
        display: -webkit-box

</style>
