<template>
    <acs-base-container center>
        <v-card flat class="text-left">
            <v-card-title>
                <div>{{ $t('paysuccess.finish') }}</div>
            </v-card-title>
            <v-card-text>
                {{ $t('paysuccess.finishtext') }}
            </v-card-text>
            <v-list class="acs-list-nowrap">
                <v-list-item v-for="(item, i) in items" :key="i">
                    <v-list-item-icon>
                        <v-icon large color="primary">$vuetify.icons.paySurveyPoint</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                        <v-list-item-subtitle>{{ $t(item.subtitle) }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-card-actions class="mt-5">
                <v-btn rounded block color="primary" :to="{ name: getContextRouteName('survey') }">{{ $t('paysuccess.surveybtn') }}</v-btn>
            </v-card-actions>
            <v-card-actions>
                <v-btn rounded block text exact color="primary" :to="{ name: getContextRouteName('salepoint') }">{{ $t('paysuccess.laterbtn') }}</v-btn>
            </v-card-actions>
        </v-card>
    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'pay-success',
    components: { AcsBaseContainer },
    mixins: [RouteMixin],
    props: {
        slug: { type: String },
        aslot: { type: String }
    },
    data: () => ({
        items: [
            { title: 'paysuccess.surveyarg1.title', subtitle: 'paysuccess.surveyarg1.subtitle' },
            { title: 'paysuccess.surveyarg2.title', subtitle: 'paysuccess.surveyarg2.subtitle' }
        ]
    })
}
</script>

<style lang="sass">
.acs-list-nowrap
    .v-list-item__subtitle
        white-space: normal
</style>
