import Vue from 'vue'
import i18next from 'i18next'
import HttpApi from 'i18next-http-backend'
import VueI18Next from '@panter/vue-i18next'
import lodash from 'lodash'
import moment from 'moment'
import store from '../../store'

const escapeFn = str => {
    const _entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;'
    }
    return (str || '').replace(/[<"/]/g, s => _entityMap[s])
}

const I18nPlugin = {
    install(vue, options) {
        vue.mixin({
            computed: {
                $tr() {
                    return (data, ln) => {
                        ln = ln || store.getters['ln/current']
                        const content = lodash.trim(data[ln]) ? data[ln] : data[options.default]
                        return content
                    }
                },

                $tu() {
                    // unescape partiel de i18next
                    return (key, data) => {
                        return i18next.t(key, {
                            ...data,
                            interpolation: {
                                escape: escapeFn
                            }
                        })
                    }
                }
            }
        })
    }
}

Vue.use(VueI18Next)

const resources = {}

export default {

    resources: resources,
    ln: Vue.prototype.$storage.get('acc-ln'),
    lnDefault: 'fr',

    init(config) {
        const ln = lang => lang
        const userLn = Vue.prototype.$storage.get('acc-ln') || config.i18n.user
        return i18next
            .use(HttpApi)
            .on('languageChanged', lng => {
                // utilisé uniquement pour le service worker
                resources[lng] = i18next.getDataByLanguage(lng)
            })
            .init({
                returnObjects: true,
                lng: ln(userLn),
                fallbackLng: ln(config.i18n.default),
                preload: [ln(userLn)],
                available: config.i18n.available.map(ln),
                interpolation: {
                    skipOnVariables: false,
                    format(value, format, lng) {
                        if (format === 'ul') {
                            if (!value || !value.length) {
                                return ''
                            }
                            return `+ ${(Array.isArray(value) ? value : [value]).join('\n+ ')}`
                        }
                        if (format) {
                            return moment(value).format(format)
                        }
                        return value
                    }
                },
                ns: ['shared-front', config.i18nNamespace],
                defaultNS: config.i18nNamespace,
                backend: {
                    loadPath: `${Vue.prototype.$config.api}/i18n/{{lng}}/{{ns}}`
                }
            })
            .then(() => {
                store.commit('ln/current', { current: ln(userLn) })
                store.commit('ln/available', { available: config.i18n.available.map(ln) })
                store.commit('ln/defaultLn', { defaultLn: config.i18n.default })
                Vue.use(I18nPlugin, { default: ln(config.i18n.default) })
                i18next.tu = (key, opt) => {
                    return i18next.t(key, {
                        ...opt,
                        interpolation: {
                            escape: escapeFn
                        }
                    })
                }
                return new VueI18Next(i18next)
            })
    }
}
