<template>
    <v-app-bar
        v-if="title || title === ''"
        app
        fixed
        flat
        :color="colour"
        class="acs-top-bar"
        :extension-height="emptyExtension ? 0 : 32"
        :light="!dark"
        :dark="dark"
        :absolute="absolute">
        <v-btn icon v-if="back" :to="getTo(true)" @click="backFn" class="acs-back-btn">
            <v-icon>$vuetify.icons.baseBack</v-icon>
        </v-btn>

        <v-spacer v-if="!titleEmphasis" />
        <acs-cart-reset-btn v-if="isOnlyTerminal" :slug="salepoint.slug" />
        <v-toolbar-title :class="{ 'text-h5': titleEmphasis }">{{ $tu(title, { ln: ln, ...titleParams }) }}</v-toolbar-title>
        <v-spacer />

        <acs-cart-btn v-if="$route.params.slug" :slug="$route.params.slug" />

        <v-btn v-if="menu" icon @click="$emit('update:right', !right)">
            <v-icon large>$vuetify.icons.baseMenu</v-icon>
        </v-btn>

        <template v-if="$route.meta.titleCmp" #extension>
            <component :is="$route.meta.titleCmp" v-bind="$route.params" />
        </template>
    </v-app-bar>
</template>

<script>
import AcsCartBtn from '@/components/cart/AcsCartBtn'
import AcsCartResetBtn from '@/components/cart/AcsCartResetBtn'

export default {
    name: 'acs-base-top-bar',
    components: { AcsCartBtn, AcsCartResetBtn },
    props: {
        right: { type: Boolean }
    },
    data: () => ({
        title: '',
        back: null,
        backFn: () => null,
        menu: false,
        colour: 'white',
        absolute: false,
        dark: false,
        titleEmphasis: false
    }),
    computed: {
        btitle() {
            return this.$store.getters['top/title']
        },
        bback() {
            return this.$store.getters['top/back']
        },
        ln() {
            return this.$store.getters['ln/current']
        },
        titleParams() {
            return this.$store.getters['top/params']
        },
        reload() {
            return this.$store.getters['top/reload']
        },
        emptyExtension() {
            if (this.$route.meta.titleCmp === 'acs-service-timeframe-selected') {
                return !this.$store.getters['cart/cart']?.timeframes?.length
            }
            return false
        },
        salepoint() {
            return this.$store.getters['sp/current']
        },
        isOnlyTerminal() {
            if (!this.salepoint?.id) {
                return false
            }
            return this.$store.getters['auth/isOnly'](this.salepoint.id, 'terminal')
        }
    },
    watch: {
        $route(to) {
            if (this.$route.hash) {
                return
            }
            this.applyMeta(to.meta || {})
        },
        btitle(title) {
            this.title = title
        },
        bback(back) {
            this.back = back
        },
        reload() {
            this.applyCurrent()
        }
    },
    created() {
        this.applyCurrent()
    },
    methods: {
        applyCurrent() {
            this.applyMeta(this.$router.currentRoute.meta || {})
        },

        applyMeta(meta) {
            this.$store.commit('top/title', {
                title: this.$i18n.t(meta.title, { ln: this.ln, ...this.titleParams }),
                params: Object.entries(meta.titleParams || {}).reduce((acc, v) => {
                    acc[v[0]] = this.$store.getters[v[1]]
                    return acc
                }, {}),
                ext: meta.ext
            })
            this.colour = meta.topbar?.colour || 'white'
            this.absolute = meta.topbar?.absolute || false
            this.dark = meta.topbar?.dark || false
            this.title = meta.title
            this.titleEmphasis = meta.titleEmphasis
            this.back = meta.back
            this.backFn = () => null
            this.menu = meta.menu !== false
            if (typeof this.getTo() === 'function') {
                this.backFn = this.getTo()
            }
        },

        getTo(strict) {
            if (typeof this.back === 'string') {
                return { name: this.back }
            }
            if (typeof this.back === 'function') {
                const b = this.back({ store: this.$store, router: this.$router, route: this.$route, cmp: this })
                if (strict && typeof b === 'function') {
                    return null
                }
                return b
            }
        }
    }
}
</script>

<style lang="sass">
.v-toolbar__title, .v-toolbar .v-toolbar__title
    font-size: 16px

.acs-back-btn.v-btn--active::before
    opacity: 0 !important

.v-toolbar.acs-scrollable
    display: none

.acs-top-bar .v-toolbar__extension
    justify-content: center

</style>
