<template>
    <acs-base-container large size="large">
        <acs-pay-payment-done v-if="$route.query.payment === 'done'" />

        <acs-product-category-list :slug="slug" @change:lang="onLangChanged" />

        <template v-if="!isOnlyTerminal">
            <template v-if="categories.length && menus.length">
                <v-divider />
                <v-subheader>{{ $t('menulist.downloadAndLinks') }}</v-subheader>
            </template>

            <acs-menu-list
                max-width="400"
                class="acs-home--card"
                :translate="translate"
                :show-empty="!categories.length" />
        </template>
    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsMenuList from '@/components/menu/AcsMenuList'
import AcsProductCategoryList from '@/components/product-category/AcsProductCategoryList'
import AcsPayPaymentDone from '@/components/pay/AcsPayPaymentDone'

export default {
    name: 'menu-list',
    props: {
        slug: { type: String, required: true },
        aslot: { type: String, required: false },
        back: { type: String }
    },
    components: {
        AcsBaseContainer,
        AcsMenuList,
        AcsProductCategoryList,
        AcsPayPaymentDone
    },
    data: () => ({
        translate: false
    }),
    computed: {
        categories() {
            return this.$store.getters['prod/categories']
        },
        menus() {
            return this.$store.getters['sp/menus']
        },
        lang() {
            return this.$store.getters['lang/current']
        },

        isOnlyTerminal() {
            const salepoint = this.$store.getters['sp/current']
            return this.$store.getters['auth/isOnly'](salepoint.id, 'terminal')
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            return Promise
                .all([
                    this.$store.dispatch('sp/current', { slug: this.slug }),
                    this.$store.dispatch('sp/menus', { slug: this.slug, lang: this.lang }),
                    this.aslot && this.$store.dispatch('sp/slot', { slug: this.slug, slot: this.aslot })
                ])
                .then(() => {
                    this.$store.commit('top/reload')
                    if (this.back) {
                        this.$store.commit('top/back', { back: this.back })
                    }
                })
                .catch(err => this.$err(err))
        },

        onLangChanged({ lang, translate }) {
            this.translate = translate
            return this.$store.dispatch('sp/menus', { slug: this.slug, lang })
                .catch(err => this.$err(err))
        }
    }
}
</script>
