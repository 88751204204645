<template>
    <div class="acs-menu--item" :class="{ 'mb-2': !social }">
        <v-list-item
            :class="{ white: !social, 'py-5': !social }"
            :style="{ borderColor: `${primaryColor} !important` }"
            @click="show = !menu.url && !isPdf"
            target="_blank"
            :href="href">
            <v-list-item-content v-if="!social">
                <v-list-item-title class="primary--text">{{ menu | quickTranslate('title', translate) }}</v-list-item-title>
            </v-list-item-content>
            <template v-else>
                <v-list-item-icon>
                    <v-icon v-if="hasIcon" :color="color || 'primary'">{{ `$vuetify.icons.social${menu.type}` }}</v-icon>
                    <v-img v-else :src="`/img/vicons/${menu.type}.png`" width="24" height="24" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title :class="`${color || 'primary'}--text`">{{ $t(`salepointsocial.types.${menu.type}`) }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item-action>
                <v-icon :color="menu.type !== 'faq' ? 'primary' : null">$vuetify.icons.baseRight</v-icon>
            </v-list-item-action>
            <acs-menu-view v-if="!menu.url && !isPdf" :menu="menu" v-model="show" />
        </v-list-item>
    </div>
</template>

<script>
import AcsMenuView from '@/components/menu/AcsMenuView'

export default {
    name: 'acs-menu-list-item',
    components: { AcsMenuView },
    props: {
        menu: { type: Object },
        social: { type: Boolean },
        color: { type: String },
        translate: { type: Boolean }
    },
    computed: {
        isPdf() {
            if (!this.menu.links_file) {
                return false
            }
            const type = this.menu.links_file.substr(this.menu.links_file.lastIndexOf('.') + 1).toLowerCase()
            return type === 'pdf'
        },

        primaryColor() {
            const mode = this.$vuetify.theme.isDark ? 'dark' : 'light'
            return this.$vuetify.theme?.themes[mode]?.primary
        },

        href() {
            if (this.menu.url) {
                return this.menu.url
            }
            if (this.isPdf) {
                return this.menu.links_file
            }
            return null
        },

        hasIcon() {
            return !['tiktok'].includes(this.menu.type)
        }
    },
    data: () => ({
        show: false
    })
}
</script>

<style lang="sass">
.acs-menu--item
    border-radius: 4px

    .white
        border-style: solid
        border-width: 1px

    .v-list-item__title
        text-transform: uppercase
        font-weight: 800
        font-size: 14px
        line-height: 16px

</style>
