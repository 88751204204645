import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'

let webpushToken = Vue.prototype.$sessionStorage.get('webpush_token')
if (!webpushToken) {
    webpushToken = uuidv4()
    Vue.prototype.$sessionStorage.set('webpush_token', webpushToken)
}

export default {
    namespaced: true,

    state: {
        show: false,
        token: webpushToken
    },

    getters: {
        show: state => state.show,
        token: state => state.token
    },

    mutations: {
        show(state, { show } = {}) {
            state.show = show
        }
    },

    actions: {
        async getConfig(context) {
            const res = await Vue.prototype.$api.get('/webpush')
            return res.data
        },

        async subscribe(context, { token, subscription, slug, filters }) {
            const res = await Vue.prototype.$api.post('/webpush', { token, subscription, salepoint_slug: slug, filters })
            return res.data
        },

        async unsubscribe(context, { token, subscription, slug, filters }) {
            await Vue.prototype.$api.post('/webpush/unsubscribe', { token, subscription, salepoint_slug: slug, filters })
        }
    }
}
