<template>
    <v-menu
        v-model="show"
        rounded
        :close-on-content-click="false">
        <template #activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                class="acs--btn-text pr-1 mb-2"
                :class="{ 'acs-standard-highlight': highlight }"
                outlined
                rounded
                block
                :loading="loading"
                :disabled="disabled">
                <v-badge color="primary" left offset-x="-4" offset-y="16" :content="filtersCount" :value="filtersCount">
                    {{ $t('productfilter.button') }}
                </v-badge>
                <v-icon right small>
                    {{ show ? '$vuetify.icons.baseUp' : '$vuetify.icons.baseDown' }}
                </v-icon>
            </v-btn>
        </template>
        <v-card>
            <acs-base-card-title :title="$t('productfilter.button')" @close="close" />
            <v-subheader>{{ $t('product.orderModes') }}</v-subheader>
            <acs-service-selector
                v-model="service"
                dense
                :translate="translate"
                :disabled="disabled"
                :slug="slug" />

            <v-divider />
            <v-subheader>{{ $t('productfilter.tags') }}</v-subheader>
            <acs-product-tags-selector
                ref="tagSelector"
                v-model="tags"
                :translate="translate"
                :slug="slug"
                :disabled="disabled" />

            <v-divider />
            <v-card-actions>
                <v-btn
                    text
                    :disabled="disabled"
                    @click="reset"
                    class="acs--btn-text pl-0">
                    <v-icon left small color="primary">$vuetify.icons.ecomUndo</v-icon>
                    {{ $t('productfilter.removeFilters') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'
import AcsServiceSelector from '@/components/service/AcsServiceSelector'
import AcsProductTagsSelector from '@/components/product-list/AcsProductTagsSelector'
import lodash from 'lodash'

export default {
    name: 'acs-product-filter',
    components: { AcsBaseCardTitle, AcsServiceSelector, AcsProductTagsSelector },
    props: {
        slug: { type: String },
        translate: { type: Boolean },
        disabled: { type: Boolean }
    },
    data: () => ({
        show: false,
        loading: false,
        tags: [],
        service: null,
        initialization: true,
        filtersChanged: null,
        serviceChanged: false
    }),
    computed: {
        filters() {
            return this.$store.getters['prod/filters'](this.slug)
        },
        filtersCount() {
            let count = 0
            if (!this.filters) {
                return count
            }
            count += this.filters.tags?.length || 0
            if (this.filters.service) {
                count += 1
            }
            return count
        },
        highlight() {
            return this.$store.getters['prod/firstLoad']
        },

        timeframes() {
            const timeframes = this.$store.getters['cart/cart']?.timeframes
            return timeframes?.length ? timeframes : null
        }
    },
    watch: {
        async service(v, o) {
            // on doit gérer le changement de l'id de service entre
            // 1. on a rechargé la page et on reload le cart
            // 2. on a switché d'un filtre "aucun service" vers "un service"
            this.serviceChanged = !!(v && (o || !this.initialization))

            if (this.timeframes && this.serviceChanged) {
                // on reset les timeframes du panier en cours
                await this.$store.dispatch('cart/update', { slug: this.slug, timeframes: [] })
            }

            this.filtersChanged()
        },
        tags() {
            this.filtersChanged()
        },
        timeframes() {
            this.filtersChanged()
        }
    },
    created() {
        this.filtersChanged = lodash.debounce(() => {
            this.changed()
        }, 800)
    },
    mounted() {
        // si une query existe, on l'utilise pour setter les filtres
        if (this.$route.query.s || this.$route.query.tag) {
            this.service = this.$route.query.s || null
            this.tags = this.$route.query.tag?.split(',') || []
            return this.changed()
        }
        // si les filtres sont pour un autre salepoint, on les efface
        if (this.filters && this.filters.slug !== this.slug) {
            this.reset()
        } else {
            this.service = this.filters?.service
            this.tags = this.filters?.tags || []
        }
    },
    beforeDestroy() {
        this.filtersChanged = null
    },
    methods: {
        changed() {
            this.$store.dispatch('prod/saveFilters', {
                slug: this.slug,
                service: this.service,
                tags: this.tags,
                timeframes: this.timeframes
            })
            this.$emit('change', {
                active: this.service || this.tags.length > 0,
                service: this.service,
                tags: this.tags,
                timeframes: this.timeframes
            })

            const showTimeframesPopup = this.serviceChanged
            this.serviceChanged = false

            if (showTimeframesPopup) {
                const service = this.$store.getters['ser/services'].find(s => s.id === this.service)
                if (!service?.timeframe) {
                    return
                }
                // on affiche les créneaux si nécessaire
                this.$store.commit('ser/timeframesPopup', { show: true, serviceId: service.id })
            }

            this.$nextTick()
            this.initialization = false
        },

        close() {
            this.show = false
        },

        cancel() {
            this.filtersChanged.cancel()
        },

        reset() {
            this.service = null
            this.tags = []
            if (this.$refs.tagSelector) {
                this.$refs.tagSelector.collapse()
            }
        }
    }
}
</script>
