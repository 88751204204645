<template>
    <div v-if="firstTimeframe" class="d-flex justify-center">
        <v-btn small rounded color="primary" @click="showPopup">
            <v-badge
                inline
                color="primary"
                :value="timeframes.length > 1"
                :content="`+ ${timeframes.length - 1}`">
                {{ firstTimeframeFormatted }}
            </v-badge>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'acs-service-timeframe-selected',

    computed: {
        timeframes() {
            return this.$store.getters['cart/cart']?.timeframes || []
        },

        serviceId() {
            return this.$store.getters['cart/cart']?.service_id
        },

        firstTimeframe() {
            return this.timeframes.length && this.timeframes[0]
        },

        firstTimeframeFormatted() {
            const formatter = new Intl.DateTimeFormat(this.$store.getters['ln/currentWithRegion'], {
                dateStyle: 'medium',
                timeStyle: this.timeframe?.between_frame_duration ? 'short' : undefined
            })
            return formatter.format(new Date(this.firstTimeframe))
        },

        timeframe() {
            const services = this.$store.getters['ser/services']
            const service = services.find(s => s.id === this.serviceId)
            return service?.timeframe
        }
    },

    methods: {
        showPopup() {
            this.$store.commit('ser/timeframesPopup', { show: true })
        }
    }
}
</script>
