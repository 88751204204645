<template>
    <span ref="svg" class="acs-svg"></span>
</template>

<script>
export default {
    name: 'acs-base-svg',
    props: {
        src: { type: String }
    },
    computed: {
        salepointColour() {
            return this.$store.getters['sp/current'].colour
        }
    },
    watch: {
        src: 'load'
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            const res = await this.$http.get(this.src)
            const reg = /fill="#[^".]+"/g
            this.$refs.svg.innerHTML = res.data.replace(reg, `fill="${this.salepointColour}"`)
        }
    }
}
</script>
