<template>
    <v-alert v-model="show" dismissible text color="primary">
        <v-card-title class="flex-nowrap">
            <v-img max-width="80" src="/img/pay-success.svg" />
            <div class="text-left pl-4">
                <div class="text-h6">{{ $t('cartdone.success') }}</div>
                <div class="text-subtitle-1">{{ $t('cartdone.successHelp') }}</div>
            </div>
        </v-card-title>
    </v-alert>
</template>

<script>
export default {
    name: 'asc-pay-payment-done',

    data: () => ({
        show: false,
        timeout: null
    }),

    beforeDestroy() {
        window.clearTimeout(this.timeout)
    },

    mounted() {
        this.show = true
        // this.timeout = window.setTimeout(() => (this.show = false), 15 * 1000)
    }
}
</script>
