const ProductCategoryList = () => import(/* webpackChunkName: "ecommerce" */ '@/views/ProductCategoryList.vue')
const ProductList = () => import(/* webpackChunkName: "ecommerce" */ '@/views/ProductList.vue')
const ProductForm = () => import(/* webpackChunkName: "ecommerce" */ '@/views/ProductForm.vue')
const ServiceAdvancedForm = () => import(/* webpackChunkName: "ecommerce" */ '@/views/ServiceAdvancedForm.vue')

const Cart = () => import(/* webpackChunkName: "ecommerce" */ '@/views/Cart.vue')
const CartPay = () => import(/* webpackChunkName: "ecommerce" */ '@/views/CartPay.vue')
const CartUpselling = () => import(/* webpackChunkName: "ecommerce" */ '@/views/CartUpselling.vue')
const CartSuccess = () => import(/* webpackChunkName: "ecommerce" */ '@/views/CartSuccess.vue')
const CartCancel = () => import(/* webpackChunkName: "ecommerce" */ '@/views/CartCancel.vue')

const backCategory = home => ({ store, route, router }) => () => {
    const parent = store.getters['prod/findParent'](route.params.category)
    if (!parent || !parent.name) {
        return router.push({ name: home ? 'menulisthome' : 'menulist' })
    }
    return router.push({ name: home ? 'productcategoryhome' : 'productcategory', params: { category: parent.id } })
}

const backCart = (from, home) => ({ store, router }) => () => {
    const serviceId = store.getters['cart/cart']?.service_id
    const service = store.getters['ser/byId'](serviceId)
    const upselling = store.getters['ser/upsellingProducts']

    const suffix = home ? 'home' : ''

    if (from === 'advanced') {
        if (upselling?.length) {
            return router.push({ name: `cartupselling${suffix}` })
        }
        return router.push({ name: `cart${suffix}` })
    }
    if (from === 'upselling') {
        return router.push({ name: `cart${suffix}` })
    }
    if (service?.advanced) {
        return router.push({ name: `cartadvanced${suffix}` })
    }
    if (upselling?.length) {
        return router.push({ name: `cartupselling${suffix}` })
    }
    return router.push({ name: `cart${suffix}` })
}

export default [
    {
        path: '/:slug/ecom/cart',
        name: 'carthome',
        component: Cart,
        props: true,
        meta: {
            title: 'cart.title',
            titleCmp: 'acs-service-timeframe-selected',
            titleEmphasis: false,
            ext: false
        }
    },
    {
        path: '/:slug/ecom/cart/pay',
        name: 'cartpayhome',
        component: CartPay,
        props: true,
        meta: {
            title: 'cart.title',
            back: backCart('cart', true)
        }
    },
    {
        path: '/:slug/ecom/cart/upselling',
        name: 'cartupsellinghome',
        component: CartUpselling,
        props: true,
        meta: {
            title: 'productupselling.title',
            titleCmp: 'acs-service-timeframe-selected',
            back: backCart('upselling', true)
        }
    },
    {
        path: '/:slug/ecom/cart/advanced',
        name: 'cartadvancedhome',
        component: ServiceAdvancedForm,
        props: true,
        meta: {
            title: 'cartadvanced.title',
            titleCmp: 'acs-service-timeframe-selected',
            ext: false,
            back: backCart('advanced', true)
        }
    },
    {
        path: '/:slug/ecom/cart/success',
        name: 'cartsuccesshome',
        component: CartSuccess,
        props: true,
        meta: {
            title: 'cart.title',
            back: 'menulisthome'
        }
    },
    {
        path: '/:slug/ecom/cart/success/:orderId',
        name: 'cartsuccessorderhome',
        component: CartSuccess,
        props: true,
        meta: {
            title: 'cart.title',
            back: 'menulisthome'
        }
    },
    {
        path: '/:slug/ecom/cart/cancel',
        name: 'cartcancelhome',
        component: CartCancel,
        props: true,
        meta: {
            title: 'cart.title',
            back: backCart('cart', true)
        }
    },
    {
        path: '/:slug/ecom/categories/:category',
        name: 'productcategoryhome',
        component: ProductCategoryList,
        props: true,
        meta: {
            title: 'categories.title',
            titleCmp: 'acs-service-timeframe-selected',
            ext: false,
            back: backCategory(true)
        }
    },
    {
        path: '/:slug/ecom/categories/:category/products',
        name: 'productlisthome',
        component: ProductList,
        props: true,
        meta: {
            title: 'productlist.title',
            titleCmp: 'acs-service-timeframe-selected',
            ext: false,
            back: backCategory(true)
        }
    },
    {
        path: '/:slug/ecom/categories/:category/products/:product',
        name: 'productformhome',
        component: ProductForm,
        props: true,
        meta: {
            title: 'product.title',
            back: 'productlisthome',
            titleCmp: 'acs-product-navigation',
            ext: false
        }
    },

    // dans un slot
    {
        path: '/:slug/slots/:aslot/ecom/cart',
        name: 'cart',
        component: Cart,
        props: true,
        meta: {
            title: 'cart.title',
            titleCmp: 'acs-service-timeframe-selected',
            titleEmphasis: false,
            ext: false,
            back: 'menulist'
        }
    },
    {
        path: '/:slug/slots/:aslot/ecom/cart/advanced',
        name: 'cartadvanced',
        component: ServiceAdvancedForm,
        props: true,
        meta: {
            title: 'cartadvanced.title',
            titleCmp: 'acs-service-timeframe-selected',
            ext: false,
            back: backCart('advanced', false)
        }
    },
    {
        path: '/:slug/slots/:aslot/ecom/cart/pay',
        name: 'cartpay',
        component: CartPay,
        props: true,
        meta: {
            title: 'cart.title',
            back: backCart('cart', false)
        }
    },
    {
        path: '/:slug/slots/:aslot/ecom/cart/upselling',
        name: 'cartupselling',
        component: CartUpselling,
        props: true,
        meta: {
            title: 'productupselling.title',
            titleCmp: 'acs-service-timeframe-selected',
            back: backCart('upselling', false)
        }
    },
    {
        path: '/:slug/slots/:aslot/ecom/cart/success/:orderId',
        name: 'cartsuccessorder',
        component: CartSuccess,
        props: true,
        meta: {
            title: 'cart.title',
            back: 'menulist'
        }
    },
    {
        path: '/:slug/slots/:aslot/ecom/cart/success',
        name: 'cartsuccess',
        component: CartSuccess,
        props: true,
        meta: {
            title: 'cart.title',
            back: 'menulist'
        }
    },
    {
        path: '/:slug/slots/:aslot/ecom/cart/cancel',
        name: 'cartcancel',
        component: CartCancel,
        props: true,
        meta: {
            title: 'cart.title',
            back: backCart('cart', false)
        }
    },
    {
        path: '/:slug/slots/:aslot/ecom/categories/:category',
        name: 'productcategory',
        component: ProductCategoryList,
        props: true,
        meta: {
            title: 'categories.title',
            titleCmp: 'acs-service-timeframe-selected',
            ext: false,
            back: backCategory(false)
        }
    },
    {
        path: '/:slug/slots/:aslot/ecom/categories/:category/products',
        name: 'productlist',
        component: ProductList,
        props: true,
        meta: {
            title: 'productlist.title',
            titleCmp: 'acs-service-timeframe-selected',
            ext: false,
            back: backCategory(false)
        }
    },
    {
        path: '/:slug/slots/:aslot/ecom/categories/:category/products/:product',
        name: 'productform',
        component: ProductForm,
        props: true,
        meta: {
            title: 'product.title',
            back: 'productlist',
            titleCmp: 'acs-product-navigation',
            ext: false
        }
    }
]
