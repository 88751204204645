import Vue from 'vue'
import i18next from 'i18next'

const Rules = {
    required: v => !!v || i18next.t('shared-front:rules.required'),
    requiredZero: v => !!v || v === 0 || i18next.t('shared-front:rules.required'),
    ranged: (min, max, forceNumber) => {
        return v => {
            const mincheck = min || min === 0
            const lnKey = `shared-front:rules.${mincheck && max ? 'minmax' : (mincheck ? 'min' : 'max')}`
            const msg = i18next.t(lnKey, { min: min, max: max })
            if (typeof v === 'number' || forceNumber) {
                return (!v && v !== 0) || ((!mincheck || Number(v) >= min) && (!max || Number(v) <= max)) || msg
            }
            return (!v && v !== 0) || ((!min || v.length >= min) && (!max || v.length <= max)) || msg
        }
    },
    emailed: v => {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !v || re.test(v) || i18next.t('shared-front:rules.email')
    },
    emailTeamed: v => {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-_0-9]+)(\.[a-zA-Z]{2,})?))$/
        return !v || re.test(v) || i18next.t('shared-front:rules.email')
    },
    password: (err, min) => {
        return v => !v || !err || i18next.t('shared-front:rules.password', { minChars: min })
    },
    length: length => v => !v || v.length <= length || i18next.t('shared-front:rules.length', { count: length }),
    minLength: length => v => v?.length === undefined || v.length >= length || i18next.t('shared-front:rules.minLength', { count: length }),
    time: (min, max, seconds, allowEqual) => v => {
        if (!v) {
            return true
        }
        if (!/^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(v)) {
            return i18next.t('shared-front:rules.time', { count: seconds ? 2 : 0 })
        }
        const a = Number(v.replace(/:/g, ''))
        const minn = min && Number(min.replace(/:/g, ''))
        const maxn = max && Number(max.replace(/:/g, ''))
        if (min && ((allowEqual && a < minn) || (!allowEqual && a <= minn))) {
            return i18next.t('shared-front:rules.min', { min: min })
        }
        if (max && ((allowEqual && a > maxn) || (!allowEqual && a >= maxn))) {
            return i18next.t('shared-front:rules.max', { max: max })
        }
        return true
    },
    eq: (value, errMsg) => {
        return v => !v || !value || v === value || errMsg
    },
    neq: (value, errMsg) => {
        return v => !v || !value || v !== value || errMsg
    },
    url: v => {
        if (!v) {
            return true
        }
        let url
        try {
            url = new URL(v)
        } catch (_) {
            return i18next.t('shared-front:rules.url')
        }
        return ['http:', 'https:'].indexOf(url.protocol) !== -1 || i18next.t('shared-front:rules.url')
    }
}

const RulesPlugin = {
    install(vue) {
        vue.mixin({
            computed: {
                $required: () => Rules.required,
                $requiredZero: () => Rules.requiredZero,
                $ranged: () => Rules.ranged,
                $emailed: () => Rules.emailed,
                $emailTeamed: () => Rules.emailTeamed,
                $password: () => Rules.password,
                $length: () => Rules.length,
                $minLength: () => Rules.minLength,
                $time: () => Rules.time,
                $eq: () => Rules.eq,
                $neq: () => Rules.neq,
                $url: () => Rules.url
            }
        })
    }
}

Vue.use(RulesPlugin)

export default Rules
