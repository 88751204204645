<template>
    <v-item-group v-model="mode" mandatory>
        <v-row v-if="items.length > 1" class="my-4">
            <v-col v-for="(item, i) in items" :key="item.value">
                <v-item v-slot="{ active, toggle }">
                    <v-card
                        :key="item.value"
                        :dark="mode === i"
                        :color="active ? 'primary' : undefined"
                        class="d-flex flex-column align-center pt-3"
                        @click="toggle">
                        <v-icon x-large>$vuetify.icons.pspCashregister_{{ item.value }}</v-icon>
                        <p class="text-center">{{ item.text }}</p>
                    </v-card>
                </v-item>
            </v-col>
        </v-row>

        <acs-pay-qr
            v-model="showQrTerminal"
            :slug="slug"
            :type="type"
            :data="checkoutAction.actionData && checkoutAction.actionData.data"
        />

        <slot name="paybutton"></slot>
    </v-item-group>
</template>

<script>
import AcsPayQr from '@/components/pay/AcsPayQr'

export default {
    name: 'acs-psp-provider-terminal',

    components: { AcsPayQr },

    props: {
        loadingIndicator: { type: Boolean },
        psp: { type: String },
        pspData: { type: Object },
        slug: { type: String },
        disabled: { type: Boolean },
        type: { type: String }
    },

    data: () => ({
        checkoutAction: {},
        modes: [],
        value: null,
        showQrTerminal: false
    }),

    computed: {
        mode: {
            get() {
                const index = this.items.findIndex(item => item.value === this.value)
                return index > -1 ? index : null
            },
            set(v) {
                const item = this.items[v]
                this.value = item?.value || null
            }
        },

        items() {
            return this.modes.map(mode => ({
                text: this.$i18n.t(`shared-front:psp.providers.terminal.modes.${mode}`),
                value: mode
            }))
        },

        loading: {
            get() {
                return this.loadingIndicator
            },
            set(v) {
                this.$emit('update:loadingIndicator', v)
            }
        }
    },

    methods: {
        onSelect(checkoutData, checkoutAction) {
            this.modes = checkoutData.modes
            if (checkoutData.modes.length === 1) {
                this.value = checkoutData.modes[0]
            }
            this.checkoutAction = checkoutAction
            this.loading = false
        },

        async onConfirmPayment(route) {
            if (this.disabled) {
                return
            }
            const { action, actionData } = this.checkoutAction

            actionData.data.mode = this.value

            if (this.value === 'terminal') {
                this.showQrTerminal = true
                this.loading = false
                return
            }

            this.loading = true

            await this.$store.dispatch(action, actionData)

            window.location.href = route.href
            // on ne stoppe pas le load, puisqu'il y a redirect
        },

        reset() {
            this.checkoutAction = {}
        }
    }
}
</script>
