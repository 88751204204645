import MenuList from '@/views/MenuList.vue'

export default [
    {
        path: '/:slug/menus',
        name: 'menulisthome',
        component: MenuList,
        props: true,
        meta: {
            title: 'menulist.title',
            titleParams: { name: 'sp/name' },
            titleCmp: 'acs-service-timeframe-selected',
            ext: false,
            back: 'salepointhome'
        }
    },
    {
        path: '/:slug/slots/:aslot/menus',
        name: 'menulist',
        component: MenuList,
        props: true,
        meta: {
            title: 'menulist.title',
            titleParams: { name: 'sp/name' },
            titleCmp: 'acs-service-timeframe-selected',
            ext: false,
            back: 'salepointslot'
        }
    }
]
