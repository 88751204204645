<template>
    <v-list>
        <acs-product-translate
            :loading="loading"
            v-model="translate"
            @change="v => doTranslate(v.lang)" />

        <acs-product-filter
            v-if="categories.length"
            ref="filters"
            :disabled="loading"
            :translate="translate"
            :slug="slug"
            @change="loadCounts()" />

        <acs-product-category-image
            v-if="categoryNode && categoryNode.links_image"
            :value="categoryNode.links_image"
            :title="$options.filters.quickTranslate(categoryNode, 'name', translate)"
            cmp-class="mb-2" />

        <!-- <v-subheader v-if="category">{{ breadcrumb }}</v-subheader> -->
        <template v-if="categories.length">
            <acs-product-category-list-item
                v-for="category in categories"
                :key="category.id"
                :slug="slug"
                :category="category"
                :translate="translate" />

            <p class="overline text-center" v-if="!category">
                {{ priceType === 'taxincl' ? $t('productlist.taxincluded') : $t('productlist.taxexcluded') }}
            </p>
        </template>

        <acs-base-loader v-model="loading" />

        <acs-cart-scan
            v-if="cartNeedSlot"
            class="mx-0"
            :slug="slug"
            :aslot="$route.params.aslot"
            :label="$t('productlist.slot')" />
    </v-list>
</template>

<script>
import AcsProductCategoryListItem from '@/components/product-category/AcsProductCategoryListItem'
import AcsProductCategoryImage from '@/components/product-category/AcsProductCategoryImage'
import AcsProductTranslate from '@/components/product-list/AcsProductTranslate'
import AcsProductFilter from '@/components/product-list/AcsProductFilter'
import AcsCartScan from '@/components/cart/AcsCartScan'

import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-product-category-list',
    components: {
        AcsProductCategoryListItem,
        AcsProductCategoryImage,
        AcsProductTranslate,
        AcsProductFilter,
        AcsCartScan
    },
    props: {
        slug: { type: String },
        category: { type: String }
    },
    mixins: [RouteMixin],
    data: () => ({
        loading: false,
        translate: false
    }),
    computed: {
        categories() {
            if (!this.category) {
                return this.$store.getters['prod/categories']
            }
            return this.$store.getters['prod/findNode'](this.category)?.children || []
        },

        categoryNode() {
            return this.$store.getters['prod/findNode'](this.category)
        },

        breadcrumb() {
            if (!this.category) {
                return null
            }
            const breadcrumb = this.$store.getters['prod/breadcrumb'](this.category)
            return breadcrumb ? breadcrumb.map(n => n.name).join(' > ') : null
        },

        filters() {
            return this.$store.getters['prod/filters'](this.slug)
        },

        priceType() {
            return this.$store.getters['sp/current'].price_type
        },

        cartNeedSlot() {
            const services = this.$store.getters['ser/services'] || []
            return this.filters && !!services.find(service => service.id === this.filters.service && service.type === 'slot')
        }
    },
    watch: {
        category() {
            this.$emit('loaded')
        }
    },
    async mounted() {
        // si la traduction est activée, le trigger du load viendra de là
        !this.translate && await this.load()
        Promise.all([
            this.loadCounts(),
            this.$store.dispatch('ser/services', { slug: this.slug })
        ])
    },
    methods: {
        doTranslate(lang) {
            this.$emit('change:lang', {
                lang,
                translate: this.translate
            })
            return this.load(lang)
        },

        load(lang) {
            // si on est entrain de loader, on annule le debounce des filtres
            window.setTimeout(() => this.$refs.filters?.cancel(), 100)
            this.loading = true
            const countProducts = !this.categories.length
            return this.$store
                .dispatch('prod/categories', {
                    slug: this.slug,
                    translate: this.translate,
                    lang: lang
                })
                .then(() => {
                    this.$emit('loaded')
                    return countProducts && this.loadCounts()
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        loadCounts() {
            this.loading = true
            return this.$store
                .dispatch('prod/countProducts', {
                    slug: this.slug,
                    service: this.filters?.service,
                    tags: this.filters?.tags,
                    timeframes: this.filters?.timeframes
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
