<template>
    <v-alert
        class="acs-cart-alert"
        :value="!!products.length"
        color="primary"
        outlined
        @click="onClick">
        <v-row>
            <v-col class="grow">{{ $t('cart.pending') }}</v-col>
            <v-col class="shrink">
                <v-badge color="primary" overlap dot>
                    <v-icon color="primary">$vuetify.icons.ecomCart</v-icon>
                </v-badge>
            </v-col>
        </v-row>
    </v-alert>
</template>

<script>
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-cart-alert',
    mixins: [RouteMixin],
    computed: {
        products() {
            return this.$store.getters['cart/products']
        }
    },
    methods: {
        onClick() {
            this.$router.push({ name: this.getContextRouteName('cart') })
        }
    }
}
</script>

<style lang="sass">
.acs-cart-alert
    cursor: pointer
    background-color: white !important

</style>
