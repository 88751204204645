<template>
    <acs-base-container center>
        <v-card flat>
            <v-card-text>
                Page introuvable
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" to="/">Retour à l'accueil</v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'

export default {
    name: 'not-found',
    components: { AcsBaseContainer }
}
</script>
