<template>
    <v-btn
        depressed
        rounded
        :outlined="inverse"
        :to="to || undefined"
        :color="color"
        class="acs-btn--strong px-4"
        :class="{ small: small }"
        @click="e => $emit('click', e)"
        :disabled="disabled">
        <div class="py-2">
            <v-icon dark>{{ icon }}</v-icon>
        </div>
        <div class="acs-btn--content">
            <div v-if="!showForTeam">{{ service && service.name ? $options.filters.quickTranslate(service, 'name', true) : label }}</div>
            <div v-else>{{ $t('isOnlyTeam.menuLabel') }}</div>
        </div>
    </v-btn>
</template>

<script>
export default {
    name: 'acs-slot-btn',
    props: {
        inverse: { type: Boolean },
        color: { type: String, default: 'primary' },
        small: { type: Boolean },
        to: { type: [Object, String] },
        disabled: { type: Boolean },
        label: { type: String },
        icon: { type: String },
        service: { type: Object }
    },
    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        },
        showForTeam() {
            return this.$store.getters['auth/isOnly'](this.salepoint.id, 'team') && this.to?.name === 'menulisthome'
        }
    }
}
</script>

<style lang="sass">
.acs-btn--strong
    width: 100%
    border-radius: 4px
    font-weight: bold
    min-height: 160px
    white-space: normal
    text-align: left
    flex-grow: 1

    &.small
        min-height: 100px

    .v-btn__content
        height: 100%
        width: 100%
        align-items: start
        flex-direction: column

    .acs-btn--content
        word-wrap: normal
        letter-spacing: 0.9px
        max-width: 116px
</style>
