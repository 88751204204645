<template>
    <v-expansion-panels accordion tile focusable hover class="mb-1">
        <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate color="primary">
                <span class="white--text">{{ $t('accountcreate.title') }}</span>
                <template #actions>
                    <v-icon right color="white">$vuetify.icons.accountCreateNotif</v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="pt-3">a
                    <div class="pr-3" v-marked="$t('accountcreate.text')"></div>
                    <v-btn rounded color="primary" target="_blank" :href="newAccountUrl">
                        {{ $t('accountcreate.btn') }}
                    </v-btn>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    name: 'acs-pay-create-account',
    computed: {
        newAccountUrl() {
            return `${this.$config.client}/account/signin?b=user&back=${this.$route.path}`
        }
    }
}
</script>
