<template>
    <acs-base-container large center size="large">
        <v-card flat class="text-left">
            <v-stepper v-model="step" class="acs-pay-wizard">
                <v-stepper-items>
                    <v-stepper-content step="1" class="pa-0">
                        <acs-pay-amount
                            v-model="payamount"
                            :currency="currency"
                            max-width="400"
                            class="text-left acs-home--card"
                            @submit="next" />
                    </v-stepper-content>
                    <v-stepper-content step="2" class="pa-0">
                        <v-form v-model="valid" @submit.stop.prevent="submit">
                            <acs-pay-tip
                                v-model="tip"
                                :currency="currency"
                                :amount="payamount"
                                :dense="paystep !== 0"
                                max-width="400"
                                class="text-left acs-home--card"
                                @submit="next" />

                            <acs-pay-cg v-if="paystep === 0" v-model="conditionsAccepted" class="pa-3" />

                            <acs-cart-pay-process
                                v-if="step === 2 && !tipChange"
                                ref="payProcess"
                                type="bill"
                                :checkout-params="{
                                    amount: total,
                                    tip: tip,
                                    token: token
                                }"
                                :aslot="aslot"
                                :slug="slug"
                                :disabled="!valid"
                                :step.sync="paystep"
                                :captcha-enabled="this.$config.hcaptcha.enabled"
                                max-width="400"
                                class="text-left acs-home--card" />
                        </v-form>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>
    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsPayAmount from '@/components/pay/AcsPayAmount'
import AcsPayTip from '@/components/pay/AcsPayTip'
import AcsPayCg from '@/components/pay/AcsPayCg'
import AcsCartPayProcess from '@/components/cart/AcsCartPayProcess'
import RouteMixin from '@/mixins/RouteMixin'

import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'pay',
    components: { AcsBaseContainer, AcsPayAmount, AcsPayTip, AcsCartPayProcess, AcsPayCg },
    mixins: [RouteMixin],
    props: {
        slug: { type: String, required: true },
        aslot: { type: String }
    },
    data: () => ({
        loading: false,
        token: uuidv4(), // pour l'idempotency key utilisé par stripe, vu qu'il n'y a pas de panier
        step: 1,
        paymode: null,
        payamount: null,
        tipChange: false,
        tip: null,
        paystep: 0,
        conditionsAccepted: false,
        valid: false
    }),
    computed: {
        total() {
            return (this.payamount || 0) + (this.tip || 0)
        },
        salepoint() {
            return this.$store.getters['sp/current']
        },
        currency() {
            return this.salepoint.currency_users && this.salepoint.currency_users.toUpperCase()
        },
        connected() {
            return this.$store.getters['auth/connected']
        }
    },
    watch: {
        tip() {
            this.tipChange = true
            this.$nextTick(() => (this.tipChange = false))
        }
    },
    mounted() {
        if (this.connected) {
            this.conditionsAccepted = true
        }
        this.loading = true
        return Promise
            .all([
                this.$store.dispatch('sp/current', { slug: this.slug }),
                this.aslot && this.$store.dispatch('sp/slot', { slug: this.slug, slot: this.aslot })
            ])
            .catch(err => this.$err(err))
            .finally(() => (this.loading = false))
    },
    methods: {

        next() {
            this.step = Number(this.step) + 1
        }
    }
}
</script>

<style lang="sass">
.acs-pay-wizard
    border: 0
    box-shadow: none !important
</style>
