<template>
    <div>
        <slot name="paybutton"></slot>
    </div>
</template>

<script>
export default {
    name: 'acs-psp-provider-sbp',

    metadata: {
        provider: 'sbp',
        color: '#F7931A'
    },

    props: {
        loadingIndicator: { type: Boolean },
        psp: { type: String },
        pspData: { type: Object },
        slug: { type: String },
        disabled: { type: Boolean }
    },

    data: () => ({
        checkoutAction: {}
    }),

    computed: {
        loading: {
            get() {
                return this.loadingIndicator
            },
            set(v) {
                this.$emit('update:loadingIndicator', v)
            }
        }
    },

    methods: {
        onSelect(checkoutData, checkoutAction) {
            this.checkoutAction = checkoutAction
            this.loading = false
        },

        async onConfirmPayment(route) {
            if (this.disabled) {
                return
            }
            const { action, actionData } = this.checkoutAction

            this.loading = true

            const res = await this.$store.dispatch(action, actionData)
            if (!res.checkoutUrl) {
                throw new Error('No checkout URL found!')
            }
            window.location.href = res.checkoutUrl
            // on ne stoppe pas le load, puisqu'il y a redirect
        },

        reset() {
            this.checkoutAction = {}
        }
    }
}
</script>
