<template>
    <v-btn
        small
        :outlined="!selected"
        :disabled="!selected && (disabled || outdated)"
        :text="!selected"
        class="mb-3"
        color="primary"
        @click="$emit('click', calendarDate)">
        {{ label }}
    </v-btn>
</template>

<script>
export default {
    name: 'acs-service-timeframe-calendar-date',

    props: {
        slug: { type: String },
        serviceId: { type: [String, Number] },
        calendarDate: { type: Object },
        selected: { type: Boolean },
        disabled: { type: Boolean },
        isDay: { type: Boolean }
    },

    computed: {
        label() {
            if (this.isDay) {
                return this.$i18n.t('servicetimeframe.selectlabel')
            }
            return this.calendarDate.name
        },

        outdated() {
            return new Date(this.calendarDate.date) < new Date()
        }
    }
}
</script>
