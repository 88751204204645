import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import lodash from 'lodash'
import vuetifyconfig from '@/shared/plugins/vuetify'

import XIcon from '@/shared/components/icons/XIcon'

Vue.use(Vuetify)

export default new Vuetify(lodash.merge(vuetifyconfig, {
    theme: {
        themes: {
            light: {
                primaryLight: '#AAEDFD'
            }
        }
    },
    icons: {
        values: {
            // base
            baseSort: 'mdi-drag-horizontal-variant',
            update: 'mdi-cellphone-arrow-down',
            dot: 'mdi-circle-medium',
            // home
            loginEmail: 'mdi-at',
            loginPassword: 'mdi-lock',
            userMenu: 'mdi-cog-outline',
            logout: 'mdi-logout',
            survey: 'mdi-star',
            qr: 'mdi-qrcode-scan',
            // fillin
            passwordOn: 'mdi-eye-off',
            passwordOff: 'mdi-eye',
            aliasWarning: 'mdi-alert-circle',
            // fillvalidate
            validateAccount: 'mdi-email-newsletter',
            // salepointtable
            waitServices: 'mdi-timer-sand',
            waitCall: 'mdi-bell-ring',
            waitBill: 'mdi-cash-multiple',
            tableMenu: 'mdi-cart-outline',
            billcard: 'mdi-credit-card-outline',
            billcash: 'mdi-circle-multiple',
            waitRead: 'mdi-check-all',
            payBill: 'mdi-credit-card',
            paySurveyPoint: 'mdi-check-circle',
            tip: 'mdi-circle-multiple',
            discount: 'mdi-sale-outline',
            // quit
            quit: 'mdi-logout-variant',
            // check
            check: 'mdi-check',
            // social
            socialinstagram: 'mdi-instagram',
            socialfacebook: 'mdi-facebook',
            socialwebsite: 'mdi-web',
            sociallinkedin: 'mdi-linkedin',
            socialyoutube: 'mdi-youtube',
            // socialtwitter: 'mdi-twitter',
            socialtwitter: {
                component: XIcon
            },
            socialfaq: 'mdi-help-circle',
            // ecommerce
            ecomSelectOn: 'mdi-radiobox-marked',
            ecomSelectOff: 'mdi-radiobox-blank',
            ecomUndo: 'mdi-restore',
            ecomTagMore: 'mdi-plus',
            ecomTagLess: 'mdi-minus',
            ecomCart: 'mdi-cart-outline',
            ecomCartGo: 'mdi-cart-arrow-right',
            ecomSoundOn: 'mdi-bell-check',
            ecomSoundOff: 'mdi-bell-cancel-outline',
            ecomCardDefaultOn: 'mdi-star',
            ecomCardDefaultOff: 'mdi-star-outline',
            ecomCard: 'mdi-credit-card',
            ecomCartReset: 'mdi-restart',
            // PSP
            psp_sbp: 'mdi-bitcoin',
            psp_demo: 'mdi-alpha-d-circle-outline',
            psp_cashregister: 'mdi-cash-register',
            psp_stripe: 'mdi-credit-card',
            psp_saferpay: 'mdi-credit-card',
            psp_terminal: 'mdi-monitor-screenshot',
            // account
            accountCreateNotif: 'mdi-alert-circle',
            account: 'mdi-account-box'
        }
    }
}))
