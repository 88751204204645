import Vue from 'vue'
import lodash from 'lodash'
import Vuetify from '@/plugins/vuetify'

export default {
    namespaced: true,

    state: {
        current: {},
        slot: {},
        menus: [],
        menuLang: null,
        countries: [],
        survey: {},
        surveySchema: null,
        account: {},
        open: null,
        openExpireAt: null
    },

    getters: {
        current: state => state.current,
        name: state => state.current && state.current.name,
        slot: state => state.slot,
        menus: state => state.menus,
        countries: state => state.countries,
        survey: state => state.survey,
        account: state => state.account
    },

    mutations: {
        current(state, data) {
            state.current = data.current
        },

        update(state, data) {
            for (const i in data) {
                state.current[i] = data[i]
            }
        },

        slot(state, data) {
            state.slot = data.slot
        },

        menus(state, data) {
            state.menus = data.menus
        },

        countries(state, data) {
            state.countries = data.countries
        },

        account(state, data) {
            state.account = data.account
        },

        updateService(state, { type, id, disabled }) {
            if (type) {
                // indexOf sert à attraper billcash et billcard avec le type [bill]
                const s = state.current.services.find(s => type.indexOf(s.type) === 0)
                if (s) {
                    s.disabled = disabled
                }
            } else {
                state.current.services.forEach(s => {
                    if (s.id === id) {
                        s.disabled = disabled
                    }
                })
            }
        },

        reset(state) {
            state.current = {}
            state.slot = {}
            state.menus = []
            state.countries = []
            state.survey = {}
            state.surveySchema = null
            state.account = {}
            state.open = null
            state.openExpireAt = null
        }
    },

    actions: {
        async salepoints(context, { role = null } = {}) {
            const res = await Vue.prototype.$api.get('/salepoints', { params: { role } })
            return res.data
        },

        async current(context, data) {
            if (context.state.current.slug === data.slug && !data.force) {
                return context.state.current
            }
            const res = await Promise.all([
                Vue.prototype.$api.get(`/salepoints/${data.slug}`, {
                    params: {
                        include_translation: true
                    }
                }),
                Vue.prototype.$api.get(`/salepoints/${data.slug}/config`)
            ])
            context.commit('current', { current: res[0].data })
            // mise à jour de la configuration du front en fonction des données
            // spécifiques au point de vente
            Vue.prototype.$config = lodash.merge(Vue.prototype.$config, res[1].data)
            if (res[0].data.colour) {
                Vuetify.framework.theme.themes.light.primary = res[0].data.colour
            }
            return res[0].data
        },

        async slot(context, data) {
            if (context.state.slot.slug === data.slot) {
                return context.state.slot
            }
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/slots/c/${data.slot}`)
            context.commit('slot', { slot: res.data })
            return res.data
        },

        async menus({ state, commit }, data) {
            if (state.menus.length && data.lang === state.menuLang) {
                return state.menus
            }
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/menus`, {
                params: {
                    include_translation: true
                }
            })
            state.menuLang = data.lang
            commit('menus', { menus: res.data })
            return res.data
        },

        async countries(context) {
            if (context.state.countries.length) {
                return context.state.countries
            }
            const res = await Vue.prototype.$api.get('/salepoints-countries')
            context.commit('countries', { countries: res.data })
            return res.data
        },

        async call(context, data) {
            const c = data.socket.client
            data.socket = undefined
            c.send('slotcall', data)
        },

        async getAccount(context, data) {
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/client/account`)
            context.commit('account', { account: res.data })
            return res.data
        },

        async open({ state }, data) {
            if (state.open && state.openExpireAt >= new Date()) {
                return state.open
            }
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/open`)
            state.open = res.data
            state.openExpireAt = new Date(Date.now() + (1000 * 60))
            return res.data
        },

        async translateWelcomeMessage(context, data) {
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/i18n/welcome-message`)
            return res.data
        },

        async survey(context, data) {
            if (context.state.surveySchema) {
                return context.state.surveySchema
            }
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/surveys/schema`)
            context.state.surveySchema = res.data
            return res.data
        }
    }
}
