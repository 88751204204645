<template>
    <v-card :max-width="maxWidth" flat>
        <v-progress-linear
            :value="progress"
            color="primary">
        </v-progress-linear>
        <v-window v-model="step">
            <v-window-item v-for="(item, i) in schema" :key="item" :value="i + 1">
                <v-card-text class="acs--survey-text">
                    <p class="acs--survey-header">{{ $t(`dosurvey.questions.${item}`) }}</p>
                    <v-rating
                        v-if="item !== 'q6'"
                        v-model="rating[i]"
                        background-color="amber lighten-3"
                        color="amber"
                        :length="stars"
                        class="my-5"
                    ></v-rating>
                    <v-textarea
                        v-else
                        v-model="comment"
                        filled
                        persistent-placeholder
                        :placeholder="$t('dosurvey.placeholder')"
                    ></v-textarea>
                </v-card-text>
            </v-window-item>
        </v-window>

        <v-card-actions>
            <v-col cols="6">
                <v-btn
                    :disabled="step === 1 && schema.length > 1"
                    color="primary"
                    rounded
                    block
                    @click="back">
                    {{ $t('dosurvey.buttons.back') }}
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn
                    v-if="step < schema.length"
                    color="primary"
                    rounded
                    block
                    @click="next">
                    {{ $t('dosurvey.buttons.next') }}
                </v-btn>
                <v-btn
                    v-if="step === schema.length"
                    color="primary"
                    rounded
                    block
                    @click="finish">
                    {{ $t('dosurvey.buttons.send') }}
                </v-btn>
            </v-col>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'acs-survey',
    data: () => ({
        rating: [0, 0, 0, 0, 0],
        comment: '',
        step: 1,
        stars: 7,
        progress: 0
    }),

    props: {
        maxWidth: { type: [String, Number] }
    },

    computed: {
        schema() {
            return this.$store.getters['sur/schema']
        },

        progressRatio() {
            if (this.schema.length <= 1) {
                return 100
            }
            return Math.round(100 / (this.schema.length - 1))
        }
    },

    methods: {
        back() {
            this.step--
            this.progress = this.progressRatio * (this.step - 1)
        },

        next() {
            this.step++
            this.progress = this.progressRatio * (this.step - 1)
        },

        finish() {
            this.$emit('finishsurvey', {
                rating: this.rating,
                comment: this.comment
            })
        }
    }
}
</script>

<style lang="sass" scoped>
.acs--survey-header
    font-weight: bold
    font-size: 30px
    line-height: 1.2em
    margin-bottom: .2em

.v-card__text, .v-card__title
    font-family: "Red Hat Display", "Roboto", sans-serif
    color: #000!important
    word-break: normal!important
    text-align: center
</style>
