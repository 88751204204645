<template>
    <div>
        <p class="text-center">{{ $t('shared-front:psp.providers.demo.title') }}</p>
        <slot name="paybutton"></slot>
    </div>
</template>

<script>
export default {
    name: 'acs-psp-provider-demo',

    props: {
        loadingIndicator: { type: Boolean },
        psp: { type: String },
        pspData: { type: Object },
        slug: { type: String },
        disabled: { type: Boolean }
    },

    data: () => ({
        checkoutAction: {}
    }),

    computed: {
        loading: {
            get() {
                return this.loadingIndicator
            },
            set(v) {
                this.$emit('update:loadingIndicator', v)
            }
        }
    },

    methods: {
        onSelect(checkoutData, checkoutAction) {
            this.checkoutAction = checkoutAction
            this.loading = false
        },

        async onConfirmPayment(route) {
            if (this.disabled) {
                return
            }
            const { action, actionData } = this.checkoutAction

            this.loading = true

            await this.$store.dispatch(action, actionData)

            window.location.href = route.href
            // on ne stoppe pas le load, puisqu'il y a redirect
        },

        reset() {
            this.checkoutAction = {}
        }
    }
}
</script>
