<template>
    <v-dialog v-model="show" scrollable max-width="600" :persistent="loading" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card>
            <acs-base-card-title :title="$t('Payment Saferpay')" :dismissible="!loading" @close="show = false" />
            <v-card-text class="mt-5">
                <template v-if="!moreInfoNeededRedirectUrl && !disableType">
                    <v-select v-model="type" :items="types" item-value="brand" item-text="brand">
                        <template #item="{ item, on, attrs }">
                            <v-list-item v-on="on" v-bind="attrs">
                                <v-list-item-action-text class="pr-3">
                                    <v-img v-if="item.logo_url" max-width="80" min-width="80" :src="item.logo_url" />
                                    <v-icon v-else>$vuetify.icons.billcard</v-icon>
                                </v-list-item-action-text>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t(`psp.brands.${item.brand}`) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template #selection="{ item }">
                            <v-list-item>
                                <v-list-item-action-text class="pr-3">
                                    <v-img v-if="item.logo_url" max-width="80" min-width="80" :src="item.logo_url" />
                                    <v-icon v-else>$vuetify.icons.billcard</v-icon>
                                </v-list-item-action-text>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t(`psp.brands.${item.brand}`) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                    <v-alert :value="$config.envMarker && type === 'card'" type="info" color="primary">
                        <a href="https://docs.saferpay.com/home/integration-guide/testing-and-go-live#visa-and-v-pay" target="_blank" class="white--text">Testing card numbers</a>
                        <br />
                        {{ $t('payrequest.test.message') }}
                        <br />
                        {{ $t('payrequest.test.cardnum') }}: <strong>9010504904000007</strong><br />
                        {{ $t('payrequest.test.date') }}: <strong>any data</strong><br />
                        {{ $t('payrequest.test.cvc') }}: <strong>any data</strong>
                    </v-alert>
                </template>
                <acs-base-loader v-model="loading" permanent />
                <iframe
                    class="asc-saferpay-iframe"
                    :src="paymentPageUrl"
                    :style="{ height: `${height}px` }"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'

export default {
    name: 'acs-psp-provider-saferpay-card',

    components: { AcsBaseCardTitle },

    props: {
        value: { type: Boolean },
        slug: { type: String },
        psp: { type: String },
        loadingIndicator: { type: Boolean }
    },

    data() {
        return {
            type: 'card',
            types: ['card', 'postfinancepay', 'twint'],
            moreInfoNeededRedirectUrl: null,
            paymentPageUrl: null,
            disableType: false,
            height: 450,
            iFrameHeightChange: e => this.onIframeHeightChange(e)
        }
    },

    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        loading: {
            get() {
                return this.loadingIndicator
            },
            set(v) {
                this.$emit('update:loading-indicator', v)
            }
        },

        demo() {
            return this.$store.getters['sp/current'].demo
        },

        disabled() {
            return this.loading
        }
    },

    watch: {
        show(v) {
            if (v) {
                window.addEventListener('message', this.iFrameHeightChange)
                this.disableType = false
                if (this.moreInfoNeededRedirectUrl) {
                    return
                }
                return this.load()
            } else {
                this.moreInfoNeededRedirectUrl = null
                window.removeEventListener('message', this.iFrameHeightChange)
            }
        },
        type: 'load'
    },

    beforeDestroy() {
        window.removeEventListener('message', this.iFrameHeightChange)
    },

    mounted() {
        this.loading = true
        return this.$store.dispatch('usr/listAvailablePaymentMethods', { slug: this.slug, psp: this.psp })
            .then(types => {
                this.types = types
            })
            .catch(err => this.$err(err))
            .finally(() => (this.loading = false))
    },

    methods: {

        load() {
            if (!this.type) {
                return
            }
            this.loading = true
            this.paymentPageUrl = null
            const data = {
                type: this.type,
                salepoint_slug: this.slug,
                slot_slug: this.$route.params.aslot
            }
            return this.$store.dispatch('usr/getSetupForNewCard', { slug: this.slug, data })
                .then(async({ url }) => {
                    this.paymentPageUrl = url
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$err(err)
                })
        },

        async onConfirmPayment(route, checkoutData, { action, actionData }) {
            try {
                this.loading = true

                const { url } = await this.$store.dispatch(action, actionData)

                if (!url) {
                    // pas d'url, le paiement est déjà fait, on redirige sur la page de succès
                    // on ne stoppe pas le load, puisqu'il y a redirect
                    window.location.href = route.href
                    return
                }

                // il faut rajouter des infos 3D secure
                this.moreInfoNeededRedirectUrl = route.href
                await this.$nextTick()

                this.loading = false
                this.show = true
                this.paymentPageUrl = url
            } catch (err) {
                this.loading = false
                return this.$err(err)
            }
        },

        reset() {
            this.checkoutAction = {}
        },

        onIframeHeightChange(e) {
            if (e.data.message === 'acc-mounted') {
                this.disableType = true
                return
            }
            if (e.data.message === 'acc-loaded') {
                let url = window.location.href
                if (url.indexOf('?') === -1) {
                    url += '?'
                }
                if (url.indexOf('setup_intent') === -1) {
                    url += '&setup_intent=1'
                }
                window.location.href = this.moreInfoNeededRedirectUrl || url
                return
            }
            this.height = e.data.height > 450
                ? e.data.height + 25
                : 450
        }
    }
}
</script>

<style lang="sass">
.asc-saferpay-iframe
    width: 100%
    border: 0
</style>
