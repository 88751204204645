import moment from 'moment'

export default {
    namespaced: true,

    state: {
        counter: '',
        duration: 0,
        running: false,
        life: null,
        percent: 0,
        interval: null,
        validated: false
    },

    getters: {
        validated: state => state.validated,
        counter: state => state.counter,
        percent: state => state.percent,
        running: state => state.running
    },

    mutations: {
        attach(state, data) {
            if (data.start >= data.duration) {
                state.running = false
                return
            }
            state.duration = data.duration
            state.life = moment({ second: 0 }).add(data.duration - (data.start || 0), 'seconds')
            state.counter = state.life.format('mm:ss')
            state.percent = 0
            state.running = true
        },

        detach(state) {
            state.running = false
        },

        validate(state, data) {
            state.validated = data.validated || false
        },

        down(state) {
            state.life.subtract(1, 'second')
            state.counter = state.life.format('mm:ss')
            state.percent = (state.duration - ((state.life.minutes() * 60) + state.life.seconds())) / state.duration * 100
            if (state.counter === '00:00') {
                state.percent = 100
                state.running = false
            }
        },

        interval(state, data) {
            state.interval = data
        }
    },

    actions: {
        start(context, data) {
            context.commit('attach', { duration: data.duration, start: data.start })
            context.commit('validate', { validated: data.validated })
            window.clearInterval(context.state.interval)

            context.commit('interval', window.setInterval(() => {
                context.state.running && context.commit('down')
                if (!context.state.running) {
                    window.clearInterval(context.state.interval)
                }
            }, 1000))
        },

        end(context) {
            context.commit('detach')
            window.clearInterval(context.state.interval)
        }
    }
}
