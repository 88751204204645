<template>
    <v-list dense>
        <acs-menu-list-item v-for="(menu, i) in socials" :key="i" :menu="menu" social />
        <acs-menu-list-item :menu="{ type: 'faq', url: faq }" social color="grey" />
    </v-list>
</template>

<script>
import AcsMenuListItem from '@/components/menu/AcsMenuListItem'

export default {
    name: 'acs-salepoint-socials',
    components: { AcsMenuListItem },
    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        },

        socials() {
            return this.salepoint.socials
        },

        faq() {
            return this.$store.getters['pub/url']('/faq-utilisateurs')
        }
    }
}
</script>
