<template>
    <v-dialog v-model="show" max-width="400" :persistent="loading">
        <template #activator="{ on, attrs }">
            <v-btn icon color="error" v-on="on" v-bind="attrs">
                <v-icon>$vuetify.icons.ecomCartReset</v-icon>
            </v-btn>
        </template>
        <v-card>
            <acs-base-card-title :title="$t('psp.providers.terminal.cartreset.title')" :dismissible="!loading" @close="show = false" color="error" />
            <v-card-text class="pt-3">
                <acs-base-ln-selector maximized class="mb-5" />
                <div v-marked="$t('psp.providers.terminal.cartreset.text')"></div>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn
                    block
                    rounded
                    color="error"
                    :loading="loading"
                    :disabled="loading"
                    @click="submit">
                    {{ $t('psp.providers.terminal.cartreset.submit') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'
import AcsBaseLnSelector from '@/shared/components/base/AcsBaseLnSelector'

import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-cart-reset-btn',

    components: { AcsBaseCardTitle, AcsBaseLnSelector },

    mixins: [RouteMixin],

    props: {
        slug: { type: String }
    },

    data: () => ({
        loading: false,
        show: false
    }),

    methods: {
        submit() {
            this.loading = false
            return Promise
                .all([
                    this.$store.dispatch('prod/saveFilters', {}),
                    this.$store.dispatch('cart/remove', { slug: this.slug })
                ])
                .catch(err => this.$err(err))
                .finally(() => this.$router.replace({ name: this.getContextRouteName('salepoint') }))
        }
    }
}
</script>
