<template>
    <v-container fluid>
        <v-row>

            <v-col class="acs-home--left" v-if="breakpoint">
                <v-img class="main-img"
                    src="/img/home-accessing.jpg"
                    :class="{ md: $vuetify.breakpoint.md }"
                    title="accessing.app"
                    alt="accessing.app" />

                <acs-base-ln-selector maximized class="lang-selector" />
            </v-col>

            <v-col class="acs-home--right py-0 pr-0" :class="{ 'pl-0': $vuetify.breakpoint.mdAndDown, mobile: !breakpoint }">
                <v-card
                    :max-width="breakpoint ? 400 : null"
                    class="main-box"
                    flat>
                    <div class="px-5 mb-5">
                        <v-img class="acs-image--full"
                            :max-width="breakpoint ? 300 : 500"
                            :src="`${$config.os}/assets/accessing-logo-slogan.svg`"
                            title="accessing.app"
                            alt="accessing.app" />
                    </div>
                    <v-card-text>
                        <acs-base-qrcode-btn @input="onScan">
                            <template #button="{ on }">
                                <acs-slot-btn
                                    color="primary"
                                    inverse
                                    v-on="on"
                                    :label="$t('home.scan')"
                                    icon="$vuetify.icons.qr" />
                            </template>
                        </acs-base-qrcode-btn>
                    </v-card-text>
                    <v-card-text v-if="!connected">
                        <p class="acs-horizontal-line"><span>{{ $t('userlogin.or') }}</span></p>
                    </v-card-text>
                    <v-card-text v-show="!connected">
                        <acs-home-login-form @login="login" />
                    </v-card-text>
                    <v-card-text v-if="connected">
                        <acs-home-logged />
                    </v-card-text>
                </v-card>

                <v-card flat :max-width="breakpoint ? 700 : null" :class="{ 'px-2': !breakpoint }">
                    <v-container class="py-5 my-5">
                        <v-row>
                            <v-col :cols="breakpoint ? 4 : 12">
                                <a :href="puburl('/')" target="_blank">
                                    <v-img
                                        :max-width="!breakpoint ? 200 : null"
                                        :src="`${$config.os}/assets/accessing-logo-rgb.svg`"
                                        title="accessing.app"
                                        alt="accessing.app" />
                                </a>
                            </v-col>
                            <v-col :cols="breakpoint ? 8 : 12">
                                {{ $t('home.definition') }}
                                <p><a :href="puburl('/')" target="_blank">{{ $t('home.more') }}</a></p>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-card flat class="team-app d-flex mb-5" color="primaryLight">
                        <v-card-text class="text-center">
                            <p>{{ $t('home.alreadyClient') }}</p>
                            <h3>{{ $t('home.downloadTeam') }}</h3>
                            <div class="d-flex logos justify-center mt-5 mb-2">
                                <a :href="$config.links.teamsPlaystore">
                                    <img src="/img/googleplay.png" alt="Google Play" />
                                </a>
                                <a :href="`${$config.links.teamsAppstore}?l=${ln}`">
                                    <img src="/img/applestore.png" alt="Apple Store" />
                                </a>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-card>
                <v-container class="links grey lighten-4 mt-5">
                    <v-row>
                        <v-col>
                            <ul>
                                <li><a :href="puburl('/ml')" target="_blank">{{ $t('home.legal') }}</a></li>
                                <li><a :href="$config.links.support" target="_blank">{{ $t('home.support') }}</a></li>
                            </ul>
                        </v-col>
                        <v-col>
                            <v-btn :href="$config.links.facebook" target="_blank" icon>
                                <v-icon>$vuetify.icons.socialfacebook</v-icon>
                            </v-btn>
                            <v-btn :href="$config.links.instagram" target="_blank" icon>
                                <v-icon>$vuetify.icons.socialinstagram</v-icon>
                            </v-btn>
                            <v-btn :href="$config.links.support" target="_blank" icon>
                                <v-icon>$vuetify.icons.baseContact</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AcsBaseQrcodeBtn from '@/shared/components/base/AcsBaseQrcodeBtn'
import AcsHomeLoginForm from '@/components/home/AcsHomeLoginForm'
import AcsHomeLogged from '@/components/home/AcsHomeLogged'
import AcsBaseLnSelector from '@/shared/components/base/AcsBaseLnSelector'
import AcsSlotBtn from '@/components/slot/AcsSlotBtn'

export default {
    name: 'home',

    components: {
        AcsBaseQrcodeBtn,
        AcsHomeLoginForm,
        AcsHomeLogged,
        AcsSlotBtn,
        AcsBaseLnSelector
    },

    computed: {
        breakpoint() {
            return this.$vuetify.breakpoint.mdAndUp
        },
        connected() {
            return this.$store.getters['auth/connected']
        },
        ln() {
            return this.$store.getters['ln/current']
        }
    },

    mounted() {
        if (this.$route.query.t) {
            this.loginByDelegationToken(this.$route.query.t)
        }
    },

    methods: {
        puburl(path) {
            return this.$store.getters['pub/url'](path)
        },

        loginByDelegationToken(token) {
            this.loading = true
            return this.$store.dispatch('auth/getTokenByDelegation', { token: token })
                .then(async() => {
                    await this.$store.dispatch('auth/loadUser')

                    const user = this.$store.getters['auth/user']
                    const firstRole = user.roles.find(r => ['team', 'terminal'].includes(r.role))

                    const salepoints = await this.$store.dispatch('sp/salepoints', { role: firstRole?.role })
                    window.location.href = this.$config.front + '/' + salepoints[0].slug
                })
                .catch(err => {
                    this.loading = false
                    return this.$err(err)
                })
        },

        login(data) {
            // redirection si souhaité
            if (this.$route.query.back) {
                return this.$router.push({ path: this.$route.query.back })
            }
            if (data.terminal) {
                window.location.href = this.$config.front + '/' + data.slug
                return
            }
            const url = data.team
                ? this.$config.team
                : this.$config.client

            // on s'assure du slash final, à cause des services workers
            window.location.href = url.substr(-1) === '/' ? url : `${url}/`
        },

        onScan(url) {
            window.location.href = url
        }
    }
}
</script>

<style lang="sass" scoped>
.acs-horizontal-line
    width: 100%
    text-align: center
    border-bottom: 1px solid #ccc
    line-height: 0.1em
    margin: 20px 0 10px

    span
        background-color: #fff
        padding: 0 10px

.acs-home--left
    margin-top: 7em

    .lang-selector
        max-width: 250px
        margin: 0 auto
        margin-top: 3em

    .main-img
        max-width: 464px
        margin: 0 auto

        &.md
            max-width: 200px

.acs-home--right

    .main-box
        margin: 0

    .links
        ul
            margin: 0
            list-style: none

    .team-app
        border-radius: 20px !important

        .logos img
            display: block
            cursor: pointer
            max-width: 150px
            margin-right: 1em

        .img-container img
            max-width: 150px

    a
        text-decoration: none
        font-weight: bold
</style>
