<template>
    <v-dialog v-model="show" max-width="400" scrollable>
        <v-form v-model="valid" @submit.stop.prevent="submit">
            <v-card>
                <acs-base-card-title :title="$t('ticket.x')" @close="show = false" />
                <v-card-text class="py-0 mt-4" v-show="useLastDate">
                    <p>{{ $t('shared-front:ticketxz.lastdate', { date: this.dates[0], time: times.start }) }}</p>
                </v-card-text>
                <v-card-actions class="mt-3">
                    <v-btn @click="useLastDate = !useLastDate" color="primary" outlined rounded block>
                        {{ $t('shared-front:ticketxz.managedate') }}
                    </v-btn>
                </v-card-actions>
                <v-card-text class="px-0" v-show="!useLastDate">
                    <p class="px-4 pt-4">{{ $t('ticket.description') }}</p>

                    <v-date-picker
                        v-model="dates"
                        color="primary"
                        no-title
                        full-width
                        :rules="[$required]"
                        :max="max"
                        @input="sortDates"
                        range />

                    <div class="px-4">
                        <acs-salepoint-schedule-timeslots class="mt-5"
                            v-model="times.start"
                            :end.sync="times.end"
                            overflow-disabled
                            seconds
                            :loose="dates.length > 1 && dates[0] !== dates[1]"
                            :removable="false"
                            end-optional />

                        <v-text-field
                            :label="$t('shared-front:ticketxz.selected')"
                            :rules="[$required]"
                            :value="selected"
                            readonly />
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        rounded
                        block
                        color="primary"
                        type="submit"
                        :disabled="!valid">
                        {{ $t('shared-front:ticketxz.download') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'
import AcsSalepointScheduleTimeslots from '@/components/salepoint-schedule/AcsSalepointScheduleTimeslots'
import moment from 'moment'

export default {
    name: 'acs-ticket-x',
    components: { AcsBaseCardTitle, AcsSalepointScheduleTimeslots },
    data: () => ({
        loading: false,
        useLastDate: true,
        valid: false,
        max: (new Date()).toISOString(),
        initDate: null,
        dates: [],
        times: {
            start: null,
            end: null
        }
    }),
    computed: {
        show: {
            get() {
                return this.$store.getters['team/ticketPrint']
            },
            set(v) {
                this.$store.commit('team/ticketPrint', { show: v })
            }
        },
        lnQuery() {
            return this.$store.getters['ln/query']
        },
        selected() {
            if (!this.dates.length) {
                return null
            }
            if (this.dates.length === 1) {
                return this.$i18n.t('shared-front:base.datefrom', { date: this.dates[0] })
            }
            const d = moment().rangeDate(this.dates[0], this.dates[1])
            if (!d.end) {
                return this.$i18n.t('shared-front:base.date', d)
            }
            return this.$i18n.t('shared-front:base.daterange', d)
        },
        salepoint() {
            return this.$store.getters['sp/current']
        },
        user() {
            return this.$store.getters['auth/user']
        }
    },
    watch: {
        'salepoint.id': 'load',
        useLastDate(v) {
            this.resetWithDate(v ? this.initDate : null)
        },
        show(v) {
            if (v) {
                this.load()
            }
        }
    },
    methods: {
        load() {
            if (!this.salepoint.id || !this.user?.id) {
                return
            }
            this.forceSaveDate = false
            this.useLastDate = true
            this.loading = true
            return this.$store.dispatch('team/ticketXLastDate', { slug: this.salepoint.slug, team_id: this.user.id })
                .then(date => {
                    this.initDate = date || new Date(this.salepoint.created_at)
                    this.resetWithDate(this.initDate)
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        async submit() {
            const start = new Date(this.dates[0])
            const t = this.times.start.split(':')
            start.setHours(t[0])
            start.setMinutes(t[1])
            start.setSeconds(t[2])

            const end = this.dates.length === 2
                ? new Date(this.dates[1])
                : new Date()

            end.setMilliseconds(999)

            if (this.times.end) {
                const t = this.times.end.split(':')
                end.setHours(t[0])
                end.setMinutes(t[1])
                end.setSeconds(t[2])
            }

            await this.$store.dispatch('pop/download', {
                url: `/salepoints/${this.salepoint.slug}/teams/${this.user.id}/ticketx`,
                params: {
                    start_date: start,
                    end_date: end
                }
            })

            // save du last date si souhaité
            if (this.useLastDate) {
                await this.$store.dispatch('team/saveTicketXLastDate', {
                    slug: this.salepoint.slug,
                    team_id: this.user.id,
                    date: end
                })
                this.resetWithDate(end)
            }
        },

        resetWithDate(date) {
            // on doit reseter le date range de cette façon, sinon le picker
            // n'est pas réactif aux changements
            this.dates = []
            this.times.start = '00:00:00'
            this.times.end = null

            if (date) {
                this.$nextTick(() => {
                    const parts = moment().toCustomString(date).split(' ')
                    this.dates = [parts[0]]
                    this.times.start = parts[1]
                    this.times.end = null

                    this.initDate = date
                })
            } else {
                this.$nextTick(() => {
                    this.dates = [this.max]
                    this.$nextTick(() => (this.dates = []))
                })
            }
        },

        sortDates() {
            this.dates.sort()
        }
    }
}
</script>
