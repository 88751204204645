<template>
    <v-list>
        <acs-menu-list-item v-for="menu in menus" :key="menu.id" :menu="menu" :translate="translate" />
        <acs-menu-list-item v-for="(menu, i) in socials" :key="i" :menu="menu" :translate="translate" social />
        <acs-menu-list-item :menu="{ type: 'faq', url: faq }" :translate="translate" social color="grey" />

        <acs-base-empty-list :value="showEmpty && !menus.length" :text="$t('menulist.empty')" />

    </v-list>
</template>

<script>
import AcsMenuListItem from '@/components/menu/AcsMenuListItem'
import AcsBaseEmptyList from '@/shared/components/base/AcsBaseEmptyList'

export default {
    name: 'acs-menu-list',
    components: { AcsMenuListItem, AcsBaseEmptyList },
    props: {
        showEmpty: { type: Boolean },
        translate: { type: Boolean }
    },
    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        },

        socials() {
            return this.salepoint.socials
        },

        menus() {
            return this.$store.getters['sp/menus']
        },

        faq() {
            return this.$store.getters['pub/url']('/faq-utilisateurs')
        }
    }
}
</script>
