<template>
    <v-dialog v-model="show" max-width="400" :persistent="loading">
        <v-form v-model="valid" @submit.stop.prevent="submit">
            <v-card>
                <acs-base-card-title :title="$t('shared-front:usercard.addcard')" :dismissible="!loading" @close="show = false" />
                <v-card-text>
                    {{ $t('psp.providers.demo.add') }}
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        rounded
                        block
                        color="primary"
                        type="submit"
                        :disabled="disabled"
                        :loading="loading">
                        {{ $t('shared-front:base.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'

export default {
    name: 'acs-psp-provider-demo-card',

    components: { AcsBaseCardTitle },

    props: {
        value: { type: Boolean },
        slug: { type: String },
        loadingIndicator: { type: Boolean }
    },

    data() {
        return {
            valid: false
        }
    },

    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        loading: {
            get() {
                return this.loadingIndicator
            },
            set(v) {
                this.$emit('update:loading-indicator', v)
            }
        },

        disabled() {
            return this.loading || !this.valid
        }
    },

    watch: {
        show(v) {
            v && this.load()
        }
    },

    methods: {
        load() {
            this.loading = true
            return this.$store.dispatch('usr/getSetupForNewCard', { slug: this.slug })
                .then(async({ id }) => {
                    await Promise.all([
                        this.$store.dispatch('usr/setDefaultCard', { slug: this.slug, cardId: id }),
                        this.$store.dispatch('usr/cards', { slug: this.slug, force: true })
                    ])
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$err(err)
                })
        },

        async submit() {
            this.show = false
        },

        async onConfirmPayment(route, checkoutData, { action, actionData }) {
            try {
                this.loading = true

                await this.$store.dispatch(action, actionData)

                const redirectUrl = this.$config.front + route.href

                window.location.href = redirectUrl
                // on laisse tourner le loading, parce qu'il y a redirection de toute façon
            } catch (err) {
                this.loading = false
                throw err
            }
        }
    }
}
</script>
