import Vue from 'vue'

const userDef = {
    civility: 'mx',
    ln: '',
    lastname: null,
    firstname: null,
    email: null,
    password: null,
    alias: null,
    remember: false,
    roles: []
}

export default {
    namespaced: true,

    state: {
        accessError: 0,
        connected: false,
        rightPopup: false,
        rightMessage: null,
        user: { ...userDef }
    },

    getters: {
        user: state => state.user,
        connected: state => state.connected,
        accessError: state => state.accessError,
        rightPopup: state => state.rightPopup,
        rightMessage: state => state.rightMessage,

        isOnly: state => (tenantId, role) => {
            const connected = state.user
            if (!connected.id) {
                return false
            }
            const roles = (connected.roles || [])
                .filter(r => (!tenantId && r.role !== 'user') || r.tenant_id === tenantId)
                .map(r => r.role)

            if (!roles.length) {
                return false
            }

            const filtered = roles.filter(r => r !== role)
            return roles.length && !filtered.length
        }
    },

    mutations: {
        user(state, data) {
            state.user = data.user
            state.connected = true
        },
        logout(state) {
            state.connected = false
            state.user = { ...userDef }
        },
        accessError(state) {
            state.accessError += 1
        },
        rightPopup(state, data) {
            state.rightPopup = data.show
            state.rightMessage = data.message
        }
    },

    actions: {
        async sendEmailToken(context, data) {
            return Vue.prototype.$api.post('/auth/newaccount', { email: data.email, meta: data.metadata })
        },

        async sendLostToken(context, data) {
            return Vue.prototype.$api.post('/auth/passwordlost', { email: data.email })
        },

        async setLostPassword(context, data) {
            return Vue.prototype.$api.put('/auth/passwordlost', { token: data.token, password: data.password })
        },

        async getTokenByDelegation(context, data) {
            return Vue.prototype.$api.post('/auth/sessions/fromdelegation', { token: data.token })
        },

        async getToken(context, data) {
            return Vue.prototype.$api.post('/auth/sessions', { ...data.user })
        },

        async refreshToken(context, data) {
            if (data && data.useHttp) {
                // utilisé dans l'errorInterceptor axios, pour éviter
                // une boucle infinie
                return Vue.prototype.$http.post(`${Vue.prototype.$api.defaults.baseURL}/auth/issuetoken`)
            }
            return Vue.prototype.$api.post('/auth/issuetoken')
        },

        async logout(context, data) {
            return Vue.prototype.$api.delete('/auth/sessions/current')
                .finally(() => context.commit('logout'))
        },

        async loadUser(context) {
            const res = await Vue.prototype.$api.get('/me')
            context.commit('user', { user: res.data })
            return res.data
        },

        async saveUser(context, data) {
            return Vue.prototype.$api.put('/me', data.user)
        }
    }
}
