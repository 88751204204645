import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        token: Vue.prototype.$storage.get('t'),
        active: false,
        endAt: null,
        now: null,
        interval: null
    },

    getters: {
        active: state => state.active,
        endAt: state => state.endAt && new Date(Number(state.endAt))
    },

    mutations: {
        down(state) {
            state.now = new Date()
            state.active = state.now <= state.endAt
        }
    },

    actions: {
        async check(context, data) {
            if (context.state.token === data.token && context.state.endAt) {
                // on a déjà récupéré la date du timestamp, pas besoin d'aller
                // le récupérer à nouveau
                return
            }
            context.state.endAt = null
            if (data.token) {
                context.state.token = data.token
                Vue.prototype.$storage.set('t', data.token)
            }
            if (!context.state.token) {
                return false
            }
            let res
            try {
                res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/slots/c/${data.table}/${context.state.token}`)
            } catch (e) {
                // à faire: gérer les erreurs
                return false
            }
            context.state.endAt = new Date(res.data.timestamp)
            return true
        },

        start(context, data) {
            if (!context.state.endAt) {
                // le token de date n'existe pas ou un problème lors de sa
                // récupération a eu lieu, on désactive le service d'appel par
                // sécurité
                context.state.active = false
                return
            }
            if (context.state.endAt < new Date()) {
                // le dernier scan remonte à trop loin => désactivation
                context.state.active = false
                return
            }
            // tout va bien
            context.state.active = true

            window.clearInterval(context.state.interval)

            context.state.interval = window.setInterval(() => {
                context.state.active && context.commit('down')
                if (!context.state.active) {
                    window.clearInterval(context.state.interval)
                }
            }, 1000)
        },

        end(context) {
            window.clearInterval(context.state.interval)
        }
    }
}
