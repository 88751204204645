<template>
    <div
        class="acs-salepoint-info--openings white--text d-flex flex-column align-center">
        <v-subheader class="white--text">{{ $t('salepointschedules.title') }}</v-subheader>
        <table>
            <tbody>
                <tr v-for="(schedule, index) in openings" :key="schedule.id" :class="{ active: isActive(schedule, index)}">
                    <td class="text-right acs-salepoint-info--timing">{{ schedule.name | dayname }}</td>
                    <td>
                        <v-icon :color="index === selected ? 'white' : 'transparent'">$vuetify.icons.dot</v-icon>
                    </td>
                    <td class="acs-salepoint-info--timing">
                        <ul v-if="isOpen(schedule)" class="acs-salepoint-timeslot-list">
                            <template v-if="schedule.timeslots.length">
                                <li v-for="(tslot, i) in schedule.timeslots"
                                    :key="i"
                                    :style="getTimeslotStyle(tslot)"
                                    :class="{ selected: index === selected && ((overflowTimeslot === null && i === timeslot) || i === overflowTimeslot) }">
                                    {{ tslot.start | date(lang, $t('salepointinfo.hours'), 'HH:mm') }}
                                    -
                                    {{ (tslot.overflowValue || tslot.end) | date(lang, $t('salepointinfo.hours'), 'HH:mm') }}
                                </li>
                            </template>
                            <span v-else class="acs-salepoint-info--name">{{ $t('salepointschedules.open') }}</span>
                        </ul>

                        <span v-else class="acs-salepoint-info--name">{{ $t('salepointschedules.closed') }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'acs-salepoint-info-schedule-opening',
    props: {
        schedules: { type: Array },
        day: { type: String },
        timeslot: { type: Number },
        expandTimeslotsHorizontally: { type: Boolean }
    },
    computed: {
        openings() {
            // eslint-disable-next-line
            return this.$options.filters.sortdays(this.schedules)
        },

        selected() {
            const item = this.openings.findIndex(day => day.name === this.day)
            // on est obligé de gérer ici le overflow pour l'affichage...
            if (item > -1 && this.timeslot >= 0 && this.openings[item].timeslots[this.timeslot].overflow === -1) {
                return item - 1 < 0 ? this.openings.length - 1 : item - 1
            }
            return item > -1 ? item : null
        },

        overflowTimeslot() {
            if (this.selected === null) {
                return null
            }
            const day = this.openings[this.selected]
            if (day.name === this.day) {
                return null
            }
            return this.openings[this.selected].timeslots.length - 1
        },

        lang() {
            return this.$store.getters['ln/current']
        }
    },
    methods: {
        isOpen(schedule) {
            if (!schedule.open) {
                return false
            }
            const data = schedule.timeslots.reduce((acc, s) => {
                if (!acc[s.overflow]) {
                    acc[s.overflow] = 0
                }
                acc[s.overflow] += 1
                return acc
            }, [])
            return !data[-1] || data[0] || data[1]
        },

        isActive(schedule, index) {
            if (schedule.timeslots?.length) {
                return !!schedule.timeslots.find((tslot, i) => {
                    return index === this.selected && ((this.overflowTimeslot === null && i === this.timeslot) || i === this.overflowTimeslot)
                })
            }
            return index === this.selected
        },

        getTimeslotStyle(slot) {
            const exp = this.expandTimeslotsHorizontally
            return {
                display: slot.overflow === -1 ? 'none' : (exp ? 'inline' : 'inline-block')
            }
        }
    }
}
</script>

<style lang="sass">

.acs-salepoint-info--openings
    tr.active
        .text-right, .acs-salepoint-info--name
            font-weight: bold

    td
        vertical-align: top

.acs-salepoint-timeslot-list
    margin: 0
    padding: 0 !important

    li
        padding-right: 0
        white-space: nowrap

        &:not(:last-child):after
            content: "/\00a0"

        &.selected
            font-weight: bold

.acs-salepoint-info--timing
    width: 48%

</style>
