<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="pt-0">
                <acs-slot v-if="tid && sid"
                    max-width="400"
                    class="acs-home--card" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AcsSlot from '@/components/slot/AcsSlot.vue'

export default {
    name: 'salepoint-table',
    components: { AcsSlot },
    props: {
        slug: { type: String, required: true },
        aslot: { type: String, required: true }
    },
    computed: {
        tid() {
            return this.$store.getters['sp/slot'].id
        },
        sid() {
            return this.$store.getters['sp/current'].id
        },
        isOnlyTerminal() {
            return this.$store.getters['auth/isOnly'](this.sid, 'terminal')
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            const data = await Promise
                .all([
                    this.$store.dispatch('sp/current', { slug: this.slug }),
                    this.$store.dispatch('sp/slot', { slug: this.slug, slot: this.aslot })
                ])
                .catch(err => {
                    let skip = false
                    // redirection sur la home du salepoint ou la home tout court
                    if (err.response && err.response.data && err.response.data.type === 'NotFound') {
                        skip = true
                        const page = err.response.data.model === 'salepoint' ? 'home' : 'salepointhome'
                        this.$router.replace({ name: page, params: { slug: this.slug } })
                    }
                    this.$err(err, { skip: skip })
                })

            if (this.isOnlyTerminal) {
                return this.$router.replace({
                    name: 'menulist'
                })
            }

            const sp = data[0]
            const tb = data[1]
            this.$store.commit('top/title', { title: `${tb && tb.name} - ${sp.name}` })
        }
    }
}
</script>
