import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        salepoint_id: null,
        schema: []
    },

    getters: {
        salepoint_id: state => state.salepoint_id,
        schema: state => state.schema
    },

    mutations: {
        salepoint_id(state, data) {
            state.salepoint_id = data.salepoint_id
        },

        reset(state) {
            state.salepoint_id = null
            state.schema = []
        }
    },

    actions: {
        async get(context, data) {
            if (context.state.salepoint_id === data.salepoint_id) {
                return context.state.salepoint_id
            }

            const res = await Vue.prototype.$api.get(`/salepoints/${data.salepoint_id}/surveys`)
            context.commit('salepoint_id', { salepoint_id: res.data })
            return res.data
        },

        async create(context, data) {
            if (context.state.salepoint_slug === data.salepoint_slug) {
                return context.state.salepoint_slug
            }

            return Vue.prototype.$api.post(`/salepoints/${data.salepoint_slug}/surveys`, {
                survey: data.survey,
                slot_slug: data.slot
            })
            // context.commit('salepoint_id', { salepoint_id: res.data })
        },

        async schema(context, data) {
            if (context.state.schema.length) {
                return context.state.schema
            }
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/surveys/schema`)
            context.state.schema = res.data
            return res.data
        }
    }
}
