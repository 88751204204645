<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="pt-0">
                <acs-salepoint-home v-if="sid"
                    max-width="400"
                    class="acs-home--card"
                    @submit="submit" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AcsSalepointHome from '@/components/salepoint/AcsSalepointHome.vue'

export default {
    name: 'salepoint-home',
    props: {
        slug: { type: String, required: true }
    },
    components: {
        AcsSalepointHome
    },
    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        },
        sid() {
            return this.salepoint.id
        },

        isOnlyTerminal() {
            return this.$store.getters['auth/isOnly'](this.salepoint.id, 'terminal')
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            const sp = await this.$store.dispatch('sp/current', { slug: this.slug })
                .catch(err => {
                    let skip = false
                    // redirection sur la home
                    if (err.response && err.response.data && err.response.data.type === 'NotFound') {
                        skip = true
                        this.$router.replace({ name: 'home' })
                    }
                    this.$err(err, { skip: skip })
                })

            if (this.isOnlyTerminal) {
                return this.$router.replace({
                    name: 'menulisthome'
                })
            }

            this.$store.commit('top/title', { title: sp.name })
        },

        submit(data) {
            this.$router.push({ name: 'salepointslot', params: { slug: this.slug, slot: data.slot } })
        }
    }
}
</script>
