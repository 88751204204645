import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        products: [],
        cart: null,
        currentCartId: Vue.prototype.$storage.get('cart_id', null)
    },

    getters: {
        products: state => state.products,
        quantities: state => state.products.reduce((sum, product) => sum + product.quantity, 0),
        cart: state => state.cart,
        currentCartId: state => state.currentCartId
    },

    mutations: {
        reset(state) {
            state.cart = null
            state.currentCartId = null
        },

        setCurrentCartId(state, { id }) {
            state.currentCartId = id
            Vue.prototype.$storage.set('cart_id', id)
        },

        updateProductQuantity(state, { id, quantity }) {
            const cartproduct = state.products.find(cp => cp.id === id)
            if (cartproduct) {
                cartproduct.quantity = quantity
            }
        }
    },

    actions: {
        async get({ state }, { slug, translate }) {
            const res = await Vue.prototype.$api
                .get(`/me/salepoints/${slug}/cart`, {
                    params: {
                        include_translation: translate
                    }
                })
                .catch(err => {
                    if (err.response?.status === 404) {
                        return Vue.prototype.$api.delete(`/me/salepoints/${slug}/cart`)
                    }
                    throw err
                })

            state.cart = res.data
            state.products = res.data?.products || []
            return state.cart
        },

        async update({ state, dispatch }, data) {
            if (!state.cart && !data.createIfNotExists) {
                return
            }

            // reset des timeframes si le service a changé
            if (state.cart && data.service_id && data.service_id !== state.cart.service_id && !data.timeframes) {
                data.timeframes = []
            }

            await Vue.prototype.$api.put(`/me/salepoints/${data.slug}/cart?create_if_not_exists=${data.createIfNotExists ? 'true' : 'false'}`, {
                service_id: data.service_id || undefined,
                order_mode: data.order_mode || undefined,
                tip: data.tip >= 0 ? data.tip : undefined,
                slot_slug: data.slot_slug || undefined,
                message: data.message || undefined,
                timeframes: data.timeframes || undefined
            })

            if (state.cart) {
                if (data.service_id) {
                    state.cart.service_id = data.service_id
                }
                if (data.order_mode) {
                    state.cart.order_mde = data.order_mode
                }
                if (data.tip >= 0) {
                    state.cart.tip = data.tip
                }
                if (data.slot_slug) {
                    state.cart.slot_slug = data.slot_slug
                }
                if (data.message) {
                    state.cart.message = data.message
                }
                if (data.timeframes) {
                    state.cart.timeframes = data.timeframes
                }
            } else {
                await dispatch('get', { slug: data.slug })
            }
        },

        async updateDiscount({ state }, { slug, discount }) {
            await Vue.prototype.$api.put(`/me/salepoints/${slug}/cart/discount`, {
                discount
            })
            state.cart.discount = discount
        },

        async remove({ state, commit }, data) {
            await Vue.prototype.$api.delete(`/me/salepoints/${data.slug}/cart`)
            state.cart = null
            state.products = []
            commit('setCurrentCartId', { id: null })
        },

        async validate(context, data) {
            return Vue.prototype.$api.post(`/me/salepoints/${data.slug}/cart/validate`)
        },

        async validateLast(context, data) {
            return Vue.prototype.$api.post(`/me/salepoints/${data.slug}/cart/validate-last`)
        },

        async addProduct({ state, dispatch }, data) {
            return Vue.prototype.$api
                .post(`/me/salepoints/${data.slug}/cart`, {
                    product_id: data.product_id,
                    quantity: data.quantity,
                    options: data.options
                })
                .then(res => {
                    state.products.push(res.data)
                })
                .catch(err => {
                    if (err.response?.data?.error?.type === 'productexists') {
                        // le produit existe déjà avec ces options, on adapte la quantité
                        const product = state.products.find(cp => cp.id === err.response.data.error.id)
                        if (product) {
                            return dispatch('updateProductQuantity', {
                                slug: data.slug,
                                cartproduct_id: product.id,
                                product_id: data.product_id,
                                quantity: product.quantity + data.quantity
                            })
                        }
                    }
                    throw err
                })
        },

        async updateProductQuantity({ state, commit, dispatch }, data) {
            return Vue.prototype.$api
                .put(`/me/salepoints/${data.slug}/cart/products/${data.cartproduct_id}`, {
                    product_id: data.product_id,
                    quantity: data.quantity
                })
                .then(() => commit('updateProductQuantity', { id: data.cartproduct_id, quantity: data.quantity }))
                .catch(err => {
                    if (err.response?.data?.error?.type === 'productexists') {
                        // le produit existe déjà avec ces options, on adapte la quantité de
                        // l'existant et on supprime celui-ci
                        const product = state.products.find(cp => cp.id === err.response.data.error.id)
                        if (product) {
                            return Promise.all([
                                dispatch('updateProductQuantity', {
                                    slug: data.slug,
                                    cartproduct_id: product.id,
                                    product_id: data.product_id,
                                    quantity: product.quantity + data.quantity
                                }).then(() => commit('updateProductQuantity', { id: product.id, quantity: product.quantity + data.quantity })),
                                dispatch('removeProduct', {
                                    slug: data.slug,
                                    cartproduct_id: data.cartproduct.id
                                })
                            ])
                        }
                    }
                    throw err
                })
        },

        async removeProduct({ state }, data) {
            await Vue.prototype.$api.delete(`/me/salepoints/${data.slug}/cart/products/${data.cartproduct_id}`)

            const index = state.products.findIndex(p => p.id === data.cartproduct_id)
            if (index !== -1) {
                state.products.splice(index, 1)
            }
        },

        async getAmountsByTax(context, data) {
            const res = await Vue.prototype.$api.get(`/me/salepoints/${data.slug}/cart/taxes`)
            return res.data
        },

        async lock(context, { slug }) {
            await Vue.prototype.$api.post(`/me/salepoints/${slug}/cart/lock`)
        },

        async share(context, { slug, token }) {
            await Vue.prototype.$api.post(`/me/salepoints/${slug}/cart/share`, {
                token
            })
        }
    }
}
