<template>
    <acs-base-container center size="large">
        <acs-survey @finishsurvey="finishSurvey" />
    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsSurvey from '@/components/survey/AcsSurvey'

export default {
    name: 'survey',
    props: {
        slug: { type: String, required: true },
        aslot: { type: String, required: false }
    },
    components: {
        AcsBaseContainer,
        AcsSurvey
    },
    computed: {
        schema() {
            return this.$store.getters['sur/schema']
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            await Promise.all([
                this.$store.dispatch('sp/current', { slug: this.slug }),
                this.$store.dispatch('sur/schema', { slug: this.slug })
            ])
            if (this.aslot) {
                await this.$store.dispatch('sp/slot', { slug: this.slug, slot: this.aslot })
            }
        },

        async finishSurvey(params) {
            const survey = {
                comment: params.comment
            }
            this.schema.forEach((item, i) => survey[item] = params.rating[i])
            // q6 correspond au commentaire, stocké différemment
            survey.q6 = undefined

            return this.$store
                .dispatch('sur/create', {
                    survey: survey,
                    slot: this.aslot,
                    salepoint_slug: this.slug
                })
                .then(() => this.$router.push({ name: 'surveyfinish', params: { slug: this.slug, slot: this.aslot } }))
                .catch(err => this.$err(err))
        }
    }
}
</script>
