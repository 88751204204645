<template>
    <v-list-item>
        <v-list-item-icon class="acc-icon--center">
            <v-icon large>$vuetify.icons.ecomCard</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
            <v-list-item-title><samp>**** **** **** {{ card.last4 }}</samp></v-list-item-title>
            <v-list-item-subtitle>{{ brand }} <span v-if="card.name">({{ card.name }})</span></v-list-item-subtitle>
            <v-list-item-subtitle>{{ expirationDate }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon
            v-if="!card.test"
            @click.stop="setDefaultCard(card)">
            <v-tooltip v-if="!loading" bottom>
                <template #activator="{ on, attrs }">
                    <v-icon small v-on="on" v-bind="attrs" class="pr-3">
                        {{ card.active ? '$vuetify.icons.ecomCardDefaultOn' : '$vuetify.icons.ecomCardDefaultOff' }}
                    </v-icon>
                </template>
                {{ $t(card.active ? 'shared-front:usercard.defaultcard' : 'shared-front:usercard.usedefaultcard') }}
            </v-tooltip>
            <v-progress-circular v-else indeterminate color="grey" size="16" width="2" class="mr-3 mt-1" />
            <v-icon small @click.stop="show = true" color="red">$vuetify.icons.baseDelete</v-icon>
        </v-list-item-icon>

        <v-list-item-icon v-else>
            <strong class="primary--text">[TEST]</strong>
        </v-list-item-icon>

        <v-dialog v-model="show" max-width="400" :persistent="loading">
            <v-card>
                <acs-base-card-title :title="$t('shared-front:usercard.deletecardtitle')" :dismissible="!loading" @close="show = false" />
                <v-card-text class="mt-3">
                    {{ $t('shared-front:usercard.deletecard') }}
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn
                        block
                        rounded
                        color="primary"
                        :loading="loading"
                        :disabled="loading"
                        @click="remove">
                        {{ $t('shared-front:base.remove') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-list-item>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'

export default {
    name: 'acs-pay-card-list-item',
    components: { AcsBaseCardTitle },
    props: {
        slug: { type: String },
        card: { type: Object },
        index: { type: Number }
    },
    data: () => ({
        loading: false,
        show: false
    }),
    computed: {
        expirationDate() {
            return this.dateFormatter.format(new Date(this.card.expiration))
        },
        brand() {
            return this.card.brand.toUpperCase()
        },
        dateFormatter() {
            return new Intl.DateTimeFormat(this.$store.getters['ln/currentWithRegion'], {
                month: 'short',
                year: 'numeric'
            })
        }
    },
    methods: {
        setDefaultCard() {
            this.loading = true
            return this.$store.dispatch('usr/setDefaultCard', { slug: this.slug, cardId: this.card.id })
                .then(() => {
                    this.$emit('select', { card: this.card, index: this.index })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        remove() {
            this.loading = true
            const cardId = this.card.id

            // si on le met dans le then(), comme le store aura déjà enlevé
            // l'élément du tableau, le emit ne sera plus actif et la
            // récup de l'event chez le parent ne fonctionnera pas
            this.$emit('remove', { cardId })

            return this.$store.dispatch('usr/removeCard', { slug: this.slug, cardId })
                .then(() => {
                    this.show = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
