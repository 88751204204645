export default {
    props: {
        loadingIndicator: { type: Boolean }
    },

    data() {
        return {
            stripeLoadInterval: null,
            paymentElement: null,
            paymentElementError: null,
            completed: false,
            paymentChangeListener: e => this.onPaymentChange(e),
            paymentReadyListener: e => this.onPaymentReady(e),
            paymentErrorListener: e => this.onPaymentError(e)
        }
    },

    computed: {
        loading: {
            get() {
                return this.loadingIndicator
            },
            set(v) {
                this.$emit('update:loadingIndicator', v)
            }
        },

        lang() {
            return this.$store.getters['ln/current']
        },

        salepoint() {
            return this.$store.getters['sp/current']
        },

        appearance() {
            return {
                theme: 'stripe',
                variables: {
                    colorPrimary: this.salepoint.colour,
                    fontFamily: 'Nunito, Roboto, sans-serif'
                }
            }
        },

        appearanceFonts() {
            return [{
                cssSrc: 'https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&family=Red+Hat+Display:wght@400;500;700'
            }]
        }
    },

    beforeDestroy() {
        window.clearInterval(this.stripeLoadInterval)
        this._reset()
    },

    methods: {
        /**
         * Stripe peut ne pas encore être accessible (si refresh sur la page de paiement)
         * Dans ce cas, on doit attendre avant qu'il ne soit disponible
         *
         * @param {String} accountId
         * @returns {Promise<Object>}
         */
        async _getStripe(accountId, { keepLoading } = {}) {
            const get = () => {
                try {
                    // eslint-disable-next-line
                    return Stripe(this.$config.stripe.publicKey, {
                        locale: this.lang,
                        stripeAccount: accountId || undefined
                    })
                } catch (err) {
                    return null
                }
            }

            window.clearInterval(this.stripeLoadInterval)

            const promise = new Promise((resolve, reject) => {
                let count = 0

                const stripe = get()
                if (stripe) {
                    return resolve(stripe)
                }

                this.stripeLoadInterval = window.setInterval(() => {
                    this.loading = true

                    const stripe = get()
                    if (stripe) {
                        window.clearInterval(this.stripeLoadInterval)
                        return resolve(stripe)
                    }
                    count += 1
                    if (count >= 30) {
                        window.clearInterval(this.stripeLoadInterval)
                        reject(new Error('No stripe JS file found in <head>'))
                    }
                }, 1000)
            })

            return promise.finally(() => (this.loading = keepLoading || false))
        },

        async _createElement(stripe, el, secret) {
            try {
                this.paymentElementError = null

                await this.$store.dispatch('dbg/log', {
                    slug: this.slug,
                    cid: this.$store.getters['cart/cart']?.id,
                    msg: `start custom element with secret ${secret}`
                })

                const elements = stripe.elements({
                    clientSecret: secret,
                    fonts: this.appearanceFonts,
                    appearance: this.appearance
                })

                this.paymentElement = elements.create('payment')

                await this.$nextTick()

                this.paymentElement.mount(el)
                this.paymentElement.on('change', this.paymentChangeListener)
                this.paymentElement.on('ready', this.paymentReadyListener)
                this.paymentElement.on('loaderror', this.paymentErrorListener)

                // loading will be set to false when payment element is ready
                return elements
            } catch (err) {
                await this.$store.dispatch('dbg/log', {
                    slug: this.slug,
                    cid: this.$store.getters['cart/cart']?.id,
                    msg: 'starterror custom element',
                    err: err.message
                }).catch(() => null)

                throw err
            }
        },

        onPaymentChange(e) {
            this.completed = e.complete
        },

        async onPaymentReady() {
            this.loading = false
            this.paymentElementError = null

            return this.$store.dispatch('dbg/log', {
                slug: this.slug,
                cid: this.$store.getters['cart/cart']?.id,
                msg: 'ready custom element'
            })
        },

        async onPaymentError(event) {
            this.loading = false
            this.paymentElementError = event?.error?.message

            return this.$store.dispatch('dbg/log', {
                slug: this.slug,
                cid: this.$store.getters['cart/cart']?.id,
                msg: 'error custom element',
                err: `${event?.error?.type}: ${this.paymentElementError}`,
                event
            })
        },

        _reset() {
            if (this.paymentElement) {
                this.paymentElement.off('change', this.paymentChangeListener)
                this.paymentElement.off('ready', this.paymentReadyListener)
                this.paymentElement.off('loaderror', this.paymentErrorListener)
                this.paymentElement.destroy()
                this.paymentElement = null
            }
            this.completed = false
            this.paymentElementError = null
            this.paymentChangeListener = null
            this.paymentReadyListener = null
            this.paymentErrorListener = null
            this.loading = false
        }
    }
}
