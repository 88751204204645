<template>
    <div>
        {{ salepoint.name }}
        <br />{{ salepoint.address_street }}
        <br />{{ salepoint.address_zip }} {{ salepoint.address_city }}
        <br />{{ country }}
        <template v-if="salepoint.email">
            <br />{{ $t('salepoint.email') }}: <a :class="linkClass" :href="`mailto:${salepoint.email}`">{{ salepoint.email }}</a>
        </template>
        <template v-if="salepoint.phone">
            <br />{{ $t('salepoint.phone') }}: <a :class="linkClass" :href="`tel:${salepoint.phone}`">{{ salepoint.phone }}</a>
        </template>
    </div>
</template>

<script>
export default {
    name: 'acs-salepoint-info-address',
    props: {
        salepoint: { type: Object },
        dark: { type: Boolean }
    },
    computed: {
        country() {
            const countries = this.$store.getters['sp/countries']
            const c = countries.find(c => c.value === this.salepoint.address_country)
            return c && c.name
        },

        maplink() {
            const str = [
                this.salepoint.address_street,
                this.salepoint.address_zip,
                this.salepoint.address_city,
                this.country
            ]
            return `https://www.openstreetmap.org/search?query=${str.join(' ')}`
        },

        linkClass() {
            return !this.dark ? 'white--text' : ''
        }
    },
    mounted() {
        this.$store.dispatch('sp/countries')
    }
}
</script>
