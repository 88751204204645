import Vue from 'vue'
import Vuex from 'vuex'

import AcsBaseLnStore from '@/shared/store/AcsBaseLnStore'
import DebugStore from '@/shared/store/DebugStore'
import AcsAuthStore from '@/shared/store/AcsAuthStore'
import AcsMessageStore from '@/shared/store/AcsMessageStore'
import AcsPublicSiteStore from '@/shared/store/AcsPublicSiteStore'
import AcsLoadStore from '@/shared/store/AcsLoadStore'
import AcsClassStore from '@/shared/store/AcsClassStore'
import AcsPopupStore from '@/shared/store/AcsPopupStore'

import AcsBaseTopStore from '@/store/AcsBaseTopStore'
import AcsSalepointStore from '@/store/AcsSalepointStore'
import AcsSlotStore from '@/store/AcsSlotStore'
import AcsTableLockStore from '@/store/AcsTableLockStore'
import AcsSessionTimeoutStore from '@/store/AcsSessionTimeoutStore'
import AcsSalepointSurveyStore from '@/store/AcsSalepointSurveyStore'
import AcsUserStore from '@/store/AcsUserStore'
import AcsSocketStore from '@/store/AcsSocketStore'
import AcsProductStore from '@/store/AcsProductStore'
import AcsPspStore from '@/store/AcsPspStore'
import AcsCartStore from '@/store/AcsCartStore'
import AcsServiceStore from '@/store/AcsServiceStore'
import AcsOrderStore from '@/store/AcsOrderStore'
import AcsAudioStore from '@/store/AcsAudioStore'
import AcsAnalyticStore from '@/store/AcsAnalyticStore'
import AcsTeamStore from '@/store/AcsTeamStore'
import AcsNotificationStore from '@/store/AcsNotificationStore'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        ln: AcsBaseLnStore,
        load: AcsLoadStore,
        auth: AcsAuthStore,
        msg: AcsMessageStore,
        pub: AcsPublicSiteStore,
        cls: AcsClassStore,
        dbg: DebugStore,
        pop: AcsPopupStore,

        top: AcsBaseTopStore,
        sp: AcsSalepointStore,
        slot: AcsSlotStore,
        lock: AcsTableLockStore,
        out: AcsSessionTimeoutStore,
        sur: AcsSalepointSurveyStore,
        usr: AcsUserStore,
        socket: AcsSocketStore,
        prod: AcsProductStore,
        psp: AcsPspStore,
        cart: AcsCartStore,
        ser: AcsServiceStore,
        order: AcsOrderStore,
        audio: AcsAudioStore,
        ana: AcsAnalyticStore,
        team: AcsTeamStore,
        notif: AcsNotificationStore
    }
})
