<template>
    <div>
        <v-alert :value="!!demo" type="info" color="primary">
            <a href="https://stripe.com/docs/testing" target="_blank" class="white--text">Testing card numbers</a>
            <br />
            {{ $t('payrequest.test.message') }}
            <br />
            {{ $t('payrequest.test.cardnum') }}: <strong>4242 4242 4242 4242</strong><br />
            {{ $t('payrequest.test.date') }}: <strong>04/24</strong><br />
            {{ $t('payrequest.test.cvc') }}: <strong>242</strong>
        </v-alert>
        <div ref="payform" class="mx-1"></div>

        <v-alert :value="!!paymentElementError" type="error">{{ paymentElementError }}</v-alert>

        <slot name="paybutton"></slot>
        <p class="acs-stripe--logo mt-3 mb-0">
            <img src="/img/stripe.svg" title="Stripe" alt="Stripe" />
        </p>

        <v-input type="hidden" :value="completed" :rules="[$required]" single-line hide-details />
    </div>
</template>

<script>
import AcsPspProviderStripeMixin from './AcsPspProviderStripeMixin'

export default {
    name: 'acs-psp-provider-stripe',

    mixins: [AcsPspProviderStripeMixin],

    props: {
        psp: { type: String },
        slug: { type: String }
    },

    data() {
        return {
            // completed: false, --> dans le mixin
            // loading: false, --> dans le mixin
            // paymentElementError: null --> dans le mixin
            elements: null,
            stripe: null
        }
    },

    computed: {
        demo() {
            return this.$store.getters['sp/current'].demo
        }
    },

    beforeDestroy() {
        this.reset()
    },

    methods: {

        async onSelect({ account_id }, { action, actionData }) {
            try {
                this.loading = true

                const { secret } = await this.$store.dispatch(action, actionData)

                this.stripe = this.stripe || (await this._getStripe(account_id, { keepLoading: true }))

                this.elements = await this._createElement(this.stripe, this.$refs.payform, secret)
            } catch (err) {
                this.loading = false
                return this.$err(err)
            }
        },

        async onConfirmPayment(route) {
            try {
                this.loading = true

                const data = await this.stripe.confirmPayment({
                    elements: this.elements,
                    confirmParams: {
                        return_url: this.$config.front + route.href
                    }
                })
                if (data.error) {
                    const msg = `[${data.error.type || 'unknown'}] ${data.error.message || 'General error'}`
                    throw new Error(msg)
                }

                // on laisse tourner le loading, parce qu'il y a redirection de toute façon
            } catch (err) {
                this.loading = false
                this.$err(err, { silent: true })
            }
        },

        reset() {
            this._reset()
            this.elements = null
            this.stripe = undefined
        }
    }
}
</script>

<style lang="sass">
.acs-stripe--logo
    text-align: right
    opacity: 0.5

    img
        max-width: 100px
</style>
