<template>
    <v-card flat>
        <v-card-title>
            <div>{{ $t('paysummary.title') }}</div>
        </v-card-title>
        <v-card-text v-if="!dense">
            <p>{{ $t('paysummary.text') }}</p>
        </v-card-text>
        <v-card-text v-show="!dense" class="mb-5">
            <v-btn block outlined color="primary" v-if="!show" @click="show = true">
                <v-icon left>$vuetify.icons.baseAdd</v-icon>
                {{ $t('paysummary.add') }}
            </v-btn>
            <v-text-field v-else
                class="acs-pay-tip"
                name="paytip"
                v-model="tip"
                type="number"
                :rules="[$ranged(0)]"
                outlined
                :label="$t('paysummary.tiplabel')">
                <template #prepend>
                    <acs-base-svg src="/img/coins.svg" />
                </template>
            </v-text-field>
        </v-card-text>
        <v-divider />
        <v-simple-table class="acs-pay-summary">
            <tbody>
                <tr>
                    <td>{{ $t('paysummary.bill') }}</td>
                    <td>{{ amount | num(currency) }}</td>
                </tr>
                <tr v-show="tip">
                    <td>{{ $t('paysummary.tip') }}</td>
                    <td>{{ tip | num(currency) }}</td>
                </tr>
                <tr>
                    <th>{{ $t('paysummary.total') }}</th>
                    <th>{{ amount + (tip || 0) | num(currency) }}</th>
                </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>

<script>
import AcsBaseSvg from '@/shared/components/base/AcsBaseSvg'

export default {
    name: 'acs-pay-tip',
    components: { AcsBaseSvg },
    props: {
        currency: { type: String },
        amount: { type: Number },
        value: { type: [String, Number] },
        dense: { type: Boolean }
    },
    data: () => ({
        show: true // start visible (ACC-250)
    }),
    computed: {
        tip: {
            get() {
                return this.value || this.value === 0 ? Number(this.value) : null
            },
            set(v) {
                v = v || v === 0 ? Number(v) : null
                if (v < 0) {
                    v = 0
                }
                this.$emit('input', v)
            }
        }
    }
}
</script>

<style lang="sass">
.acs-pay-tip
    .v-input__prepend-outer
        margin-top: 4px

.acs-pay-summary
    td:nth-child(2), th:nth-child(2)
        text-align: right !important

    th
        font-size: 1.5em !important

.acs-svg--primary
    fill: red
</style>
