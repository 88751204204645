<template>
    <v-select
        name="language"
        v-model="lang"
        :label="$t('user.lang')"
        :items="langs"
        :rules="[$required]"
        outlined>
    </v-select>
</template>

<script>
export default {
    name: 'acs-user-lang-field',
    props: {
        value: { type: String }
    },
    computed: {
        lang: {
            get() {
                if (this.value) {
                    return this.value
                }
                const ln = this.$store.getters['ln/current']
                this.$emit('input', ln)
                return ln
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        langs() {
            return this.$store.getters['ln/available'].map(ln => ({
                value: ln,
                text: ln.toUpperCase()
            }))
        }
    }
}
</script>
