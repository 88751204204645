<template>
    <p class="d-flex flex-column align-center">
        <span class="caption text-uppercase">{{ date | date(lang, 'ddd') }}</span>
        <strong class="text-h6">{{ date | date(lang, 'D') }}</strong>
    </p>
</template>

<script>
export default {
    name: 'acs-service-timeframe-calendar-date-header',
    props: {
        date: { type: String }
    },
    computed: {
        lang() {
            return this.$store.getters['ln/current']
        }
    }
}
</script>
