<template>
    <v-row justify="space-around" align="start">
        <v-alert v-if="!overflowDisabled && end2" v-model="newday" border="top" color="primary" class="mx-3 acs-salepoint-schedule-timeslot-newday" dark>
            <span v-if="special">{{ $t('salepointschedule.special.timeslotsnewday', { start, end }) }}</span>
            <span v-else>{{ $t('salepointschedule.timeslotsnewday', { start, end, currentday, nextday }) }}</span>
            <slot name="newday-alert" :start="start" :end="end"></slot>
        </v-alert>
        <v-col class="py-0">
            <acs-salepoint-schedule-timeslot
                :disabled="disabled"
                :seconds="seconds"
                v-model="start"
                :max="!loose && end2 ? end2 : null"
                :label="$t('salepointschedule.from')" />
        </v-col>
        <v-col class="py-0">
            <acs-salepoint-schedule-timeslot
                :disabled="disabled"
                v-model="end2"
                :seconds="seconds"
                :min="!loose && start ? start : null"
                ref="end"
                :start="start"
                :neq-msg="$t('salepointschedule.neqerror')"
                manage-midnight
                :label="$t('salepointschedule.to')"
                :optional="endOptional" />
        </v-col>
        <v-col class="flex-0" v-if="removable !== false">
            <v-icon @click="$emit('remove')">$vuetify.icons.baseClose</v-icon>
        </v-col>
    </v-row>
</template>

<script>
import AcsSalepointScheduleTimeslot from '@/components/salepoint-schedule/AcsSalepointScheduleTimeslot'

export default {
    name: 'acs-salepoint-schedule-timeslots',
    components: { AcsSalepointScheduleTimeslot },
    props: {
        value: { type: String },
        end: { type: String },
        overflow: { type: Number },
        overflowValue: { type: String },
        overflowDisabled: { type: Boolean },
        loose: { type: Boolean },
        disabled: { type: Boolean },
        dayname: { type: String },
        special: { type: Boolean },
        seconds: { type: Boolean },
        removable: { type: Boolean },
        endOptional: { type: Boolean }
    },
    data: () => ({
        autoUpdateTimeslotValue: false
    }),
    computed: {
        start: {
            get() {
                return this.value
            },
            set(v) {
                this.emitOverflow(v, this.end2)
                this.$emit('input', v)
            }
        },

        end2: {
            get() {
                return this.end
            },
            set(v) {
                this.emitOverflow(this.start, v)
                this.$emit('update:overflow-value', null)
                this.$emit('update:end', v)
                if (this.autoUpdateTimeslotValue) {
                    this.autoUpdateTimeslotValue = false
                    this.$refs.end.update(v)
                }
            }
        },
        currentday() {
            return this.$options.filters.dayname && this.$options.filters.dayname(this.dayname)
        },
        nextday() {
            return this.$options.filters.dayname && this.$options.filters.dayname(this.dayname, 1)
        },
        newday() {
            const end = Number(this.end.replace(/:/g, '')) || 2400
            return this.start && this.end && Number(this.start.replace(/:/g, '')) > end
        }
    },

    mounted() {
        if (this.overflowValue) {
            this.autoUpdateTimeslotValue = true
            this.end2 = this.overflowValue
        }
    },

    methods: {
        emitOverflow(start, end) {
            if (!start || !end) {
                return this.$emit('update:overflow', 0)
            }
            const s = Number(start.replace(/:/g, ''))
            const e = Number(end.replace(/:/g, '')) || 2400

            this.$emit('update:overflow', s > e ? 1 : 0)
        }
    }
}
</script>

<style lang="sass">
.flex-0
    flex: 0

.acs-salepoint-schedule-timeslot-newday
    flex-basis: 100%
</style>
