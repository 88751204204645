<template>
    <v-app :class="appCls">
        <acs-base-top-bar-ext v-if="salepoint.id && ext" :right.sync="drawerRight" />
        <acs-base-top-bar v-else :right.sync="drawerRight" />
        <acs-base-menu-drawer v-model="drawerRight" />
        <v-main :class="appCls">
            <acs-base-message />
            <acs-base-env-marker />
            <acs-service-timeframe-popup />
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import AcsBaseTopBar from '@/components/base/AcsBaseTopBar'
import AcsBaseTopBarExt from '@/components/base/AcsBaseTopBarExt'
import AcsBaseMenuDrawer from '@/components/base/AcsBaseMenuDrawer'
import AcsBaseMessage from '@/shared/components/base/AcsBaseMessage'
import AcsBaseEnvMarker from '@/shared/components/base/AcsBaseEnvMarker'
import AcsServiceTimeframePopup from '@/components/service-timeframe/AcsServiceTimeframePopup'

import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'App',
    components: {
        AcsBaseMenuDrawer,
        AcsBaseTopBar,
        AcsBaseTopBarExt,
        AcsBaseMessage,
        AcsBaseEnvMarker,
        AcsServiceTimeframePopup
    },
    mixins: [RouteMixin],
    data: () => ({
        drawerRight: false
    }),
    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        },
        slug() {
            return this.salepoint?.slug
        },
        ext() {
            return this.$store.getters['top/ext']
        },
        appCls() {
            return this.$store.getters['cls/app']
        },
        mainCls() {
            return this.$store.getters['cls/main']
        },
        ln() {
            return this.$store.getters['ln/current']
        },
        isOnlyTeamOrTerminal() {
            const team = this.$store.getters['auth/isOnly'](this.salepoint?.id, 'team')
            const terminal = this.$store.getters['auth/isOnly'](this.salepoint?.id, 'terminal')
            return team || terminal
        }
    },
    watch: {
        ln() {
            if (this.salepoint?.id) {
                // où que l'on soit dans l'app, si on change de langue, les
                // noms des services doivent être renouvelés
                return this.$store.dispatch('sp/current', { slug: this.salepoint.slug, force: true })
            }
        },
        'salepoint.id'(id) {
            return Promise.all([
                this.startSocket(),
                this.countVisit(),
                this.setPSPAssets()
            ])
        },
        $route: 'onRouteChange'
    },
    sockets: {
        async orderstatusupdated(data) {
            const orders = await this.$store.dispatch('order/ordersCache', { slug: this.slug, strict: true, days: this.$config.orders.maxLocalHistoryDays })
            if (!orders.map(o => o.id).includes(data.id)) {
                return
            }
            this.$store.commit('order/update', { orderId: data.id, status: data.status })
            if (this.isOnlyTeamOrTerminal) {
                return
            }
            // ACC-831: on alerte le client uniquement pour le statut prepared (et non plus delivered)
            if (['prepared'].includes(data.status)) {
                this.$store.commit('audio/alert', { loop: this.$config.orders.readySoundLoop })
            }
            if (data.status === 'prepared') {
                // la commande est prête, on redirige sur sa page de détail
                return this.$router.push({
                    name: this.getContextRouteName('cartsuccessorder'),
                    params: { orderId: data.id }
                })
            }
        }
    },
    mounted() {
        return this.startSocket()
    },

    methods: {
        async startSocket() {
            if (!this.salepoint?.id) {
                return
            }
            await this.stopSocket()
            return this.$store.dispatch('socket/start', { salepoint_id: this.salepoint.id })
        },

        stopSocket() {
            return this.$store.dispatch('socket/stop')
        },

        countVisit() {
            if (this.$route.meta.ignoreCount || !this.slug) {
                return
            }
            // on settimeout car $route n'est pas tout de suite setté à l'url au chargement
            return new Promise((resolve, reject) => {
                window.setTimeout(() => {
                    return this.$store
                        .dispatch('ana/homeCount', {
                            slug: this.slug,
                            slot_slug: this.$route.params.aslot
                        })
                        .then(() => resolve())
                        .catch(err => reject(err))
                }, 1000)
            })
        },

        onRouteChange(route) {
            if (route?.meta?.topbar?.absolute !== true) {
                this.$store.commit('cls/remove', { type: 'app', cls: 'acs-main-absolute' })
            }
        },

        setPSPAssets() {
            if (!this.salepoint?.id) {
                return
            }
            return this.$store.dispatch('psp/listAssets', { slug: this.salepoint.slug })
                .then(assets => {
                    // on supprime tous les éléments du head lié au PSP précédent
                    const els = document.querySelectorAll('script[data-psp="true"]')
                    els.forEach(el => document.head.removeChild(el))

                    assets.filter(asset => !!asset.src).forEach(asset => {
                        const exists = document.querySelector(`script[src="${asset.src}"]`)
                        if (exists) {
                            return
                        }
                        const el = document.createElement('script')
                        el.type = asset.type
                        el.src = asset.src
                        el.setAttribute('data-psp', 'true')
                        document.head.appendChild(el)
                    })
                })
                .catch(err => this.$err(err))
        }
    },

    beforeDestroy() {
        return this.stopSocket()
    }
}
</script>

<style lang="sass">
$primary: black
$breakpoint-tablet: 768px

div.v-application

    &, .text-h5, .text-subtitle-2, .text-caption
        font-family: "Nunito", "Roboto", sans-serif !important

    .text-subtitle-2
        font-weight: 700

    .text-caption
        color: rgba(0, 0, 0, 0.6)
        font-size: 15px !important

    .v-list-item__subtitle
        font-size: 1rem

.acs-home--card
    margin: 0 auto

.v-card__title
    word-break: break-word !important

.acs-image--full
    width: 100%

.acs-button-link
    text-transform: none

.v-subheader
    font-weight: bold

.acs--btn
    font-weight: 800
    letter-spacing: 0.9px

.acs--btn-text
    font-size: 16px
    letter-spacing: 0.5px
    text-transform: none

.acs-notification--update
    font-family: "Nunito", "Roboto", sans-serif
    position: fixed
    display: flex
    align-items: center
    bottom: 0
    left: 50%
    background-color: #FFFFFF
    justify-content: space-between
    color: #8B8B8B
    max-width: 100vw
    z-index: 9999
    padding: 10px 30px
    font-size: 0.8rem
    border-radius: 1px 1px 0 0
    box-shadow: 0 -3px 6px rgba(100, 100, 100, 0.3)
    line-height: 1rem

    button.button
        margin-left: 16px
        color: $primary

    &.button--loading
        color: transparentize($primary, 0.8)

        &:after
            border-color: $primary
            border-top-color: transparent

@media screen and (max-width: $breakpoint-tablet)
    .acs-notification--update
        width: 100%

.v-main.acs-scrollable .v-main__wrap
    height: calc(100vh - 64px)
    overflow-y: auto

html.acs-scrollable
    overflow-y: auto

.acs-main-absolute
    padding: 0 !important

// hack for Safari
.v-btn--disabled
    pointer-events: unset !important
    cursor: default

.acc-icon--center
    margin-top: auto !important
    margin-bottom: auto !important

@keyframes acs-standard-highlight
    0%
        background-color: #fc0

.acs-standard-highlight
    background-color: white
    animation-name: acs-standard-highlight
    animation-duration: 0.2s
    animation-iteration-count: 11
    animation-direction: alternate
    animation-delay: 0.8s

</style>
