<template>
    <v-text-field
        :label="$t('user.alias.alias') + '*'"
        :rules="[$required]"
        v-model="alias"
        name="alias"
        :disabled="disabled"
        :hint="$t('user.alias.help')"
        :autofocus="focus && $vuetify.breakpoint.mdAndUp"
        persistent-hint
        outlined>
        <template #message="{ message }">
            <v-icon small color="warning">$vuetify.icons.aliasWarning</v-icon>
            {{ message }}
        </template>
    </v-text-field>
</template>

<script>
export default {
    name: 'acs-user-alias-field',
    props: {
        value: { type: String },
        disabled: { type: Boolean },
        focus: { type: Boolean }
    },
    computed: {
        alias: {
            get() {
                return this.value || undefined
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
