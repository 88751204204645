export default {
    namespaced: true,

    state: {
        title: '',
        back: null,
        params: {},
        ext: false,
        reload: false
    },

    getters: {
        title: state => state.title,
        ext: state => state.ext,
        back: state => state.back,
        params: state => state.params || {},
        reload: state => state.reload
    },

    mutations: {
        title(state, data) {
            state.params = data.params
            if (data.ext !== undefined) {
                state.ext = data.ext
            }
            if (data.browser !== true) {
                state.title = data.title
            }
            if (data.browser || data.browser === undefined) {
                const t = [data.title]
                if (data.suffix || data.suffix === undefined) {
                    t.push('accessing.app')
                }
                document.title = t.join(' > ')
            }
        },

        back(state, data) {
            state.back = data.back
        },

        reload(state) {
            state.reload = !state.reload
        }
    }
}
