import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        countDone: false
    },

    actions: {
        async homeCount({ state }, { slug, slot_slug }) {
            if (state.countDone) {
                return
            }
            state.countDone = true
            await Vue.prototype.$api.post(`/salepoints/${slug}/count`, {
                slot_slug
            })
        }
    }
}
