<template>
    <div class="acs-card-title">
        <v-card-title v-if="!toolbar" class="py-1" :class="[color, singleLine && 'single-line']">
            <v-btn dark text icon small @click="onClick" v-if="back">
                <v-icon small>$vuetify.icons.baseBack</v-icon>
            </v-btn>
            <div class="title--text">
                <h6 v-if="!badge" :class="colorText">{{ title }}</h6>
                <v-badge v-else :color="badgeColor">
                    <template #badge>
                        {{ badge }}
                    </template>
                    <h3 :class="colorText">{{ title }}</h3>
                </v-badge>
            </div>
            <slot name="buttons"></slot>
            <v-spacer v-if="!hideSpacer" />
            <v-btn dark text icon small @click="onClick" v-if="dismissible">
                <v-icon small>$vuetify.icons.baseClose</v-icon>
            </v-btn>
        </v-card-title>
        <div v-if="!toolbar" class="extension" :class="{ absolute: extensionFloat }">
            <slot name="extension"></slot>
        </div>
        <v-toolbar v-if="toolbar" dense :class="color">
            <v-btn dark text icon small @click="onClick" v-if="back">
                <v-icon small>$vuetify.icons.baseBack</v-icon>
            </v-btn>
            <v-toolbar-title :class="colorText">{{ title }}</v-toolbar-title>
        </v-toolbar>
    </div>
</template>

<script>
export default {
    name: 'acs-base-card-title',
    props: {
        title: { type: String },
        color: { type: String, default: 'primary' },
        colorText: { type: String, default: 'white--text' },
        toolbar: { type: Boolean },
        minHeight: { type: Boolean },
        badge: {},
        badgeColor: { type: String },
        dismissible: { type: Boolean, default: true },
        back: { type: Boolean },
        hideSpacer: { type: Boolean },
        extensionFloat: { type: Boolean },
        singleLine: { type: Boolean }
    },
    methods: {
        onClick(ev) {
            this.$emit('close', ev)
        }
    }
}
</script>

<style lang="scss" scoped>
.extension.absolute {
    z-index: 1;
    position: absolute;
    width: 100%;
}

.acs-card-title {

    .single-line {
        display: flex;
        flex-wrap: nowrap;

        .title--text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
