<template>
    <v-alert
        class="acs-base-empty-list mx-4"
        dense
        outlined
        color="grey"
        :value="value">
        <v-icon size="80" :color="colour">mdi-format-list-checkbox</v-icon>
        <br />
        {{ text }}
    </v-alert>
</template>

<script>
export default {
    name: 'acs-base-empty-list',
    props: {
        value: { type: Boolean },
        text: { type: String },
        colour: { type: String, default: 'grey lighten-2' }
    }
}
</script>

<style lang="sass">
.acs-base-empty-list.v-alert--outlined
    border: none !important
    text-align: center
    padding: 1em

</style>
