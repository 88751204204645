export default {
    namespaced: true,

    state: {
        retry: false
    },

    getters: {
        retry: state => state.retry
    },

    mutations: {
        retry(state, data) {
            state.retry = data
        }
    }
}
