<template>
    <v-form v-model="valid" @submit.prevent.stop="login" :disabled="loading">
        <acs-user-email-field v-model="user.email" also-team :label="$t('home.login')" />
        <acs-user-password-field v-model="user.password" required disable-entropy />
        <v-btn
            type="submit"
            color="primary"
            class="mb-5"
            block
            rounded
            :loading="loading">
            {{ $t('home.submit') }}
        </v-btn>

        <acs-user-password-lost-btn v-model="user.email" tabindex="1" />
        <p>
            {{ $t('home.newUser') }}
            <a :href="`${$config.client}/account/signin?${querystring}`">{{ $t('home.createAccount') }}</a>
        </p>
    </v-form>
</template>

<script>
import AcsUserEmailField from '@/shared/components/user/AcsUserEmailField'
import AcsUserPasswordField from '@/shared/components/user/AcsUserPasswordField'
import AcsUserPasswordLostBtn from '@/components/user/AcsUserPasswordLostBtn'

export default {
    name: 'acs-home-login-form',
    components: {
        AcsUserPasswordField,
        AcsUserEmailField,
        AcsUserPasswordLostBtn
    },
    props: {
        email: { type: Boolean }
    },
    data: () => ({
        valid: false,
        loading: false,
        user: {
            email: null,
            password: null
        }
    }),
    computed: {
        querystring() {
            return Object.entries(this.$route.query).map(o => `${o[0]}=${o[1]}`).join('&')
        }
    },
    methods: {
        isTeamOrTerminal() {
            return this.$emailed(this.user.email) !== true
        },

        login() {
            this.loading = true
            const data = { ...this.user }

            if (this.isTeamOrTerminal()) {
                data.email = `${data.email}.accessing.app`
            }

            return this.$store.dispatch('auth/getToken', { user: data })
                .then(async() => {
                    await this.$store.dispatch('auth/loadUser')

                    // reset useful data store
                    this.$store.commit('prod/reset')

                    if (this.isTeamOrTerminal()) {
                        const params = {
                            team: this.$store.getters['auth/isOnly'](null, 'team'),
                            terminal: this.$store.getters['auth/isOnly'](null, 'terminal')
                        }

                        const salepoints = await this.$store.dispatch('sp/salepoints', {
                            role: params.team ? 'team' : 'terminal'
                        })

                        params.slug = salepoints[0].slug

                        this.$emit('login', params)
                    } else {
                        this.$emit('login', {})
                    }
                })
                .catch(err => {
                    this.loading = false
                    this.$err(err)
                })
        }
    }
}
</script>

<style lang="sass" scoped>
a
    text-decoration: none
    font-weight: bold

p
    font-size: 16px
</style>
