<template>
    <v-dialog v-model="show" max-width="320" persistent>
        <v-card :loading="loading">
            <acs-base-card-title :title="$t('Terminal de paiement')" :dismissible="false" class="mb-5" />

            <v-card outlined class="mb-3 mx-5">
                <v-alert type="error" :value="!!error">
                    {{ error }}
                </v-alert>
                <v-card-text class="px-5">
                    <v-img
                        :src="image"
                        :alt="$t('QR code')"
                        :title="$t('QR code')"
                        min-height="50"
                        @loadstart="start"
                        @load="end(false)"
                        @error="end(true)">
                        <template #placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <acs-base-loader v-model="loading" />
                            </v-row>
                        </template>
                    </v-img>

                    <div v-if="!error" class="d-flex justify-center mt-5">
                        <v-progress-circular indeterminate :size="40" color="grey" />
                    </div>
                </v-card-text>
            </v-card>

            <v-card-actions>
                <v-btn
                    block
                    rounded
                    color="primary"
                    @click="show = false">
                    {{ $t('shared-front:base.cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-pay-qr',

    components: { AcsBaseCardTitle },

    props: {
        value: { type: Boolean },
        slug: { type: String },
        type: { type: String },
        data: { type: Object, default: () => ({}) }
    },

    mixins: [RouteMixin],

    data: () => ({
        loading: false,
        error: false,
        image: null,
        interval: null
    }),

    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },

    watch: {
        show(v) {
            if (!v) {
                window.clearTimeout(this.timeout)
                window.clearInterval(this.interval)
            }
            return v && this.load()
        }
    },

    beforeDestroy() {
        window.clearTimeout(this.timeout)
        window.clearInterval(this.interval)
    },

    methods: {
        start() {
            this.error = false
            this.loading = true
        },

        end(error) {
            this.error = error
            this.loading = false
            window.clearInterval(this.interval)
        },

        load() {
            this.loading = true
            this.error = false
            this.image = null

            window.clearInterval(this.interval)
            window.clearTimeout(this.timeout)

            const data = {
                psp_data: this.data
            }
            return this.$store.dispatch('psp/createTerminalTransaction', { slug: this.slug, type: this.type, data })
                .then(async({ code, qrcode }) => {
                    this.image = qrcode
                    this.timeout = await new Promise(resolve => window.setTimeout(resolve, 6 * 1000))
                    this.interval = window.setInterval(() => this.getTransaction(code), 3 * 1000)
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        getTransaction(trxCode) {
            this.loading = true
            return this.$store.dispatch('psp/getTransactionStatus', { slug: this.slug, psp: 'terminal', trx_id: trxCode })
                .then(async trx => {
                    if (trx.done) {
                        window.clearInterval(this.interval)
                        if (trx.error) {
                            this.error = trx.error.message
                            return
                        }
                        if (trx.data.cart_id) {
                            return this.finalizeCart(trx)
                        }
                        return this.finalizeBill(trx)
                    }
                })
                .catch(err => this.$err(err, { silent: true }))
                .finally(() => (this.loading = false))
        },

        finalizeCart(trx) {
            this.loading = true
            return this.$store.dispatch('order/getFromCart', { slug: this.slug, cartId: trx.data.cart_id })
                .then(async order => {
                    // reset des filtres, la commande est finalisée
                    this.$store.dispatch('prod/saveFilters', {})
                    // intégration dans le cache local
                    this.$store.dispatch('order/addInCache', { slug: this.slug, orderId: order.id, order })
                    // suppression du panier
                    await this.$store.dispatch('cart/remove', { slug: this.slug })
                    // redirection sur la page de succès
                    return this.$router.push({
                        name: this.getContextRouteName('cartsuccessorder'),
                        params: { orderId: order.id }
                    })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        finalizeBill(trx) {
            this.loading = true
            return this.$router.push({
                name: this.getContextRouteName('paysuccess')
            })
        }
    }
}
</script>
