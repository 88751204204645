import Vue from 'vue'

function iOSversion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        // supports iOS 2.0 and later: <https://bit.ly/TJjs1V>
        const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
        return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)]
    }
    return null
}

export default {
    namespaced: true,

    state: {
        iosVersion: iOSversion(),
        logs: []
    },

    getters: {
        logs: state => state.logs,
        iOSMatch: state => version => {
            const v = state.iosVersion
            if (!v || !version) {
                return true
            }
            const ver = version.split('.')
            for (let i = 0; i < ver.length; i += 1) {
                if (v[i] !== undefined && v[i] < Number(ver[i])) {
                    return false
                } else if (v[i] !== undefined && v[i] > Number(ver[i])) {
                    return true
                }
            }
            return true
        }
    },

    mutations: {
        log(state, data) {
            state.logs.push({ ...data })
            // eslint-disable-next-line
            console.log(data.message)
        }
    },

    actions: {
        async log(context, data) {
            // eslint-disable-next-line
            await Vue.prototype.$api.post('/devlog', data).catch(console.error)
        }
    }
}
