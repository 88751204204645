const html = document.getElementsByTagName('html')[0]

export default {
    namespaced: true,

    state: {
        main: [],
        app: [],
        header: [],
        html: []
    },

    getters: {
        main: state => state.main.join(' '),
        app: state => state.app.join(' '),
        header: state => state.header.join(' '),
        html: state => state.html.join(' ')
    },

    mutations: {
        add(state, data) {
            state[data.type].push(data.cls)
            if (data.type === 'html') {
                html.setAttribute('class', state[data.type].join(' '))
            }
        },

        remove(state, data) {
            state[data.type] = state[data.type].filter(cls => cls !== data.cls)
            if (data.type === 'html') {
                html.setAttribute('class', state[data.type].join(' '))
            }
        }
    }
}
