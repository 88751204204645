<template>
    <v-btn
        :icon="!block"
        :block="block"
        :outlined="block"
        rounded
        color="primary"
        target="_blank"
        :href="href">
        <v-icon :left="block">$vuetify.icons.baseHelp</v-icon>
        <template v-if="block">{{ $t('shared-front:docs.btn') }}</template>
    </v-btn>
</template>

<script>
export default {
    name: 'acs-base-doc-btn',
    props: {
        href: { type: String },
        block: { type: Boolean }
    }
}
</script>
