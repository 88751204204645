<template>
    <acs-base-container center>
        <acs-user-account />
    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsUserAccount from '@/components/user/AcsUserAccount.vue'

export default {
    name: 'account',
    components: {
        AcsBaseContainer,
        AcsUserAccount
    },
    props: {
        slug: { type: String, required: true },
        aslot: { type: String, required: true }
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            await this.$store.dispatch('sp/current', { slug: this.slug })
            await this.$store.dispatch('sp/slot', { slug: this.slug, slot: this.aslot })
            await this.$store.dispatch('auth/loadUser')
        }
    }
}
</script>
