export default {
    namespaced: true,

    state: {
        client: null,
        salepointId: null,
        connected: false
    },

    getters: {
        logsid: state => (state.salepointId || '').toString().substr(-4),
        connected: state => state.connected
    },

    mutations: {
        init(state, data) {
            state.client = data.socket
        }
    },

    actions: {
        // ------------------------------
        // config actions

        start({ state, commit, getters }, data) {
            state.client.connect()
            state.salepointId = data.salepoint_id
            this.commit('dbg/log', { message: `open socket in ...${getters.logsid}` })
        },

        stop({ state, getters }, data) {
            if (!state.salepointId) {
                return
            }
            this.commit('dbg/log', { message: `stop socket in ...${getters.logsid}` })
            state.client.emit('unroom', { room: state.salepointId })
            state.client.disconnect()
        },

        // -------------------
        // event actions

        socket_connect({ state, getters }) {
            this.commit('dbg/log', { message: `start socket in ...${getters.logsid}` })
            state.connected = true
            state.client.emit('room', { room: state.salepointId })
        },

        socket_disconnect({ state, getters }, eventData) {
            this.commit('dbg/log', { message: `disconnected socket in ...${getters.logsid}` })
            state.connected = false
        }
    }
}
