<template>
    <v-alert
        class="acs-order-list"
        :value="!!orders.length"
        color="primary"
        outlined>
        <v-row>
            <v-col class="grow" @click="show = !show">{{ $t('orderlist.title') }}</v-col>
            <v-col class="shrink">
                <v-badge color="primary" dot overlap :value="!!prepared">
                    <v-btn v-show="!show" icon outlined x-small text color="primary" @click="show = !show">
                        {{ orders.length }}
                    </v-btn>
                    <v-icon v-show="show" color="primary" @click="show = !show">$vuetify.icons.baseUp</v-icon>
                </v-badge>
            </v-col>
        </v-row>
        <v-row v-show="show">
            <v-col class="pa-0">
                <p class="px-3 mt-3 text-caption">{{ $t('orderlist.help', { days: $config.orders.maxLocalHistoryDays }) }}</p>

                <v-list class="pa-0">
                    <v-list-item
                        v-for="order in orders"
                        :key="order.id"
                        :to="{ name: getContextRouteName('cartsuccessorder'), params: { orderId: order.id} }"
                        class="px-3">
                        <v-list-item-content>
                            <v-list-item-title>{{ order.code }}</v-list-item-title>
                            <v-list-item-subtitle>{{ order.created_at | date(lang, 'LLLL') }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action v-if="order.status === 'prepared'">
                            <v-icon color="primary">$vuetify.icons.ecomSoundOn</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
    </v-alert>
</template>

<script>
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-order-list',

    props: {
        slug: { type: String }
    },

    mixins: [RouteMixin],

    data: () => ({
        show: false,
        loading: false
    }),

    computed: {
        lang() {
            return this.$store.getters['ln/current']
        },

        prepared() {
            return this.orders.filter(order => order.status === 'prepared').length
        },

        orders() {
            return this.$store.getters['order/orders'].slice().sort((a, b) => {
                return b.created_at.localeCompare(a.created_at)
            })
        }
    },

    mounted() {
        this.loading = true
        this.$store.dispatch('order/ordersCache', { slug: this.slug, days: this.$config.orders.maxLocalHistoryDays })
            .catch(err => this.$err(err))
            .finally(() => (this.loading = false))
    }
}
</script>

<style lang="sass">
.acs-order-list
    cursor: pointer
    background-color: white !important

</style>
