<template>
    <v-radio-group v-model="civility" row>
        <v-radio
            v-for="c in civilities"
            :key="c"
            :label="$t(`civilities.${c}`)"
            :value="c" />
    </v-radio-group>
</template>

<script>
export default {
    name: 'acs-user-civility-field',
    props: {
        value: { type: String }
    },
    data: () => ({
        civilities: ['mr', 'ms', 'mx']
    }),
    computed: {
        civility: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    }
}
</script>
