<template>
    <div>
        <v-btn block rounded color="primary" :href="accountUrl">
            {{ $t('user.menu') }}
        </v-btn>
        <v-btn block rounded color="primary" outlined class="mt-5" @click="logout" :loading="loading">
            {{ $t('user.logoutbtn') }}
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'acs-home-logged',
    data: () => ({
        loading: false
    }),
    computed: {
        user() {
            return this.$store.getters['auth/user']
        },
        accountUrl() {
            let url = this.$config.client
            if (
                this.user &&
                this.user.roles &&
                this.user.roles.find(r => r.role === 'team') &&
                !this.user.roles.find(r => r.role === 'admin')) {
                url = this.$config.team
            }

            // on s'assure du slash final, à cause des services workers
            return url.substr(-1) === '/' ? url : `${url}/`
        }
    },
    methods: {
        logout() {
            return this.$store.dispatch('auth/logout')
                .finally(() => (this.loading = false))
        }
    }
}
</script>
