import Vue from 'vue'

const toFrontStructure = tree => {
    if (Array.isArray(tree)) {
        tree.forEach((child, i) => {
            tree[i] = toFrontStructure(child)
        })

        return tree
    }

    tree.children = tree.children || []

    tree.children.forEach((child, i) => {
        tree.children[i] = toFrontStructure(child)
    })
    if (!tree.children.length && tree.products_count) {
        tree.$droppable = false
    }
    return tree
}

const getBreadcrumb = (tree, id, opts = {}) => {
    if (!Array.isArray(tree) && tree.id === id) {
        return [tree]
    }

    const children = Array.isArray(tree) ? tree : tree.children

    for (let i = 0; i < children.length; i += 1) {
        const found = getBreadcrumb(children[i], id, opts)
        if (found && found.length) {
            if (!found.find(n => n.id === children[i].id) && children[i].id !== opts.skip_node_id) {
                found.unshift(children[i])
            }
            return found
        }
    }
    return []
}

const getNode = (tree, id, parentItem, returnParent) => {
    if (!Array.isArray(tree) && tree.id === id) {
        return returnParent ? parentItem : tree
    }

    const children = Array.isArray(tree) ? tree : tree.children

    for (let i = 0; i < children.length; i += 1) {
        const parent = getNode(children[i], id, tree, returnParent)
        if (parent) {
            return parent
        }
    }
    return null
}

const getParent = (tree, id) => {
    return getNode(tree, id, null, true)
}

const categories = {
    state: {
        categories: [],
        categoriesTranslation: null,
        categoryHash: null,
        productsCounts: null,
        productCountHash: null,
        firstLoad: true
    },
    getters: {
        categories: state => state.categories,
        categoriesTranslation: state => state.categoriesTranslation,
        categoriesFlat: state => {
            const flatten = tree => {
                let acc = []
                tree.forEach(node => {
                    acc.push(node)
                    if (node.children) {
                        acc = acc.concat(flatten(node.children))
                    }
                })
                return acc
            }
            return flatten(state.categories)
        },
        categoryIds: state => nodeId => {
            const category = getNode(state.categories, nodeId)
            if (!category.flatten) {
                return [category.id]
            }
            const flatten = (tree, add) => {
                let acc = []
                tree.forEach(node => {
                    if (add || node.id === nodeId) {
                        acc.push(node.id)
                    }
                    if (node.children) {
                        acc = acc.concat(flatten(node.children, add || node.id === nodeId))
                    }
                })
                return acc
            }
            return flatten(state.categories, false)
        },
        findNode: state => nodeId => getNode(state.categories, nodeId),
        findParent: state => nodeId => getParent(state.categories, nodeId),
        breadcrumb: state => (nodeId, opts = {}) => {
            const categories = opts.from_node_id
                ? getNode(state.categories, opts.from_node_id)
                : state.categories

            if (!categories) {
                return []
            }

            return getBreadcrumb(categories, nodeId, opts)
        },
        firstLoad: state => state.firstLoad
    },
    mutations: {
        setProductsCounts(state) {
            if (!state.productsCounts) {
                return
            }
            const fnSet = branch => {
                const children = Array.isArray(branch) ? branch : (branch.children || [])
                Vue.set(branch, 'products_count', state.productsCounts[branch.id]?.all || 0)
                children.forEach(fnSet)
            }
            fnSet(state.categories)

            const fnCalc = branch => {
                if (branch.children?.length) {
                    // reset du count pour les branches avec enfants
                    branch.products_count = 0
                }
                branch.products_count += (branch.children || []).reduce((sum, child) => sum + fnCalc(child), 0)
                return branch.products_count
            }
            fnCalc({ children: state.categories })
        },
        reset(state) {
            state.categories = []
        }
    },
    actions: {
        async categories({ state, commit }, data) {
            state.firstLoad = !state.categories.length

            const hash = `s${data.slug}#t${data.translate}`
            if (state.categories.length && state.categoryHash === hash) {
                return state.categories
            }

            const res = await Vue.prototype.$api
                .get(`/salepoints/${data.slug}/ecom/categories`, {
                    params: {
                        include_translation: data.translate,
                        include_products_count: data.include_products_count,
                        filter_by_role: true,
                        s: data.service ? [data.service] : undefined,
                        t: data.tags || []
                    }
                })
                .catch(err => {
                    if (err.response?.status === 404) {
                        return { data: [] }
                    }
                    throw err
                })

            state.categoryHash = hash
            if (data.lang) {
                state.categoriesTranslation = data.lang
            }
            state.categories = toFrontStructure(res.data)
            commit('setProductsCounts')
            return res.data
        },

        async countProducts({ state, commit }, data) {
            const hash = `s${data.slug}#s${data.service}#t${(data.tags || []).join(':')}#tf${(data.timeframes || []).join(':')}`
            if (state.productsCounts && state.productCountHash === hash) {
                return
            }
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/ecom/products-counts`, {
                params: {
                    s: data.service ? [data.service] : undefined,
                    timeframes: data.timeframes,
                    include_stock_disabled: true,
                    t: data.tags || []
                }
            })
            const counts = res.data.reduce((acc, c) => {
                acc[c.category_id] = c
                return acc
            }, {})

            state.productCountHash = hash
            state.productsCounts = counts

            commit('setProductsCounts')
        }
    }
}

const products = {
    state: {
        tags: [],
        products: [],
        productHash: null,
        filters: Vue.prototype.$storage.getJson('filters', {}),
        translate: !!Vue.prototype.$storage.get('autotranslate', true),
        alertMessages: [],
        navigation: [],
        navigateTo: null
    },
    getters: {
        tags: state => state.tags,
        filters: state => slug => state.filters?.slug === slug ? state.filters : {},
        translate: state => state.translate,
        alertMessages: state => state.alertMessages,
        navigation: state => state.navigation,
        navigateTo: state => state.navigateTo
    },
    mutations: {
        translate(state, data) {
            Vue.prototype.$storage.set('autotranslate', data.active ? '1' : '')
            state.translate = data.active
        },
        resetAlertMessages(state) {
            state.alertMessages = []
        },
        addAlertMessage(state, data) {
            state.alertMessages.push(data)
        },
        navigation(state, { products }) {
            state.navigation = products.map(p => ({
                id: p.id,
                category_id: p.category_id,
                name: p.name
            }))
        },
        navigateTo(state, { direction }) {
            state.navigateTo = direction
        }
    },
    actions: {

        hasRequiredOptions(context, { product }) {
            const required = !!(product.options || []).find(group => {
                if (group.min) {
                    return true
                }
                if (group.advanced) {
                    return (group.items || []).find(item => {
                        return (item.attrs || []).find(attr => {
                            return attr.name === 'required' && ['1', 'on', 'yes', 'true', 'required'].includes(attr.value)
                        })
                    })
                }
            })
            return required
        },

        async products({ state }, data) {
            const date = new Date()
            const dateKey = `${date.getMonth()}-${date.getDate()}-${date.getHours()}`

            const categoryIds = (!data.category_id || Array.isArray(data.category_id) ? data.category_id : [data.category_id]) || []

            const hash = `d${dateKey}#s${data.slug}#c${data.category_id}#t${categoryIds.join(',')}#s${data.service}#t${data.translate}#tg${(data.tags || []).join(':')}#tf${(data.timeframes || []).join(':')}`
            if (state.products.length && state.productHash === hash) {
                return state.products
            }
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/ecom/products`, {
                params: {
                    category_id: categoryIds,
                    s: data.service ? [data.service] : undefined,
                    t: data.tags || [],
                    timeframes: data.timeframes,
                    include_translation: data.translate,
                    include_stock_disabled: true
                }
            })
            state.productHash = hash
            state.products = res.data.map(product => ({
                category_id: data.category_id,
                ...product
            }))
            return state.products
        },

        async getProduct(context, data) {
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/ecom/products/${data.product_id}`, {
                params: {
                    include_translation: data.translate
                }
            })
            if (!res.data.tags) {
                res.data.tags = []
            }
            if (!res.data.services) {
                res.data.services = []
            }
            if (!res.data.options) {
                res.data.options = []
            }
            if (!res.data.timeslots) {
                res.data.timeslots = []
            }
            return res.data
        },

        async open(context, data) {
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/ecom/products/${data.product_id}/open`, {
                params: {
                    include_salepoint_schedules: data.include_salepoint_schedules
                }
            })
            return res.data
        },

        async tags({ state }, data) {
            if (state.tags.length && !data.force) {
                return state.tags
            }
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/ecom/tags`, {
                params: {
                    include_translation: data.translate
                }
            })
            state.tags = res.data
            return state.tags
        },

        saveFilters({ state }, data) {
            state.filters = data
            Vue.prototype.$storage.setJson('filters', state.filters)
        },

        mergeFilters({ state, dispatch }, data) {
            return dispatch('saveFilters', {
                ...state.filters,
                ...data
            })
        },

        async getStock(context, data) {
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/ecom/products/${data.product_id}/stock/remaining`)
            return res.data
        },

        async starProducts(context, { slug }) {
            const res = await Vue.prototype.$api.get(`/salepoints/${slug}/ecom/products-star`, {
                params: {
                    randomize: true
                }
            })
            return res.data
        }
    }
}

export default {
    namespaced: true,

    state: {
        ...products.state,
        ...categories.state
    },

    getters: {
        ...products.getters,
        ...categories.getters
    },

    mutations: {
        ...products.mutations,
        ...categories.mutations
    },

    actions: {
        ...products.actions,
        ...categories.actions
    }
}
