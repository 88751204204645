<template>
    <v-dialog v-model="show" max-width="600" :persistent="loading" scrollable content-class="mx-1">
        <v-card>
            <acs-base-card-title :title="$t('servicetimeframe.select')" :dismissible="!loading" @close="show = false" />

            <v-card-text v-if="show" class="px-0">
                <acs-service-timeframe-calendar
                    ref="timeframeCalendar"
                    v-model="selected"
                    :slug="slug"
                    :service-id="serviceId"
                    :timeframe="timeframe"
                    :product-ids="productIds"
                />
            </v-card-text>

            <v-divider />
            <v-card-actions class="flex-column">
                <p>
                    <v-chip
                        v-for="date in selected"
                        :key="date"
                        small
                        close
                        class="mx-1"
                        @click:close="removeDate(date)">
                        {{ dateFormatter.format(new Date(date)) }}
                    </v-chip>
                </p>
                <v-alert :value="!!errorMessage" dense type="warning">
                    {{ $t(errorMessage, timeframe) }}
                </v-alert>
                <v-alert :value="!!infoMessage" dense outlined type="info" color="primary">
                    {{ $t(infoMessage, { ...timeframe, count: selected.length }) }}
                </v-alert>
                <v-btn
                    :disabled="!valid"
                    block
                    rounded
                    color="primary"
                    @click="submit">
                    {{ $t('servicetimeframe.submit') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'
import AcsServiceTimeframeCalendar from '@/components/service-timeframe/AcsServiceTimeframeCalendar'

import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-service-timeframe-popup',

    components: {
        AcsBaseCardTitle,
        AcsServiceTimeframeCalendar
    },

    mixins: [RouteMixin],

    data: () => ({
        loading: false,
        cartLoaded: false,
        selected: []
    }),

    computed: {
        slug() {
            return this.$store.getters['sp/current'].slug
        },

        serviceId() {
            const forceService = this.$store.getters['ser/timeframesForceService']
            const serviceId = this.$store.getters['cart/cart']?.service_id
            const filters = this.$store.getters['prod/filters'](this.slug)
            return forceService || serviceId || filters?.service
        },

        show: {
            get() {
                return this.$store.getters['ser/timeframesPopup']
            },
            set(v) {
                this.$store.commit('ser/timeframesPopup', {
                    show: v,
                    serviceId: this.$store.getters['ser/timeframesForceService']
                })
            }
        },

        dateFormatter() {
            return new Intl.DateTimeFormat(this.$store.getters['ln/currentWithRegion'], {
                dateStyle: 'medium',
                timeStyle: this.timeframe?.between_frame_duration ? 'short' : undefined
            })
        },

        timeframe() {
            const services = this.$store.getters['ser/services']
            const service = services.find(s => s.id === this.serviceId)
            return service?.timeframe
        },

        errorMessage() {
            if (!this.timeframe) {
                return
            }
            if (this.selected.length && this.selected.length < this.timeframe.nb_frames_per_order_min) {
                return 'servicetimeframe.selectmin'
            }
            if (this.timeframe.nb_frames_per_order_max && this.selected.length > this.timeframe.nb_frames_per_order_max) {
                return 'servicetimeframe.selectmax'
            }
            return ''
        },

        infoMessage() {
            if (!this.timeframe) {
                return
            }
            if (this.timeframe.nb_frames_per_order_max > 1) {
                return 'servicetimeframe.selected'
            }
            return ''
        },

        valid() {
            if (!this.timeframe) {
                return false
            }
            if (this.selected.length < this.timeframe.nb_frames_per_order_min) {
                return false
            }
            if (this.timeframe.nb_frames_per_order_max && this.selected.length > this.timeframe.nb_frames_per_order_max) {
                return false
            }
            return true
        },

        productIds() {
            const ids = this.$store.getters['cart/products'].map(cp => cp.product.id)
            if (this.$route.params.product) {
                ids.push(this.$route.params.product)
            }
            return ids
        }
    },

    watch: {
        serviceId() {
            if (!this.cartLoaded) {
                // au 1er chargement du cart, le service passe de vide à qqch
                // mais dans ce cas, on ne veut pas que la popup s'affiche
                this.cartLoaded = true
                return
            }
            this.selected = []
            this.show = !!this.timeframe
        },

        async show(v) {
            if (v && this.$store.getters['cart/cart']) {
                // on set le selected en fonction de ce qui est déjà mis dans le panier
                this.selected = this.$store.getters['cart/cart'].timeframes?.slice() || []

                // et on set le current date du calendrier sur cette base
                await this.$nextTick()
                if (this.selected.length) {
                    this.$refs.timeframeCalendar.setDate(this.selected[0].substring(0, 10))
                }
            }
        }
    },

    methods: {
        submit() {
            this.loading = true
            // on s'assure de la présence d'un panier
            return this.$store
                .dispatch('cart/update', {
                    slug: this.slug,
                    timeframes: this.selected,
                    service_id: this.serviceId,
                    createIfNotExists: true
                })
                .then(() => this.$store.dispatch('prod/mergeFilters', {
                    slug: this.slug,
                    service: this.serviceId,
                    timeframes: this.selected
                }))
                .then(res => {
                    this.$success(res, { message: 'servicetimeframe.saved' })
                    return this.$store.getters['ser/timeframesOnSelectedFn'] && this.$store.getters['ser/timeframesOnSelectedFn']()
                })
                .then(() => {
                    this.$store.commit('ser/timeframesSaved')
                    this.show = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        removeDate(date) {
            const index = this.selected.findIndex(s => s === date)
            if (index !== -1) {
                this.selected.splice(index, 1)
            }
        }
    }
}
</script>
