<template>
    <div class="acs-menu--item mb-2" :class="classes">
        <v-list-item class="acs-category--item py-5" :class="{ grey: !category.products_count }" :to="to">
            <v-list-item-content>
                <v-list-item-title :class="{ [`${textColour}--text`]: true }">{{ category | quickTranslate('name', translate) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action :class="{ [`${textColour}--text`]: true }">
                {{ category.products_count }}
            </v-list-item-action>
            <v-list-item-action>
                <v-icon :color="textColour">$vuetify.icons.baseRight</v-icon>
            </v-list-item-action>
        </v-list-item>
    </div>
</template>

<script>
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-product-category-list-item',
    props: {
        slug: { type: String },
        category: { type: Object },
        translate: { type: Boolean }
    },
    mixins: [RouteMixin],
    computed: {
        to() {
            if (this.category.children.length) {
                return { name: this.getContextRouteName('productcategory'), params: { category: this.category.id } }
            }
            return { name: this.getContextRouteName('productlist'), params: { category: this.category.id } }
        },

        classes() {
            if (this.category.is_team) {
                return 'grey lighten-2 '
            }
            return 'primary'
        },

        textColour() {
            if (this.category.is_team) {
                return 'black'
            }
            return 'white'
        }
    }
}
</script>

<style lang="sass">
.acs-category--container
    cursor: pointer

    .v-card__title
        min-height: 80px
        font-size: 14px
        text-transform: uppercase
        font-weight: bold

.acs-category--item
    border-radius: 4px

    .v-list-item__title
        text-transform: uppercase
        font-weight: 800
        font-size: 14px
        line-height: 16px

</style>
