<template>
    <v-card :max-width="maxWidth" flat color="transparent">

        <acs-cart-alert class="mx-2" />
        <acs-order-list class="mx-2" :slug="salepoint.slug" />

        <v-container class="pa-3 pt-0">
            <v-row>
                <v-col cols="6" class="pa-2">
                    <acs-slot-btn
                        :to="{ name: 'menulisthome' }"
                        :label="$t('salepointtable.menu')"
                        :service="{
                            name: salepoint.service_menu_name,
                            name_translated: salepoint.service_menu_name_translated
                        }"
                        icon="$vuetify.icons.tableMenu" />
                </v-col>
                <v-col cols="6" v-if="!payIgnore" class="pa-2">
                    <acs-slot-btn
                        :to="{ name: getContextRouteName('pay') }"
                        :label="$t('pay.btn')"
                        :service="payService"
                        color="green lighten-5 green--text"
                        icon="$vuetify.icons.payBill" />
                </v-col>
                <v-col cols="6" class="pa-2">
                    <acs-base-qrcode-btn @input="onScan">
                        <template #button="{ on }">
                            <acs-slot-btn
                                v-on="on"
                                :label="$t('salepointtable.newqr')"
                                icon="$vuetify.icons.baseQr" />
                        </template>
                    </acs-base-qrcode-btn>
                </v-col>
            </v-row>
        </v-container>
        <div class="d-flex">
            <acs-slot-survey class="mt-3 mx-2" :slug="salepoint.slug" :close="false" @survey="doSurvey" />
        </div>

        <acs-salepoint-socials class="d-flex flex-column" />
    </v-card>
</template>

<script>
/* eslint-disable eqeqeq */
import AcsBaseQrcodeBtn from '@/shared/components/base/AcsBaseQrcodeBtn'
import AcsSlotBtn from '@/components/slot/AcsSlotBtn'
import AcsSlotSurvey from '@/components/slot/AcsSlotSurvey'
import AcsSalepointSocials from '@/components/salepoint/AcsSalepointSocials'
import AcsCartAlert from '@/components/cart/AcsCartAlert'
import AcsOrderList from '@/components/order/AcsOrderList'
import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'acs-salepoint-home',
    props: {
        maxWidth: { type: [String, Number] }
    },
    mixins: [RouteMixin],
    components: {
        AcsBaseQrcodeBtn,
        AcsSlotBtn,
        AcsSlotSurvey,
        AcsSalepointSocials,
        AcsCartAlert,
        AcsOrderList
    },
    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        },
        closed() {
            return this.salepoint.schedules && !this.salepoint.schedules.currently_open
        },
        payService() {
            return this.salepoint.services.find(s => s.type === 'pay')
        },
        payIgnore() {
            const s = this.payService
            return !s || s.disabled || s.disabled_home
        }
    },
    methods: {
        async onScan(url) {
            window.location.href = url
        },

        doSurvey() {
            this.$router.push({ name: 'surveyhome' })
        }
    }
}
</script>
