import Vue from 'vue'
import { marked, Renderer } from 'marked'
import DOMPurify from 'dompurify'

const renderer = new Renderer({
    sanitize: true
})

const options = {
    breaks: true,
    gfm: true
}

marked.use({
    renderer: {
        link: (href, title, text) => {
            const localLink = href.startsWith(`${location.protocol}//${location.hostname}`)
            const html = renderer.link(href, title, text)
            // need [trust] modifier to work
            return localLink ? html : html.replace(/^<a /, '<a target="_blank" rel="noreferrer noopener nofollow" ')
        }
    }
})

const bind = function(el, binding) {
    const cls = (el.className || '').replace(' markdown', '')
    const html = binding.modifiers.inline
        ? marked.parseInline(binding.value || '', options)
        : marked.parse(binding.value || '', options)

    el.className = `${cls} markdown`
    el.innerHTML = !binding.modifiers.trust
        ? DOMPurify.sanitize(html)
        : html
}

Vue.directive('marked', {
    bind: bind,
    update: bind
})

Vue.filter('marked', value => DOMPurify.sanitize(marked.parse(value || '', options)))
