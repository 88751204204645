import Vue from 'vue'

export default {
    namespaced: true,

    state: {
        ticketPrint: false
    },

    getters: {
        ticketPrint: state => state.ticketPrint
    },

    mutations: {
        ticketPrint(state, data) {
            state.ticketPrint = data.show
        }
    },

    actions: {
        async ticketXLastDate(context, data) {
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/teams/${data.team_id}/ticketx/last`)
            return res.data.end_date ? new Date(res.data.end_date) : null
        },

        async saveTicketXLastDate(context, data) {
            return Vue.prototype.$api.put(`/salepoints/${data.slug}/teams/${data.team_id}/ticketx/last`, {
                date: data.date.toISOString()
            })
        }
    }
}
