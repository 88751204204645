import Vue from 'vue'

const StorageLocal = {

    set(key, value) {
        this.storage().setItem(key, value)
    },

    setJson(key, value) {
        this.storage().setItem(key, JSON.stringify(value || undefined))
    },

    get(key, defaultValue) {
        return this.storage().getItem(key) || defaultValue
    },

    getJson(key, defaultValue) {
        let data
        try {
            data = JSON.parse(this.storage().getItem(key)) || defaultValue
        } catch (e) {
            data = defaultValue
        }
        return data
    },

    remove(key) {
        this.storage().removeItem(key)
    },

    available() {
        const mod = '___voilabacsstorage___'
        try {
            this.set(mod, mod)
            this.remove(mod)
            return true
        } catch (e) {
            return false
        }
    },

    storage() {
        return window.localStorage
    }
}

const StorageSession = {

    set(key, value) {
        this.storage().setItem(key, value)
    },

    setJson(key, value) {
        this.storage().setItem(key, JSON.stringify(value || undefined))
    },

    get(key, defaultValue) {
        return this.storage().getItem(key) || defaultValue
    },

    getJson(key, defaultValue) {
        let data
        try {
            data = JSON.parse(this.storage().getItem(key)) || defaultValue
        } catch (e) {
            data = defaultValue
        }
        return data
    },

    remove(key) {
        this.storage().removeItem(key)
    },

    available() {
        const mod = '___voilabacsstorage___'
        try {
            this.set(mod, mod)
            this.remove(mod)
            return true
        } catch (e) {
            return false
        }
    },

    storage() {
        return window.sessionStorage
    }
}

const MockStorage = {
    _storage: {},

    set(key, value) {
        this.storage()[key] = value
    },

    get(key) {
        return this.storage()[key]
    },

    remove(key) {
        this.storage()[key] = undefined
    },

    available() {
        return false
    },

    storage() {
        return this._storage
    }
}

Vue.prototype.$storage = StorageLocal.available() ? StorageLocal : MockStorage
Vue.prototype.$sessionStorage = StorageSession.available() ? StorageSession : MockStorage

export default StorageLocal
