export default {
    computed: {
        routehash() {
            return this.$route.hash
        }
    },

    methods: {
        popupHash() {
            return '#popup'
        },

        isPopupHash(hash) {
            return hash === this.popupHash()
        },

        endPopupHash() {
            const noHashURL = window.location.href.replace(/#.*$/, '')
            window.history.replaceState('', window.document.title, noHashURL)
        },

        initPopupHash() {
            // set d'un hash qui permet de gérer le back
            this.$router.push({ hash: this.popupHash() })
        }
    }
}
