<template>
    <debug-web-logger :disabled="!env">
        <template #activator="{ on, attrs }">
            <v-btn
                v-if="env && env.env"
                :color="color"
                fab
                dark
                bottom
                left
                fixed
                v-on="on"
                v-bind="attrs">
                {{ env.text || env.env }}
            </v-btn>
        </template>
    </debug-web-logger>
</template>

<script>
import DebugWebLogger from '@/shared/components/debug/DebugWebLogger'

export default {
    name: 'acs-base-env-marker',
    components: { DebugWebLogger },
    computed: {
        demo() {
            return this.$store.getters['sp/current'].demo
        },

        env() {
            if (this.demo) {
                return {
                    env: 'demoprod',
                    text: 'demo'
                }
            }
            const host = `//${window.location.host}`
            const hmap = [
                { s: '//127.0.0.1', env: this.$config.envMarker && 'local' },
                { s: '//test', env: 'test' },
                { s: '//staging', env: 'demo' },
                { s: '//', env: '' }
            ]
            return hmap.find(h => host.indexOf(h.s) !== -1)
        },

        color() {
            if (!this.env) {
                return 'white'
            }
            const env = this.env.env
            if (env === 'local') {
                return 'red'
            } else if (env === 'test') {
                return 'orange'
            } else if (env === 'demo') {
                return 'green'
            } else if (env === 'demoprod') {
                return 'primary'
            }
            return 'white'
        }
    }
}
</script>
