<template>
    <v-alert v-if="firstError" type="warning">
        {{ $t(`cart.errors.${firstError.type}`, details) }}
    </v-alert>
</template>

<script>
export default {
    name: 'acs-cart-product-error',
    props: {
        errors: { type: Array }
    },
    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        },
        firstError() {
            // si l'erreur n'a pas d'ID, c'est qu'elle ne correspond pas à
            // une ligne de produit, mais au panier dans son ensemble. On
            // récupère la 1ère erreur du panier global
            return this.errors.find(error => !error.id)
        },
        details() {
            if (!this.firstError) {
                return {}
            }
            return {
                currency: this.salepoint.currency_users.toUpperCase(),
                ...(this.firstError.details || {})
            }
        }
    }
}
</script>
