<template>
    <v-card :max-width="maxWidth" flat>
        <v-form v-model="valid" @submit.stop.prevent="submit">
            <v-card-text>
                <v-subheader>{{ $t('fillin.lang') }}</v-subheader>
                <acs-user-lang-field v-model="user.ln" />
                <v-subheader>{{ $t('fillin.auth') }}</v-subheader>
                <acs-user-email-field v-model="user.email" disabled />
                <acs-user-password-change :email="user.email" />
                <acs-user-remember-field v-model="user.remember" />
                <acs-user-alias-field v-model="user.alias" />
                <v-subheader>{{ $t('fillin.identity') }}</v-subheader>
                <acs-user-civility-field v-model="user.civility" />
                <acs-user-name-field v-model="user.lastname" :label="$t('fillin.lastname')" name="lastname" />
                <acs-user-name-field v-model="user.firstname" :label="$t('fillin.firstname')" name="firstname" />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    :disabled="!valid"
                    :loading="loading"
                    type="submit"
                    color="primary">
                    {{ $t('fillin.validate') }}
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import AcsUserEmailField from '@/shared/components/user/AcsUserEmailField'
import AcsUserPasswordChange from '@/components/user/AcsUserPasswordChange'
import AcsUserAliasField from '@/shared/components/user/AcsUserAliasField'
import AcsUserCivilityField from '@/components/user/AcsUserCivilityField'
import AcsUserNameField from '@/shared/components/user/AcsUserNameField'
import AcsUserRememberField from '@/components/user/AcsUserRememberField'
import AcsUserLangField from '@/shared/components/user/AcsUserLangField'

export default {
    name: 'acs-user-account',
    props: {
        maxWidth: { type: [String, Number] }
    },
    components: {
        AcsUserLangField,
        AcsUserEmailField,
        AcsUserPasswordChange,
        AcsUserAliasField,
        AcsUserCivilityField,
        AcsUserNameField,
        AcsUserRememberField
    },
    data: () => ({
        valid: false,
        loading: false,
        user: {}
    }),
    computed: {
        current() {
            return this.$store.getters['auth/user']
        }
    },
    mounted() {
        this.user = { ...this.current }
    },
    methods: {
        submit() {
            this.loading = true
            this.$store.dispatch('auth/saveUser', { user: this.user })
                .then(() => this.$store.commit('msg/show', { message: this.$i18n.t('user.saved') }))
                .finally(() => this.loading = false)
        }
    }
}
</script>
