import SalepointTable from '@/views/SalepointTable.vue'
import Pay from '@/views/Pay'
import PaySuccess from '@/views/PaySuccess'
import PayFinish from '@/views/PayFinish'
import AcsSalepointWelcome from '@/components/salepoint/AcsSalepointWelcome'

export default [
    // routes depuis le service (table) d'un salepoint
    {
        path: '/:slug/slots/:aslot',
        name: 'salepointslot',
        component: SalepointTable,
        props: true,
        meta: {
            title: 'salepointhome.title',
            titleCmp: AcsSalepointWelcome,
            ext: true
        }
    },
    {
        path: '/:slug/slots/:aslot/pay',
        name: 'pay',
        component: Pay,
        props: true,
        meta: {
            title: 'pay.menu',
            back: 'salepointslot'
        }
    },
    {
        path: '/:slug/slots/:aslot/paysuccess',
        name: 'paysuccess',
        component: PaySuccess,
        props: true,
        meta: {
            ignoreCount: true,
            title: 'paysuccess.menu'
        }
    },
    {
        path: '/:slug/slots/:aslot/payfinish',
        name: 'payfinish',
        component: PayFinish,
        props: true,
        meta: {
            ignoreCount: true,
            title: 'paysuccess.surveybtn',
            back: 'paysuccess'
        }
    }
]
