import Vue from 'vue'

const storekey = slug => `acc-orders-${slug}`

export default {
    namespaced: true,

    state: {
        orders: []
    },

    getters: {
        orders: state => state.orders
    },

    mutations: {
        update(state, { orderId, status }) {
            const order = state.orders.find(o => o.id === orderId)
            if (!order) {
                return
            }
            order.status = status
        }
    },

    actions: {
        async ordersCache({ dispatch, state }, { slug, days, strict }) {
            if (strict && state.orders.length) {
                return state.orders
            }
            const key = storekey(slug)
            // récupération des commandes depuis le localstorage. Pour le moment, tout
            // est fait en mode non-connecté
            const orders = Vue.prototype.$storage.getJson(key, [])
            return Promise
                .all(orders.map(orderId => dispatch('getPublicData', { slug, id: orderId }).catch(err => {
                    if (err?.response?.status === 404) {
                        // les commandes qui n'existent pas (bugs ou travail en local)
                        // doivent être retirée du local storage
                        return { id: orderId, created_at: '1970-01-01' }
                    }
                    throw err
                })))
                .then(orders => {
                    // on enlève du cache les commandes trop vieilles (1 jour)
                    const pivot = new Date(Date.now() - (1000 * 60 * 60 * 24 * days))
                    const unexpired = orders.filter(order => pivot < new Date(order.created_at))
                    if (unexpired.length !== orders.length) {
                        Vue.prototype.$storage.setJson(key, unexpired.map(order => order.id))
                    }
                    state.orders = unexpired
                    return state.orders
                })
        },

        async addInCache({ state }, { slug, orderId, order }) {
            const key = storekey(slug)
            const orders = Vue.prototype.$storage.getJson(key, [])

            if (!orders.includes(orderId)) {
                orders.push(orderId)

                state.orders.push(order)
                Vue.prototype.$storage.setJson(key, orders)
            }
        },

        async getPublicData(context, data) {
            const res = await Vue.prototype.$api.get(`/salepoints/${data.slug}/ecom/orders/${data.id}/public`)
            return res.data
        },

        async getFromCart(context, data) {
            const res = await Vue.prototype.$api.get(`/me/salepoints/${data.slug}/order-from-cart-id`, {
                params: {
                    cart_id: data.cartId
                }
            })
            return res.data
        },

        async getICSLinks(context, { slug, id }) {
            const res = await Vue.prototype.$api.get(`/salepoints/${slug}/ecom/orders/${id}/timeframes/calendars-links`)
            return res.data
        }
    }
}
