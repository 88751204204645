<template>
    <div class="px-3 mb-4">
        <v-chip
            v-for="(tag, i) in tags"
            :key="i"
            small
            class="mr-1 mb-1"
            @click="toggle(tag.tag)"
            :color="isSelected(tag.tag) ? 'primary' : null"
            :disabled="disabled">
            {{ tag | quickTranslate('tag', translate) }}
        </v-chip>
        <v-chip
            v-if="allTags.length > defaultTagsNumber"
            small
            class="mr-1 mb-1"
            @click="more = !more"
            :color="!more && hasMoreSelected ? 'primary' : null">
            <strong><v-icon small>{{ more ? '$vuetify.icons.ecomTagLess' : '$vuetify.icons.ecomTagMore' }}</v-icon></strong>
        </v-chip>
        <p class="text-caption px-1" v-if="!allTags.length">{{ $t('productfilter.notag') }}</p>
    </div>
</template>

<script>
export default {
    name: 'acs-product-tags-selector',
    props: {
        slug: { type: String },
        value: { type: Array },
        disabled: { type: Boolean },
        translate: { type: Boolean }
    },
    data: () => ({
        loading: false,
        more: false,
        defaultTagsNumber: 5
    }),
    computed: {
        allTags() {
            return this.$store.getters['prod/tags']
        },

        tagIds() {
            return this.$store.getters['prod/tags'].map(tag => tag.tag)
        },

        tags() {
            return this.more ? this.allTags : this.allTags.slice(0, this.defaultTagsNumber)
        },

        selected: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },

        hasMoreSelected() {
            return !!this.selected
                .map(tag => this.tagIds.indexOf(tag))
                .filter(index => index >= this.defaultTagsNumber)
                .length
        }
    },
    watch: {
        translate() {
            this.load(true)
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load(force) {
            this.loading = true
            return this.$store.dispatch('prod/tags', { slug: this.slug, translate: this.translate, force: force })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        isSelected(tag) {
            return this.selected.indexOf(tag) !== -1
        },

        toggle(tag) {
            const index = this.selected.indexOf(tag)
            if (index === -1) {
                this.selected.push(tag)
            } else {
                this.selected.splice(index, 1)
            }
        },

        collapse() {
            this.more = false
        }
    }
}
</script>
