import Vue from 'vue'
import moment from 'moment'
import '@/shared/plugins/filters'

Vue.filter('quickTranslate', (value, prop, active) => {
    const v = value || {}
    if (!active) {
        return v[prop]
    }
    return v[`${prop}_translated`] || v[prop]
})

const momentCache = { base: null, unsorted: {}, sorted: {} }
const momentBase = () => {
    if (!momentCache.base) {
        momentCache.base = moment.localeData('en').weekdaysMin().map(d => d.toLowerCase())
    }
    return momentCache.base
}
const momentLn = (localeSort) => {
    const l = moment.locale()
    const s = localeSort ? 'sorted' : 'unsorted'
    if (!momentCache[s][l]) {
        momentCache[s][l] = moment.weekdays(localeSort)
    }
    return momentCache[s][l]
}

/**
 * Retourne le nom du jour dans la bonne langue en fonction du nom short anglais
 */
Vue.filter('dayname', (value, add) => {
    const base = momentBase()
    const l = momentLn()
    let index = base.findIndex(d => d === value) + (add || 0)
    if (index > base.length - 1) {
        index = index - base.length
    }
    const res = l[index] || ''
    return res.charAt(0).toUpperCase() + res.slice(1)
})

/**
 * Trie le tableau des horaires en fonction de la locale courante, en se basant
 * sur l'anglais d'abord, puis sur la langue actuelle ensuite
 */
Vue.filter('sortdays', value => {
    const base = momentBase()
    // construction d'un lien entre le jour anglais et le jour dans la
    // langue courante
    let i = 0
    const hash = momentLn().reduce((acc, day) => {
        acc[day] = base[i]
        i += 1
        return acc
    }, {})
    // on fait le lien entre le jour de la langue courante et l'index en anglais
    i = 0
    const data = momentLn(true).reduce((acc, day) => {
        acc[hash[day]] = i
        i += 1
        return acc
    }, {})

    // restitution des jours, dans l'ordre de la locale courante
    return value.slice().sort((a, b) => {
        if (data[a.name] < data[b.name]) {
            return -1
        }
        return data[a.name] > data[b.name] ? 1 : 0
    })
})
