<template>
    <div :class="containerClass">
        <v-img
            :src="salepoint.links_bg ? `${salepoint.links_bg}&w=${bgWidth}&h=${bgHeight}&f=cover` : undefined"
            :height="bgFullHeight"
            width="100%"
            :style="{ backgroundColor: salepoint.colour }"
            gradient="to bottom, black, 0.5%, transparent">
            <template>
                <v-row class="fill-height ma-0 flex-column" justify="center" align="center">
                    <p class="acs-salepoint-info--name white--text px-5 ma-0">{{ salepoint.name }}</p>
                    <v-btn :to="{ name: 'salepointhome' }" rounded color="primary" class="acs-salepoint-info--btn my-5" :min-width="btnWidth">{{ $t('Services') }}</v-btn>
                </v-row>
            </template>
            <template #placeholder v-if="salepoint.links_bg">
                <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-3" />
                </v-row>
            </template>
        </v-img>

        <v-container class="mb-5 pa-0" fluid :class="{ 'pt-5': !$vuetify.breakpoint.smAndDown }">
            <v-row justify="center" align="center" class="acs-salepoint-info--logo-block" :class="logoClass">
                <v-col cols="4" class="pa-0">
                    <v-img
                        v-if="salepoint.links_logo"
                        :src="`${salepoint.links_logo}&w=${logoWidth}&h=${logoHeight}&f=contain&b=white`"
                        class="acs-salepoint-info--logo my-5"
                        :max-width="logoWidth">
                        <template #placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-3" />
                            </v-row>
                        </template>
                    </v-img>
                </v-col>
                <v-col :cols="logoCols" class="pa-0">
                    <div
                        v-if="salepoint.welcome_message"
                        v-marked="message"
                        class="acs-salepoint-info--message pa-5"
                        :style="messageClass"></div>
                </v-col>
            </v-row>
            <v-row v-if="products.length" class="ma-0">
                <v-carousel hide-delimiters :height="productsHeight" class="acs-salepoint-info--products my-5">
                    <v-carousel-item
                        v-for="product in products"
                        :key="product.id"
                        :src="`${product.links_image}&w=${productsWidth}&h=${productsHeight}&f=cover`"
                        :to="{ name: 'productformhome', params: { category: product.category_id, product: product.id} }"
                        eager>
                        <v-img :src="`${product.links_image}&w=${productsWidth}&h=${productsHeight}&f=cover`" height="100%" eager>
                            <template #placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-3" />
                                </v-row>
                            </template>
                        </v-img>
                    </v-carousel-item>
                </v-carousel>
            </v-row>
        </v-container>

        <div class="d-flex justify-center acs-salepoint-info--footer pb-5" :class="footerClass" :style="{ backgroundColor: salepoint.colour }">
            <acs-salepoint-info-address
                :salepoint="salepoint"
                class="text-center py-5"
                :class="addressClass"
                :style="addressStyle"
                :dark="$vuetify.breakpoint.smAndDown" />

            <acs-salepoint-info-schedule-special
                v-if="open && !reset && salepoint.schedules && salepoint.schedules.special.length"
                :colour="salepoint.colour"
                :schedules="salepoint.schedules.special"
                :day="open.item && open.item.name"
                :timeslot="open.timeslotIndex"
                :expand-timeslots-horizontally="$vuetify.breakpoint.mdAndUp"
                class="pb-3 px-3"
                show-open-state />

            <acs-salepoint-info-schedule-opening
                v-if="open && !reset && salepoint.schedules"
                :colour="salepoint.colour"
                :schedules="salepoint.schedules.openings"
                :day="open.item && open.item.name"
                :timeslot="open.timeslotIndex"
                :expand-timeslots-horizontally="$vuetify.breakpoint.mdAndUp"
                class="px-3" />

            <acs-salepoint-info-schedule-special
                v-else
                :colour="salepoint.colour"
                :title="$t('salepointschedules.title')"
                :schedules="[{ name: $t('salepointschedules.all'), timeslots: [], open: true }]"
                class="px-3"
                force-selected />
        </div>
    </div>
</template>

<script>
import AcsSalepointInfoAddress from '@/components/salepoint-info/AcsSalepointInfoAddress'
import AcsSalepointInfoScheduleOpening from '@/components/salepoint-info/AcsSalepointInfoScheduleOpening'
import AcsSalepointInfoScheduleSpecial from '@/components/salepoint-info/AcsSalepointInfoScheduleSpecial'

export default {
    name: 'salepoint-info',

    components: {
        AcsSalepointInfoAddress,
        AcsSalepointInfoScheduleOpening,
        AcsSalepointInfoScheduleSpecial
    },

    props: {
        slug: { type: String }
    },

    data: () => ({
        reset: false,
        loading: true,
        open: null,
        message: null,
        products: []
    }),

    computed: {
        salepoint() {
            return this.$store.getters['sp/current']
        },

        lang() {
            return this.$store.getters['ln/current']
        },

        containerClass() {
            return this.$vuetify.breakpoint.smAndDown
                ? 'd-flex align-center flex-column'
                : ''
        },

        bgWidth() {
            return this.$vuetify.breakpoint.smAndDown
                ? 800
                : 1000
        },

        bgHeight() {
            return this.$vuetify.breakpoint.smAndDown
                ? 800
                : 700
        },

        bgFullHeight() {
            const h = window.screen.height
            const ratio = 1

            return this.$vuetify.breakpoint.smAndDown
                ? h * ratio
                : this.bgHeight
        },

        logoWidth() {
            return 150
        },

        logoHeight() {
            return 150
        },

        logoClass() {
            return this.$vuetify.breakpoint.smAndDown
                ? 'flex-column'
                : ''
        },

        logoCols() {
            return this.$vuetify.breakpoint.smAndDown
                ? 12
                : 8
        },

        productsWidth() {
            return this.$vuetify.breakpoint.smAndDown
                ? 500
                : 800
        },

        productsHeight() {
            return this.$vuetify.breakpoint.smAndDown
                ? 260
                : 520
        },

        btnWidth() {
            return 250
        },

        addressClass() {
            return this.$vuetify.breakpoint.smAndDown
                ? ''
                : 'white--text'
        },

        addressStyle() {
            return this.$vuetify.breakpoint.smAndDown
                ? { backgroundColor: 'white' }
                : { backgroundColor: this.salepoint.colour }
        },

        messageClass() {
            return this.$vuetify.breakpoint.smAndDown
                ? { borderColor: this.salepoint.colour, borderBottomWidth: 0 }
                : { borderColor: this.salepoint.colour }
        },

        footerClass() {
            return this.$vuetify.breakpoint.smAndDown
                ? 'flex-column fullwidth'
                : ''
        }
    },

    watch: {
        lang() {
            this.reset = true
            return Promise.all([
                this.$nextTick(() => (this.reset = false)),
                this.setMessage()
            ])
        }
    },

    mounted() {
        this.$store.commit('cls/add', { type: 'app', cls: 'acs-main-absolute' })
        this.loading = true
        return this.$store.dispatch('sp/current', { slug: this.slug })
            .then(() => Promise.all([
                this.$store.dispatch('sp/open', { slug: this.slug }),
                this.$store.dispatch('prod/starProducts', { slug: this.slug }),
                this.setMessage()
            ]))
            .then(([open, products]) => {
                this.open = open
                this.products = products
                this.$store.commit('prod/navigation', { products: products })
                this.$store.commit('top/title', { title: this.salepoint.name, browser: true })
            })
            .catch(err => this.$err(err))
            .finally(() => (this.loading = false))
    },

    methods: {
        setMessage() {
            if (!this.salepoint.welcome_message) {
                return
            }
            this.loading = true
            return this.$store.dispatch('sp/translateWelcomeMessage', { slug: this.slug })
                .then(msg => {
                    this.message = msg || this.salepoint.welcome_message
                    this.loading = false
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass" scoped>
.acs-salepoint-info--logo-block
    max-width: 1000px
    margin: auto

.acs-salepoint-info--name
    font-weight: bold
    font-size: 2.2em
    text-shadow: 1px 2px 4px black
    text-align: center

.acs-salepoint-info--message
    width: 100%
    border-width: 5px
    border-style: solid
    border-left: 0
    border-right: 0

.acs-salepoint-info--btn
    padding: 28px !important

.acs-salepoint-info--products
    margin: auto
    max-width: 800px

.acs-salepoint-info--footer
    gap: 4em

    &.fullwidth
        gap: 0
        width: 100%
</style>
