<template>
    <v-select
        class="acs-ln-selector"
        :items="available"
        v-model="current"
        single-line
        hide-details
        :dense="!maximized"
        :flat="!maximized"
        :outlined="maximized"
        :disabled="loading">
        <template #item="{ item }" v-if="maximized">
            {{ $t(`shared-front:ln.${item.toLowerCase()}`) }} ({{ item }})
        </template>
        <template #selection="{ item }" v-if="maximized">
            {{ $t(`shared-front:ln.${item.toLowerCase()}`) }} ({{ item }})
        </template>
    </v-select>
</template>

<script>
export default {
    name: 'acs-base-ln-selector',
    props: {
        value: { type: String, default: undefined },
        maximized: { type: Boolean }
    },
    data: () => ({
        loading: false
    }),
    computed: {
        current: {
            get() {
                if (this.value !== undefined) {
                    return this.value && this.value.toUpperCase()
                }
                return this.$store.getters['ln/current'].toUpperCase()
            },
            set(v) {
                if (this.value !== undefined) {
                    return this.$emit('input', v && v.toLowerCase())
                }
                this.loading = true
                this.$store.dispatch('ln/current', { current: v })
                    .finally(() => (this.loading = false))
            }
        },

        available() {
            return this.$store.getters['ln/available'].map(ln => ln.toUpperCase())
        }
    }
}
</script>

<style lang="sass">
.acs-ln-selector.v-input--dense
    max-width: 80px !important

    .v-input__append-inner
        padding-left: 0 !important

</style>
