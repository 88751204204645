<template>
    <v-bottom-sheet v-model="show" max-width="700" :disabled="disabled" scrollable>
        <template #activator="{ on }">
            <acs-slot-btn
                v-on="on"
                :disabled="disabled"
                :service="service"
                :label="$t('salepointtable.bill.btn')"
                icon="$vuetify.icons.waitBill" />
        </template>
        <v-card class="pb-5">
            <v-card-title class="flex-nowrap">
                <v-icon color="primary" size="34" class="pr-5">mdi-alert-circle-outline</v-icon>
                <div class="pr-5 flex-grow-1">{{ $t('salepointtable.bill.btn') }}</div>
                <v-icon @click="show = false" class="pl-5">mdi-close</v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0">
                <v-card-text class="mt-5">{{ $t('salepointtable.bill.choose') }}</v-card-text>
                <v-container class="pa-4 pb-5">
                    <v-row>
                        <v-col cols="6" class="pa-2 pb-5" v-for="btn in items" :key="btn">
                            <acs-slot-btn
                                :inverse="selected !== btn"
                                small
                                color="primary"
                                @click="select(btn)"
                                :icon="`$vuetify.icons.${btn}`"
                                :label="$t(`salepointtable.bill.${btn}`)" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-bottom-sheet>
</template>

<script>
import AcsSlotBtn from '@/components/slot/AcsSlotBtn'

export default {
    name: 'acs-slot-bill',
    props: {
        value: { type: Boolean },
        disabled: { type: Boolean },
        service: { type: Object },
        cls: { type: String }
    },
    components: { AcsSlotBtn },
    data: () => ({
        selected: null,
        items: ['billcard', 'billcash']
    }),
    computed: {
        show: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        }
    },
    methods: {
        select(type) {
            this.show = false
            this.$emit('selected', { type })
        }
    }
}
</script>
