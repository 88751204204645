<template>
    <div class="d-flex flex-row align-center mt-4">
        <span class="v-input" :class="{ 'error--text': error }">
            <span v-marked.trust.inline="$t('payrequest.conditions', { accessing: accessingUrl, salepoint: salepointUrl, count: conditionsCount })"></span>
        </span>
        <v-switch
            class="acs-pay-cgv--switch"
            v-model="conditions"
            :rules="[$required]"
            hide-details
            @update:error="v => error = v" />
    </div>
</template>

<script>
export default {
    name: 'acs-pay-cgv',
    props: {
        value: { type: Boolean }
    },
    data: () => ({
        error: false
    }),
    computed: {
        conditions: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        accessingUrl() {
            return this.$store.getters['pub/url']('/cg')
        },
        salepointUrl() {
            const sp = this.$store.getters['sp/current']
            return sp?.terms_conditions_url
        },
        conditionsCount() {
            return this.salepointUrl ? 2 : 1
        }
    }
}
</script>

<style lang="sass">
.acs-pay-cgv--switch
    margin-top: 0
</style>
